const INITIAL_STATE = { blogs: [] };

const setBlogsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_BLOGS":
      return { ...state, blogs: [...action.payload] };

    case "CREATE_BLOG":
      return { ...state, blogs: [action.payload, ...state.blogs] };

    case "UPDATE_BLOG":
      const filteredBlogs = state.blogs.filter(
        (blog) => blog.id !== action.payload.id
      );
      return { ...state, blogs: [action.payload, ...filteredBlogs] };

    default:
      return { ...state };
  }
};
export default setBlogsReducer;

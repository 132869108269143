const INITIAL_STATE = {
  purchasings: [],
  purchasingObj: null,
};

const purchasingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_PURCHASINGS":
      return { ...state, purchasings: [...action.payload] };
    case "GET_SINGLE_PURCHASING":
      let purchasings = [];
      if (state.purchasings.length == 0) {
        purchasings.push(action.payload);
      } else {
        purchasings = state.purchasings.map((purchasing) => {
          if (purchasing.id == action.payload.id) {
            return action.payload;
          } else {
            return purchasing;
          }
        });
      }
      return { ...state, purchasings: purchasings };
    case "UPLOAD_PURCHASING":
      return {
        ...state,
        purchasings: [...state.purchasings, action.payload],
      };
    case "UPDATE_PURCHASING":
      const filteredPurchasingsArray = state.purchasings.filter(
        (purchasing) => purchasing.id !== action.payload.id
      );

      return {
        ...state,
        purchasings: [...filteredPurchasingsArray, action.payload],
      };
    case "DELETE_PURCHASING":
      const updatedPurchasingsArray = state.purchasings.filter(
        (purchasing) => purchasing.id !== action.payload
      );
      return {
        ...state,
        purchasings: [...updatedPurchasingsArray],
      };
    default:
      return { ...state };
  }
};
export default purchasingsReducer;

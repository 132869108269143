const INITIAL_STATE = {
  orders: [],
  ordersExpress: [],
  orderObj: {},
  payableOrders: [],
  dueInvoices: [],
};

const setOrdersAlgReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_ORDERS":
      return { ...state, orders: [...action.payload] };
    case "GET_ALL_DUE_INVOICES":
      return { ...state, dueInvoices: [...action.payload] };
    case "GET_ALL_PAYABLE_ORDERS":
      return { ...state, payableOrders: action.payload };
    case "UPLOAD_ORDER":
      return {
        ...state,
        orders: action.payload
          ? [action.payload, ...state.orders]
          : [...state.orders],
      };
    case "UPDATE_ORDER":
      const updatedArray = state.orders.map((order) => {
        if (order.parcelId === action.payload.parcelId) {
          return action.payload;
        } else {
          return order;
        }
      });
      return {
        ...state,
        orders: updatedArray,
      };

    case "UPDATE_MULTIPLE_ORDERS":
      console.log(action.payload[0]);
      const updatedOrdersArray = state.orders.map(
        (order) =>
          action.payload.find((obj) => obj.parcelId == order.parcelId) || order
      );

      return {
        ...state,
        orders: updatedOrdersArray,
      };
    case "UPDATE_MULTIPLE_ORDERS_EXPRESS":
      console.log(action.payload[0]);
      const updatedOrdersArrayExpress = state.ordersExpress.map(
        (order) =>
          action.payload.find((obj) => obj.bookinglId == order.bookingId) ||
          order
      );

      return {
        ...state,
        ordersExpress: updatedOrdersArrayExpress,
      };

    case "DELETE_SINGLE_ORDER":
      const filteredOrdersArray = state.orders.filter(
        (order) => order.cartonNo !== action.payload.cartonNo
      );
      return { ...state, orders: [...filteredOrdersArray] };
    case "GET_SINGLE_ORDER":
      return { ...state, orderObj: action.payload };
    case "GET_ALL_EXPRESS_ORDERS":
      return { ...state, ordersExpress: [...action.payload] };

    default:
      return { ...state };
  }
};
export default setOrdersAlgReducer;

import React, { Component } from "react";
import "../lot/physical/createLotModal.css";

import { deleteSingleOrderRedux } from "../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class DeleteOrderModal extends Component {
  render() {
    return (
      <>
        <div
          className={
            this.props.toggleDeleteOrderModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-4"
              style={{ backgroundColor: "rgb(68 0 97)" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      onClick={() =>
                        this.props.startToggleDeleteOrderModal(null)
                      }
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "140%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            Are you sure you want to delete this Order?
                          </h2>
                          <div
                            className="form-row"
                            style={{ justifyContent: "center" }}
                          >
                            <div
                              style={{
                                color: "black",
                                fontSize: "200%",
                                background: "white",
                                padding: "10px",
                                borderRadius: "3px",
                                border: "1px solid darkgray",
                              }}
                            >
                              {this.props.parcelObj &&
                                this.props.parcelObj.parcelId}
                            </div>
                          </div>

                          <div className="form-row">
                            <div
                              className="col pt-3"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "row",
                              }}
                            >
                              <button
                                onClick={() =>
                                  this.props.startToggleDeleteOrderModal(null)
                                }
                                className="btn btn-secondary"
                              >
                                No
                              </button>

                              <button
                                style={{ marginLeft: "5px" }}
                                onClick={async () => {
                                  await this.props.deleteSingleOrderRedux(
                                    this.props.parcelObj
                                  );
                                  this.props.startToggleDeleteOrderModal(null);
                                  toast.success("Successfully deleted!");
                                }}
                                className="btn btn-primary"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, { deleteSingleOrderRedux })(DeleteOrderModal);

import { combineReducers } from "redux";
import setAdminsReducer from "./admins";

import setPaymentsReducer from "./payments";
import setProductsReducer from "./products";
import setLotsReducer from "./lots";
import setBookingsReducer from "./bookings";
import setOrdersAlgReducer from "./ordersAlg";
import setExpressRatesDocumentsReducer from "./expressRatesDocuments";
import setExpressRatesParcelReducer from "./expressRatesParcel";
import setD2DRatesReducer from "./d2dRates";
import setUsersReducer from "./users";
import setRechargeReducer from "./recharge";
import setRefundsReducer from "./refunds";
import setNoticesReducer from "./notices";
import setBlogsReducer from "./blogs";
import officesReducer from "./offices";
import cnfsReducer from "./cnfs";
import employeesReducer from "./employee";
import expensesReducer from "./expenses";
import cashInsReducer from "./cashIn";
import sourcingsReducer from "./sourcing";
import purchasingsReducer from "./purchasing";
import loansReducer from "./loans";
import installmentsReducer from "./installments";
import forwardersReducer from "./freight-forwarder";
import p2pReducer from "./p2p";
const rootReducer = combineReducers({
  bookings: setBookingsReducer,
  lots: setLotsReducer,
  users: setUsersReducer,
  payments: setPaymentsReducer,
  admins: setAdminsReducer,
  products: setProductsReducer,
  ordersAlg: setOrdersAlgReducer,
  expressRatesDocuments: setExpressRatesDocumentsReducer,
  expressRatesParcel: setExpressRatesParcelReducer,
  d2dRates: setD2DRatesReducer,
  recharge: setRechargeReducer,
  refunds: setRefundsReducer,
  notices: setNoticesReducer,
  blogs: setBlogsReducer,
  offices: officesReducer,
  cnfs: cnfsReducer,
  employees: employeesReducer,
  expenses: expensesReducer,
  cashIns: cashInsReducer,
  sourcings: sourcingsReducer,
  purchasings: purchasingsReducer,
  loans: loansReducer,
  installments: installmentsReducer,
  forwarder: forwardersReducer,
  p2p: p2pReducer,
});

export default rootReducer;

import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "../../plus image.jpeg";
import {
  uploadImageRechargeRequest,
  getOrderTrackingResult,
} from "../../../../firebase/firebase.utils";
import {
  updateSourcingRedux,
  getSingleSourcingRedux,
  deleteSourcingProductRedux,
} from "../../../../actions/index";
import { Search } from "react-feather";
import { sendNotifications } from "../../../../firebase/fcmRestApi";
export class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",
      checkedValues: [],
      checkedValues2: [],
      id: "",
      name: "",
      description: "",
      searchFor: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      shippingCost: "",
      agentCost: "",
      status: "",
      warehouse: "",
      productsArray: [
        {
          id: new Date().getTime(),
          imageUrl: man,
          productName: "",
          quantity: "",
          details: "",
        },
      ],
      productName: "",
      quantity: "",
      details: "",
      amount: "",
      orderStatus: "",
      trackingNo: "",
      orderStore: "",
      orderId: "",
      shippingInformation: null,
    };
  }

  componentDidMount = async () => {
    const { invoiceId } = this.props.match.params;
    const { getSingleSourcingRedux } = this.props;
    await getSingleSourcingRedux(invoiceId);
  };

  componentWillReceiveProps = async (nextProps) => {
    const { invoiceId, getSingleSourcingRedux } = this.props.match.params;
    const invoiceId2 = nextProps.match.params.invoiceId;

    if (invoiceId !== invoiceId2) {
      await getSingleSourcingRedux(invoiceId);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;
    let product = this.state.productsArray[i];
    console.log(this.state.productsArray);
    console.log(i);
    console.log(product);
    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      console.log(i);
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);

      this.setState(
        {
          imageUrl: imgUrl,
          productsArray: this.state.productsArray.map((product2) => {
            console.log(product);
            if (product2.id === product.id) {
              return { ...product2, imageUrl: imgUrl };
            } else {
              return product2;
            }
          }),
        },
        () => {
          console.log(this.state.productsArray);
        }
      );

      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    const { sourcingObj } = this.props;

    let invalidProduct = this.state.productsArray.find(
      (product) =>
        product.imageUrl == "/static/media/plus image.3dff302b.jpeg" ||
        product.productName == "" ||
        product.quantity == ""
    );
    if (invalidProduct) {
      alert("You must fill all the missing information");
      return;
    }
    await this.props.updateSourcingRedux({
      ...sourcingObj,
      productsArray:
        sourcingObj.productsArray && sourcingObj.productsArray.length > 0
          ? [...sourcingObj.productsArray, ...this.state.productsArray]
          : this.state.productsArray,
    });

    this.setState({
      productsArray: [
        {
          id: new Date().getTime(),
          imageUrl: man,
          productName: "",
          quantity: "",
          details: "",
        },
      ],
    });
  };
  handleUpdate = async (sourcingObj, product) => {
    await this.props.updateSourcingRedux({
      ...sourcingObj,
      productsArray: sourcingObj.productsArray.map((product2) => {
        if (product2.id == product.id) {
          return {
            ...product2,
            productName: this.state.productName,
            quantity: this.state.quantity,
            details: this.state.details,
            amount: this.state.amount,
            orderStatus: this.state.orderStatus,
            trackingNo: this.state.trackingNo,
            orderStore: this.state.orderStore,
            orderId: this.state.orderId,
          };
        } else {
          return product2;
        }
      }),
    });

    this.setState({
      productsArray: [
        {
          id: new Date().getTime(),
          imageUrl: man,
          productName: "",
          quantity: "",
          details: "",
        },
      ],
    });
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  render() {
    const { productObj, shippingInformation } = this.state;
    const { currentAdmin, sourcingObj } = this.props;
    const { invoiceId } = this.props.match.params;

    let total = 0;
    if (shippingInformation) {
      shippingInformation.parcelsArray.map(
        (parcel) => (total += parseInt(parcel.finalTotal))
      );
    }
    let totalOrder = 0;
    if (
      sourcingObj &&
      sourcingObj.productsArray &&
      sourcingObj.productsArray.length > 0
    ) {
      sourcingObj.productsArray.map((product) => {
        if (product.amount) {
          totalOrder += parseInt(product.amount);
        }
      });
    }
    return (
      <Fragment>
        <Breadcrumb title={invoiceId} parent="Business consultancy/Sourcing" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <span style={{ fontWeight: "lighter" }}>Customer :</span>{" "}
                    {sourcingObj && sourcingObj.customer} <br />
                    <span style={{ fontWeight: "lighter" }}>
                      Invoice Id:{" "}
                    </span>{" "}
                    {invoiceId} <br />
                    <span style={{ fontWeight: "lighter" }}>
                      Invoice Bill:
                    </span>{" "}
                    {sourcingObj && sourcingObj.totalBill}Tk <br />
                    <span style={{ fontWeight: "lighter" }}>
                      Total Ordered:
                    </span>{" "}
                    {totalOrder}Tk
                  </h5>
                  <li>
                    <button
                      style={{ marginLeft: 10 }}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#personalInfoModal"
                      onClick={() => {
                        this.setState({});
                      }}
                    >
                      +Add Products
                    </button>
                  </li>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Name
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Quantity
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Order status
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Tracking No
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Order store
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Order Id
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sourcingObj &&
                          sourcingObj.productsArray &&
                          sourcingObj.productsArray.length > 0 &&
                          sourcingObj.productsArray.map((product, index) => (
                            <tr key={index}>
                              <th
                                scope="row"
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {index + 1}
                              </th>

                              <td>
                                <a href={product.imageUrl} target="_blank">
                                  <img
                                    style={{ height: 60, width: 60 }}
                                    src={
                                      product.imageUrl ? product.imageUrl : man
                                    }
                                  />
                                </a>
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.productName}
                              </td>

                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.quantity}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.amount ? `${product.amount}Tk` : ""}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "4px 8px",
                                      borderRadius: 5,
                                      backgroundColor:
                                        product.orderStatus == "Ordered"
                                          ? "green"
                                          : "red",
                                      textAlign: "center",
                                      fontSize: 13,
                                      display: "inline",
                                    }}
                                  >
                                    {product.orderStatus
                                      ? product.orderStatus
                                      : "Not Ordered"}
                                  </div>
                                </div>
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.trackingNo}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.orderStore}
                              </td>
                              <td
                                data-toggle="modal"
                                data-target="#detailInfoModal"
                                onClick={async () => {
                                  if (product.trackingNo) {
                                    let result = await getOrderTrackingResult(
                                      product.trackingNo
                                    );
                                    console.log(result);
                                    if (result) {
                                      this.setState({
                                        shippingInformation: result,
                                      });
                                    } else {
                                      this.setState({
                                        shippingInformation: null,
                                      });
                                    }
                                  } else {
                                    this.setState({
                                      shippingInformation: null,
                                    });
                                  }
                                  this.setState({
                                    productObj: product,
                                  });
                                }}
                              >
                                {product.orderId}
                              </td>
                              <td>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <i
                                    className="icofont-edit"
                                    data-toggle="modal"
                                    data-target="#editProductModal"
                                    onClick={() => {
                                      this.setState({
                                        productObj: product,
                                        productName: product.productName,
                                        quantity: product.quantity,
                                        details: product.details,
                                        amount: product.amount
                                          ? product.amount
                                          : "",
                                        orderStatus: product.orderStatus
                                          ? product.orderStatus
                                          : "Not Ordered",
                                        trackingNo: product.trackingNo
                                          ? product.trackingNo
                                          : "",
                                        orderStore: product.orderStore
                                          ? product.orderStore
                                          : "",
                                        orderId: product.orderId
                                          ? product.orderId
                                          : "",
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      marginRight: 8,
                                      cursor: "pointer",
                                    }}
                                  />{" "}
                                  <i
                                    className="icofont-trash"
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal"
                                    onClick={() => {
                                      this.setState({
                                        productObj: product,
                                      });
                                    }}
                                    style={{
                                      color: "red",
                                      marginLeft: 8,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="editProductModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Product
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="productName"
                      onChange={this.handleChange}
                      value={this.state.productName}
                      id="exampleFormControlInput1"
                      placeholder="Enter product name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      QUANTITY
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="quantity"
                      onChange={this.handleChange}
                      value={this.state.quantity}
                      id="exampleFormControlInput1"
                      placeholder="Enter quantity"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Order details
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="details"
                      onChange={this.handleChange}
                      value={this.state.details}
                      id="exampleFormControlInput1"
                      placeholder="Enter quantity"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TOTAL PRICE
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="amount"
                      value={this.state.amount}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="in taka"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      ORDER STATUS
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="orderStatus"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.orderStatus}
                    >
                      <option value="">SELECT STATUS</option>
                      <option value={`Ordered`}>Ordered</option>
                      <option value={`Not Ordered`}>Not Ordered</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TRACKING NO
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="trackingNo"
                      value={this.state.trackingNo}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Enter tracking no"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Order Store
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="orderStore"
                      value={this.state.orderStore}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="1688/taobao/ebay/flipkart"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Order Id
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="orderId"
                      value={this.state.orderId}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Ex: 1688 order Id"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleUpdate(sourcingObj, this.state.productObj);
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "150%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Add Products
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.productsArray.map((product, index) => {
                  return (
                    <>
                      <div>
                        <div
                          className="row"
                          style={{
                            justifyContent: "space-between",
                            margin: "7px 12px",
                            marginBottom: 0,
                          }}
                        >
                          <div className="form-group">
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#505050",
                                marginBottom: 5,
                              }}
                            >
                              Image
                            </label>
                            <div
                              className="box-input-file"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                border: "1px solid gainsboro",
                                borderRadius: 5,
                                maxHeight: 60,
                                maxWidth: 60,
                              }}
                            >
                              {this.state.image_loader ? (
                                <div
                                  class="spinner-border mt-3 ml-4"
                                  role="status"
                                  style={{
                                    color: "purple",
                                    paddingTop: "25px",
                                  }}
                                >
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <>
                                  <img
                                    className="img-60 lazyloaded blur-up"
                                    src={product.imageUrl}
                                    alt="#"
                                    style={{
                                      zIndex: 10,
                                      cursor: "pointer",
                                      border: "1px solid gainsboro",
                                    }}
                                    onClick={() => {
                                      document
                                        .getElementById(
                                          `upload-image-input${index}`
                                        )
                                        .click();
                                    }}
                                  />
                                  <input
                                    id={`upload-image-input${index}`}
                                    className="upload"
                                    type="file"
                                    style={{
                                      position: "absolute",
                                      zIndex: 5,
                                      maxWidth: "50px",
                                      marginTop: "20px",
                                    }}
                                    onChange={(e) => {
                                      console.log(product);
                                      console.log(this.state.productsArray);
                                      this._handleImgChange(e, index);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#505050",
                                marginBottom: 5,
                              }}
                            >
                              Name
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              name="productName"
                              value={product.productName}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                this.setState({
                                  productsArray: this.state.productsArray.map(
                                    (product2) => {
                                      if (product2.id === product.id) {
                                        return {
                                          ...product2,
                                          productName: value,
                                        };
                                      } else {
                                        return product2;
                                      }
                                    }
                                  ),
                                });
                              }}
                              id="exampleFormControlInput1"
                              placeholder="Enter Product Name"
                              style={{
                                borderColor: "gainsboro",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#505050",
                                marginBottom: 5,
                              }}
                            >
                              Quantity
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              name="quantity"
                              value={product.quantity}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                this.setState({
                                  productsArray: this.state.productsArray.map(
                                    (product2) => {
                                      if (product2.id === product.id) {
                                        return {
                                          ...product2,
                                          quantity: value,
                                        };
                                      } else {
                                        return product2;
                                      }
                                    }
                                  ),
                                });
                              }}
                              id="exampleFormControlInput1"
                              placeholder="Enter Product Quantity"
                              style={{
                                borderColor: "gainsboro",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              style={{
                                fontWeight: "bold",
                                color: "#505050",
                                marginBottom: 5,
                              }}
                            >
                              Details
                            </label>
                            <textarea
                              type="text"
                              className="form-control"
                              name="details"
                              value={product.details}
                              onChange={(e) => {
                                const { name, value } = e.target;
                                this.setState({
                                  productsArray: this.state.productsArray.map(
                                    (product2) => {
                                      if (product2.id === product.id) {
                                        return {
                                          ...product2,
                                          details: value,
                                        };
                                      } else {
                                        return product2;
                                      }
                                    }
                                  ),
                                });
                              }}
                              id="exampleFormControlInput1"
                              placeholder="Size,color,variants etc"
                              style={{
                                borderColor: "gainsboro",
                                borderRadius: 5,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          padding: "2px 0px",
                          borderBottom: "1px solid gainsboro",
                          marginBottom: 5,
                        }}
                      ></div>
                    </>
                  );
                })}

                <button
                  type="button"
                  className="btn "
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    padding: "5px 10px",
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.setState({
                      productsArray: [
                        ...this.state.productsArray,
                        {
                          id:
                            new Date().getTime() +
                            this.state.productsArray.length,
                          imageUrl: man,
                          productName: "",
                          quantity: "",
                          details: "",
                        },
                      ],
                    });
                  }}
                >
                  +Add
                </button>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Add Product
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", minWidth: "140%" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Product
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Product?</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{productObj && productObj.id}</td>
                      <td>
                        {" "}
                        <img
                          className="img-60 lazyloaded blur-up"
                          src={productObj && productObj.imageUrl}
                        />
                      </td>
                      <td>{productObj && productObj.productName}</td>
                      <td>{productObj && productObj.quantity}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteSourcingProductRedux(
                      sourcingObj.id,
                      productObj.id
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Shipping Information
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>
                {productObj && productObj.details && (
                  <div
                    style={{
                      marginBottom: 30,
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                  >
                    Product details:{"  "}{" "}
                    <span style={{ fontWeight: "lighter", fontSize: 16 }}>
                      {productObj.details}{" "}
                    </span>
                  </div>
                )}
                {shippingInformation ? (
                  <div>
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr style={{ background: "rgb(0, 37, 76)" }}>
                          <th style={{ color: "white" }}>Shipping mark</th>
                          <th style={{ color: "white" }}>Carton no</th>
                          <th style={{ color: "white" }}>Product name</th>
                          <th style={{ color: "white" }}>Weight</th>
                          <th style={{ color: "white" }}>Per Kg</th>
                          <th style={{ color: "white" }}>Shipping cost</th>
                          <th style={{ color: "white" }}>Parcel Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shippingInformation &&
                          shippingInformation.parcelsArray.map((parcel) => (
                            <tr>
                              <td>{parcel.shippingMark}</td>
                              <td>{parcel.parcelId}</td>
                              <td>{parcel.productName}</td>
                              <td>{parcel.grossWeight}Kg</td>
                              <td>{parcel.ratePerKg}Tk</td>
                              <td>{parcel.finalTotal}Tk</td>
                              <td
                                style={{
                                  color:
                                    parcel.parcelStatus === "Delivered"
                                      ? "green"
                                      : "orange",
                                }}
                              >
                                {parcel.parcelStatus ||
                                  (this.props.lots.length > 0 &&
                                    this.props.lots.find(
                                      (lot) => lot.lotNo == parcel.lotNo
                                    ) &&
                                    this.props.lots.find(
                                      (lot) => lot.lotNo == parcel.lotNo
                                    ).shipmentStatus) ||
                                  ""}
                              </td>
                            </tr>
                          ))}
                        <tr>
                          <td
                            colSpan={5}
                            style={{ fontWeight: "bold", textAlign: "end" }}
                          >
                            Total
                          </td>
                          <td
                            colSpan={2}
                            style={{ fontWeight: "bold", textAlign: "start" }}
                          >
                            {total}Tk
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flex: "row",
                      justifyContent: "center",
                      marginTop: 20,
                      marginBottom: 40,
                    }}
                  >
                    {" "}
                    No shipping Information available with this tracking No.
                    Please check your tracking no.
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="receiveModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "100%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Receive in Warehouse
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Receiving in Warehouse
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="warehouse"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.warehouse}
                    >
                      <option value="">Select Warehouse</option>
                      <option value="Bangladesh warehouse">Bangladesh</option>
                      <option value="India warehouse">India</option>
                      <option value="USA warehouse">USA</option>
                      <option value="China warehouse">China</option>
                      <option value="Dubai warehouse">Dubai</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleReceive();
                  }}
                >
                  Receive
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users.users,
    currentAdmin: state.admins.currentAdmin,
    sourcingObj: state.sourcings.sourcings.find(
      (sourcing) => sourcing.id == ownProps.match.params.invoiceId
    ),
    lots: state.lots.lots,
  };
};

export default connect(mapStateToProps, {
  getSingleSourcingRedux,
  updateSourcingRedux,
  deleteSourcingProductRedux,
})(ProductDetails);

import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import { ScrollContext } from "react-router-scroll-4";
import { Provider } from "react-redux";

// Components
import store from "./store";
import Dashboard from "./components/dashboard";

//  BookingRequests
import BookingList from "./components/bookings/bookingList";
import BookingListDate from "./components/bookings/bookingListDate";
import BookingRequestWarehouse from "./components/bookings/office.js";
// LotsList
import LotList from "./components/lot/physical/lotlist";
import DeliveredLot from "./components/lot/physical/deliveredLot";

//Orders
import OrdersLots from "./components/sales/ordersLots";
import OrdersD2DFreight from "./components/sales/ordersD2DFreight";
import OrdersExpressMonth from "./components/sales/ordersExpressMonth";
import OrdersExpress from "./components/sales/ordersExpress";

// Express Rates
import Document from "./components/express-rates/document/document";
import Parcel from "./components/express-rates/parcel/parcel";

// D2D Rates
import D2DRates from "./components/d2d-rates/d2dRates";

//Payments
import Payments from "./components/payments/payments";
import PaymentsExpress from "./components/paymentsExpress/payments";
import PaymentsByDate from "./components/payments/paymentsByDate";
import PaymentsByDateExpress from "./components/paymentsExpress/paymentsByDate";
import PaymentRequest from "./components/payments/payment-request/paymentRequest";
import PaymentRequestExpress from "./components/paymentsExpress/payment-request/paymentRequestExpress";
// delivery
import DeliveryLots from "./components/delivery/deliveryLots";
import DeliveryD2DFreight from "./components/delivery/deliveryD2DFreight";
import DeliveryExpressMonth from "./components/delivery/deliveryExpressMonth";
import DeliveryExpress from "./components/delivery/deliveryExpress";

// refund
import RefundRequest from "./components/refund/refundRequest";
import AllRefunds from "./components/refund/allRefunds";
import AllRefundsByLots from "./components/refund/allRefundsByLot";

//Pages
import ProductToOrder from "./components/pages/product-to-order";
import Create_page from "./components/pages/create-page";
import Media from "./components/media/media";
import List_menu from "./components/menus/list-menu";
import Create_menu from "./components/menus/create-menu";
import List_user from "./components/users/list-user";
import List_admin from "./components/admins/list-admin";
import DetailUser from "./components/users/detail-user";

import Profile from "./components/settings/profile";
import Reports from "./components/reports/report";
import Invoice from "./components/invoice/invoice";
import InvoiceExpress from "./components/invoice/invoiceExpress";
import InvoiceExpressByMonth from "./components/invoice/invoiceExpressByMonth";
import InvoiceOrderList from "./components/invoice/invoiceOrderList";
import InvoiceByOrder from "./components/invoice/invoice-by-order";
import InvoiceByOrderExpress from "./components/invoice/invoiceByOrderExpress";
import Print from "./components/invoice/print";
import Datatable from "./components/common/datatable";
import Login from "./components/auth/login";
import SearchedOrder from "./components/searched-order/searched-order";

// communication
import CommunicationByLots from "./components/communication/lots/lotList";
import CommunicationByCustomers from "./components/communication/customers/customerList";
import IntroModal from "./components/communication/introModal/introModalList";
import Notice from "./components/notice/notice";
import Blogs from "./components/blogs/blogs";
import SingleBlog from "./components/blogs/singleBlog";
import AddBlog from "./components/blogs/addBlog";

// recharge
import RechargeRequest from "./components/recharge/recharge-request/rechargeRequest";
import RechargeWallet from "./components/recharge/recharge-wallet/rechargeWallet";
import RechargeHistory from "./components/recharge/recharge-history/rechargeHistory";
import RechargeHistoryByDate from "./components/recharge/recharge-history/rechargeHistoryByDate";
import PayInvoice from "./components/payInvoice/payInvoice";
import PayInvoiceExpress from "./components/payInvoiceExpress/payInvoice";
import DailyExpense from "./components/expense/daily-expense";

// expense history
import ApproveExpense from "./components/expense-history/approve-expense/approveExpense";
import Office from "./components/expense-history/office/office";
import Cnf from "./components/expense-history/cnf/cnf";
import Employee from "./components/expense-history/employee/employee";

import ExpenseByDay from "./components/expense-history/approve-expense/daily-expense";
import MonthlyExpense from "./components/expense-history/monthly-expense/monthlyexpense";
import Loans from "./components/expense-history/loan/monthlyexpense";
import LoanMonthly from "./components/expense-history/loan/monthlyexpenseByMonth";
import MonthlyExpenseByMonth from "./components/expense-history/monthly-expense/monthlyexpenseByMonth";
import InvestMonth from "./components/expense-history/invest/monthlyexpense";
import Invests from "./components/expense-history/invest/monthlyexpenseByMonth";
import OfficeCostMonth from "./components/expense-history/daily-cost/monthlyexpense";
import LotTransportMonth from "./components/expense-history/lot-transport/monthlyexpense";
import RefundsMonth from "./components/expense-history/refund/monthlyexpense";
import CommisionMonth from "./components/expense-history/agent-commision/monthlyexpense";
import OfficeCosts from "./components/expense-history/daily-cost/monthlyexpenseByMonth";
import OfficeCostsLotTransport from "./components/expense-history/lot-transport/monthlyexpenseByMonth";
import Refunds from "./components/expense-history/refund/monthlyexpenseByMonth";
import Commisions from "./components/expense-history/agent-commision/monthlyexpenseByMonth";
import PartnerMonth from "./components/expense-history/partner payment/monthlyexpense";
import PartnerPayments from "./components/expense-history/partner payment/monthlyexpenseByMonth";
import SalaryMonth from "./components/expense-history/salary/monthlyexpense";
import Salaries from "./components/expense-history/salary/monthlyexpenseByMonth";
import OfficeMonth from "./components/expense-history/office/monthlyexpense";
import OfficeExpenses from "./components/expense-history/office/monthlyexpenseByMonth";
import EmployeeMonth from "./components/expense-history/employee/monthlyexpense";
import EmployeeExpenses from "./components/expense-history/employee/monthlyexpenseByMonth";
import CnfMonth from "./components/expense-history/cnf/monthlyexpense";
import CnfExpenses from "./components/expense-history/cnf/monthlyexpenseByMonth";
import CashSummary from "./components/expense-history/cash-summary/monthlyexpense";
import CashSummaryByMonth from "./components/expense-history/cash-summary/monthlyexpenseByMonth";
import CashSummaryByDate from "./components/expense-history/cash-summary/daily-expense";
import BillSummary from "./components/expense-history/bill-summary/monthlyexpense.js";
import BillSummaryByMonth from "./components/expense-history/bill-summary/monthlyexpenseByMonth.js";
import BillSummaryStatement from "./components/expense-history/bill-summary/invoice-by-order.js";
import BillSummaryByDate from "./components/expense-history/bill-summary/daily-expense.js";

// business consultancy
import StockProduct from "./components/business-consultancy/stock/stock-product/stock-product";
import SoldMonth from "./components/business-consultancy/stock/sold-product/month";
import SoldProducts from "./components/business-consultancy/stock/sold-product/sold-product";
import SourcingMonth from "./components/business-consultancy/sourcing/invoice/month";
import PurchasingMonth from "./components/business-consultancy/purchasing/invoice/month";
import Sourcing2Month from "./components/business-consultancy/sourcing2.0/invoice/month";
import SourcingMonthlyInvoice from "./components/business-consultancy/sourcing/invoice/monthly-invoices";
import PurchasingMonthlyInvoice from "./components/business-consultancy/purchasing/invoice/monthly-invoices";
import Sourcing2MonthlyInvoice from "./components/business-consultancy/sourcing2.0/invoice/monthly-invoices";
import AgentsPurchasing from "./components/business-consultancy/purchasing/purchasing-agents/agents";
import AgentsPurchasingMonths from "./components/business-consultancy/purchasing/purchasing-agents/month";
import AgentsPurchasingMonthly from "./components/business-consultancy/purchasing/purchasing-agents/monthly-invoices";
import NewRequest from "./components/business-consultancy/newRequest.js";
import AgentsSourcing2 from "./components/business-consultancy/sourcing2.0/sourcing-agents/agents";
import AgentsSourcing2Months from "./components/business-consultancy/sourcing2.0/sourcing-agents/month";
import AgentsSourcing2Monthly from "./components/business-consultancy/sourcing2.0/sourcing-agents/monthly-invoices";
import AgentsSourcing from "./components/business-consultancy/sourcing/sourcing-agents/agents";
import AgentsSourcingMonths from "./components/business-consultancy/sourcing/sourcing-agents/month";
import AgentsSourcingMonthly from "./components/business-consultancy/sourcing/sourcing-agents/monthly-invoices";
import Customers from "./components/expense-history/loan/customer";
import Customers2 from "./components/expense-history/customer-due/customer.js";
import SingleCustomer from "./components/expense-history/loan/singleCustomer";
import CustomersInstallments from "./components/expense-history/monthly-installment/customer";
import SingleCustomerInstallments from "./components/expense-history/monthly-installment/singleCustomer";
import Fund from "./components/expense-history/fund/fund.js";
import PaymentRequestSourcings from "./components/business-consultancy/payment-request/paymentRequest";
import ProductDetails from "./components/business-consultancy/sourcing2.0/invoice/productDetails.js";
import ProductDetailsPurchasing from "./components/business-consultancy/purchasing/invoice/productDetails.js";
import FreightForwarder from "./components/communication/freight-forwarder/list.js";

// p2p
import P2pRequest from "./components/p2pCustomer/p2pRequest";
import Warehouse from "./components/p2pAgent/warehouse";
import MyShipmentRequest from "./components/p2pAgent/myShipmentRequest";
import InShipment from "./components/p2pCustomer/inShipment";
import PaymentRequestP2p from "./components/p2pCustomer/paymentRequest";
import MemberRequestP2p from "./components/p2pAgent/memberRequest";
import AllAgentsP2p from "./components/p2pAgent/allAgentsP2p.js";
import Banners from "./components/communication/banner.js";
import OnlyInvoiceToPrint2 from "./components/expense-history/customer-due/invoice-by-order.js";

class Root extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentAdmin: null,
    };
  }

  setCurrentAdmin = (adminObj) => {
    this.setState({ currentAdmin: adminObj });
    console.log(this.state.currentAdmin);
  };

  render() {
    const { currentAdmin } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={() => (
                  <Login setCurrentAdmin={this.setCurrentAdmin} />
                )}
              />

              <App>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/dashboard`}
                  component={Dashboard}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/searched-order/:orderId`}
                  component={SearchedOrder}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/lot/lot-list/:shipmentStatus`}
                  component={LotList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/lot/delivered-lot`}
                  component={DeliveredLot}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/booking-request/:bookingStatus`}
                  component={BookingListDate}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/booking-request/:bookingStatus/:date`}
                  component={BookingList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/booking-request-warehouse`}
                  component={BookingRequestWarehouse}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/orders/express`}
                  component={OrdersExpressMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/delivery-express`}
                  component={DeliveryExpressMonth}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/calculation/express`}
                  component={(props) => (
                    <OrdersExpressMonth props={props} calculation={true} />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/delivery-express/:month`}
                  component={DeliveryExpress}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/order/:shipmentMethod/:shipmentStatus`}
                  component={OrdersLots}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/orders/d2d-freight/:shipmentMethodLotNo`}
                  component={OrdersD2DFreight}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/calcualation/expressOrder/:month`}
                  component={(props) => (
                    <OrdersExpress props={props} calculation={true} />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expressOrder/:month`}
                  component={OrdersExpress}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/calculation/:shipmentMethod/:shipmentStatus`}
                  component={(props) => (
                    <OrdersLots props={props} calculation={true} />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/calculation/d2d-freight/:shipmentMethodLotNo`}
                  component={(props) => (
                    <OrdersD2DFreight props={props} calculation={true} />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoice-d2d-freight/:shipmentMethodLotNo`}
                  component={InvoiceOrderList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pay-invoice/:shipmentMethodLotNo`}
                  component={PayInvoice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pay-invoice-express`}
                  component={PayInvoiceExpress}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoice-by-orderId/:orderId`}
                  component={InvoiceByOrder}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoice-by-bookingId/:bookingId`}
                  component={InvoiceByOrderExpress}
                />

                {/* express  */}

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/express-rates/document`}
                  component={Document}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/express-rates/parcel`}
                  component={Parcel}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/d2d-rates/:country`}
                  component={D2DRates}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/freight-forwarder`}
                  component={FreightForwarder}
                />

                {/* payments  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payments`}
                  component={Payments}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payments-express`}
                  component={PaymentsExpress}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payments-express/:date`}
                  component={PaymentsByDateExpress}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payments/:date`}
                  component={PaymentsByDate}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payment-request`}
                  component={PaymentRequest}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/payment-request/new`}
                  component={PaymentRequestP2p}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/member-request/new`}
                  component={MemberRequestP2p}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/member-request/agents`}
                  component={AllAgentsP2p}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/paymentRequest`}
                  component={PaymentRequestSourcings}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/payment-request-express`}
                  component={PaymentRequestExpress}
                />

                {/* delivery  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/delivery/:shipmentMethod`}
                  component={DeliveryLots}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/delivery/d2d-freight/:shipmentMethodLotNo`}
                  component={DeliveryD2DFreight}
                />

                {/* refund  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/refund/refund-request`}
                  component={RefundRequest}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/refund/all-refunds`}
                  component={AllRefunds}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/refund/all-refunds/:lotNo`}
                  component={AllRefundsByLots}
                />

                {/* communication  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/lots`}
                  component={CommunicationByLots}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/customers`}
                  component={CommunicationByCustomers}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/notice`}
                  component={Notice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/blogs`}
                  component={Blogs}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/daily-expense`}
                  component={DailyExpense}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/blogs/:id`}
                  component={SingleBlog}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communications/blogs/add-blog`}
                  component={AddBlog}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/intro-modal`}
                  component={IntroModal}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/communication/app-banner`}
                  component={Banners}
                />

                {/* recharge  */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/recharge/recharge-request`}
                  component={RechargeRequest}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/recharge/recharge-wallet`}
                  component={RechargeWallet}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/recharge/recharge-history`}
                  component={RechargeHistory}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/recharge/recharge-history/:date`}
                  component={RechargeHistoryByDate}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pages/product-to-order`}
                  component={ProductToOrder}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pages/create-page`}
                  component={Create_page}
                />

                {/* <Route exact epath={`${process.env.PUBLIC_URL}/media`} component={Media} /> */}

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/menus/list-menu`}
                  component={List_menu}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/menus/create-menu`}
                  component={Create_menu}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/users/list-user`}
                  component={List_user}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admins/list-admin`}
                  component={List_admin}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/users/list-user/:userId`}
                  component={DetailUser}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/settings/profile`}
                  component={Profile}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoice-d2d-freight`}
                  component={Invoice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pay-invoice`}
                  component={Invoice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoice-express`}
                  component={InvoiceExpress}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/invoice-express/:month`}
                  component={InvoiceExpressByMonth}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/data-table`}
                  component={Datatable}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/office`}
                  component={Office}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/office/:name`}
                  component={OfficeMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/singleOffice/office/:month`}
                  component={OfficeExpenses}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/cnf`}
                  component={Cnf}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/cnf/:name`}
                  component={CnfExpenses}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expenses/cnf/:month`}
                  component={CnfMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/employee`}
                  component={Employee}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/employee/:name`}
                  component={EmployeeMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/employees/:month`}
                  component={EmployeeExpenses}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/approve-expense`}
                  component={ApproveExpense}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/expense-history`}
                  component={MonthlyExpense}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/loans`}
                  component={Loans}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/loans-by-customers`}
                  component={Customers}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/loans-by-customers2/pdf`}
                  component={OnlyInvoiceToPrint2}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/loans-by-customers2`}
                  component={Customers2}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/loans-by-customers/:customer`}
                  component={SingleCustomer}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/monthly-installment`}
                  component={CustomersInstallments}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/installments-by-customers/:customer`}
                  component={SingleCustomerInstallments}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/loans/:month`}
                  component={LoanMonthly}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/invest`}
                  component={InvestMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/invest/:month`}
                  component={Invests}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/salary`}
                  component={SalaryMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/salary/:month`}
                  component={Salaries}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/daily-cost`}
                  component={OfficeCostMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/lot-transport`}
                  component={LotTransportMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/refunds`}
                  component={RefundsMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/agent-commision`}
                  component={CommisionMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/fund`}
                  component={Fund}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/daily-cost/:month`}
                  component={OfficeCosts}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/lot-transport/:month`}
                  component={OfficeCostsLotTransport}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/refunds/:month`}
                  component={Refunds}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/agent-commision/:month`}
                  component={Commisions}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/partner-payment/:partner`}
                  component={PartnerMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/partners/partner-payment/:month`}
                  component={PartnerPayments}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/expense-history/:month`}
                  component={MonthlyExpenseByMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cash-summary`}
                  component={CashSummary}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cash-summary/:month`}
                  component={CashSummaryByMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/cash-summary-by-day/:date`}
                  component={CashSummaryByDate}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bill-summary`}
                  component={BillSummary}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bill-summary/:month`}
                  component={BillSummaryByMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bill-summary/statement/:month`}
                  component={BillSummaryStatement}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bill-summary-by-day/:date`}
                  component={BillSummaryByDate}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/expense/approve-expense/:date`}
                  component={ExpenseByDay}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/stock`}
                  component={StockProduct}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sold`}
                  component={SoldMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/:status`}
                  component={P2pRequest}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/assigned/:status`}
                  component={InShipment}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/warehouse/:status`}
                  component={Warehouse}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/p2p/agent/:status`}
                  component={MyShipmentRequest}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sold/:month`}
                  component={SoldProducts}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing/invoice`}
                  component={SourcingMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing-invoice-months`}
                  component={Sourcing2Month}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/purchasing/invoice`}
                  component={PurchasingMonth}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing/invoice/:month`}
                  component={SourcingMonthlyInvoice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing2/invoice/:month`}
                  component={Sourcing2MonthlyInvoice}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing2/:invoiceId`}
                  component={ProductDetails}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/purchasing2/:invoiceId`}
                  component={ProductDetailsPurchasing}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/purchasing/invoice/:month`}
                  component={PurchasingMonthlyInvoice}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/purchasing/agents`}
                  component={AgentsPurchasing}
                />

                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing-invoice-agents`}
                  component={AgentsSourcing2}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/purchasing/agents/:agent`}
                  component={AgentsPurchasingMonths}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/purchasing/agents/:agent/:month`}
                  component={AgentsPurchasingMonthly}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcingRequest`}
                  component={NewRequest}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing/agents/:agent`}
                  component={AgentsSourcingMonths}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing/agents/:agent/:month`}
                  component={AgentsSourcingMonthly}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing2/agents/:agent`}
                  component={AgentsSourcing2Months}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/business-consultancy/sourcing2/agents/:agent/:month`}
                  component={AgentsSourcing2Monthly}
                />
              </App>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

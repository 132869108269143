const INITIAL_STATE = { notices: [], intro: [], banners: [] };

const setNoticesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_NOTICES":
      return { ...state, notices: [...action.payload] };
    case "GET_ALL_INTROS":
      return { ...state, intro: [...action.payload] };
    case "CREATE_NOTICE":
      return { ...state, notices: [action.payload, ...state.notices] };
    case "CREATE_INTRO":
      return { ...state, intro: [action.payload, ...state.intro] };
    case "UPDATE_NOTICE":
      const filteredNotices = state.notices.filter(
        (notice) => notice.id !== action.payload.id
      );
      return { ...state, notices: [action.payload, ...filteredNotices] };

    case "GET_ALL_BANNERS":
      return { ...state, banners: [...action.payload] };

    case "UPLOAD_BANNER":
      return {
        ...state,
        banners: [action.payload, ...state.banners],
      };
    case "UPDATE_BANNER":
      const filteredBannersArray = state.banners.map((banner) => {
        if (banner.id == action.payload.id) {
          return action.payload;
        } else {
          return banner;
        }
      });

      return {
        ...state,
        banners: filteredBannersArray,
      };
    case "DELETE_BANNER":
      const updatedBannersArray = state.banners.filter(
        (product) => product.id !== action.payload.id
      );

      return {
        ...state,
        banners: [...updatedBannersArray],
      };
    default:
      return { ...state };
  }
};
export default setNoticesReducer;

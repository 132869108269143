import React, { Component } from "react";
import "./paymentModal.css";
import {
  uploadLotRedux,
  updateLotRedux,
  getAllOrdersOfSingleLotRedux,
  rechargeUserFromRechargeRequestRedux,
  rechargeUserRedux,
  makePaymentExpressRedux,
  updatePaymentRequestStatusExpressRedux,
} from "../../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { getMultipleOrdersExpress } from "../../../firebase/firebase.utils";

class PaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  uniqueId = (length = 16) => {
    return parseInt(Math.ceil(Math.random() * Date.now()).toPrecision(length));
  };

  componentDidMount = () => {
    console.log(this.uniqueId());
  };

  handleSubmit = async () => {
    const { userObj, admin, paymentRequestObj, rechargeUser } = this.props;

    const parcelsArray = await getMultipleOrdersExpress(
      paymentRequestObj.paidInvoices
    );
    // admin payment korle admin jabe, user payment korle user jabe
    console.log(parcelsArray);

    await this.props.makePaymentExpressRedux(
      paymentRequestObj.amount,
      paymentRequestObj.paidInvoices,
      rechargeUser,
      admin,
      parcelsArray,
      paymentRequestObj.method
    );
    await this.props.updatePaymentRequestStatusExpressRedux({
      ...paymentRequestObj,
      status: "recharged",
    });
    toast.success("Payment is successful");

    this.props.startToggleModal(null);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { paymentRequestObj, rechargeUser, userObj } = this.props;
    console.log(paymentRequestObj);
    console.log(rechargeUser);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-3"
              style={{
                backgroundColor: "white",

                boxShadow: "black 1px 5px 24px 0px",
              }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      onClick={() => this.props.startToggleModal(null)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "black",
                              textTransform: "none",
                              fontSize: "200%",
                              borderBottom: "2px dotted black",
                            }}
                          >
                            {rechargeUser &&
                              `Payment of ${rechargeUser.displayName}`}
                          </h2>
                          <div style={{ fontWeight: "bold", fontSize: "120%" }}>
                            Are you sure you want to approve this payment?
                          </div>

                          <div
                            style={{
                              marginTop: "30px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="btn"
                              style={{
                                padding: "7px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                borderRadius: "4px",
                                backgroundColor: "red",
                                color: "white",
                              }}
                              onClick={() => {
                                this.props.startToggleModal(null);
                              }}
                            >
                              No
                            </button>
                            <button
                              className="btn"
                              style={{
                                padding: "7px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                borderRadius: "4px",
                                backgroundColor: "green",
                                marginLeft: "5px",
                                color: "white",
                              }}
                              onClick={() => {
                                this.handleSubmit();
                              }}
                            >
                              Yes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.paymentRequestObj);
  return {
    allUsers: state.users.users,
    rechargeUser: ownProps.paymentRequestObj
      ? state.users.users.find(
          (user) => user.uid === ownProps.paymentRequestObj.userId
        )
      : null,
    admin: state.admins.currentAdmin,
  };
};
export default connect(mapStateToProps, {
  uploadLotRedux,
  updateLotRedux,
  rechargeUserFromRechargeRequestRedux,
  rechargeUserRedux,
  getAllOrdersOfSingleLotRedux,
  makePaymentExpressRedux,
  updatePaymentRequestStatusExpressRedux,
})(PaymentModal);

import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "./plus image.jpeg";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import {
  getAllP2PRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
  getAllP2pAgentRequestRedux,
  updateP2pAgentRequestRedux,
} from "../../actions";
import { ShoppingCart, Bell, Search } from "react-feather";
import { sendNotifications } from "../../firebase/fcmRestApi";
import { withRouter } from "react-router-dom";
export class MyShipmentRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",
      id: "",
      name: "",
      quantity: "",
      amount: "",
      description: "",

      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      arrivalDate: "",
      searchFor: "",
    };
  }

  componentDidMount = async () => {
    const { getAllP2pAgentRequestRedux } = this.props;
    if (this.props.match.params.status == "approved") {
      await getAllP2pAgentRequestRedux("Approved");
    } else {
      await getAllP2pAgentRequestRedux("Pending");
    }
  };
  componentWillReceiveProps = async (nextProps) => {
    const { getAllP2pAgentRequestRedux } = nextProps;
    if (nextProps.match.params.status !== this.props.match.params.status) {
      if (nextProps.match.params.status == "approved") {
        await getAllP2pAgentRequestRedux("Approved");
      } else {
        await getAllP2pAgentRequestRedux("Pending");
      }
    }
  };

  handleSubmit = async (requestObj, status) => {
    const { updateP2pAgentRequestRedux, updateP2PRedux } = this.props;
    console.log(requestObj);
    console.log(status);
    await updateP2pAgentRequestRedux({ ...requestObj, status: status });
    for (let i = 0; i < requestObj.cart.length; i++) {
      await updateP2PRedux({
        ...requestObj.cart[i],
        agentStatus: status,
        agentId: requestObj.agentId,
        arrivalDate: requestObj.arrivalDate,
      });
    }
    const message = {
      title: `P2P pickup request`,
      body: `Your P2P pickup request is ${status}. Now you can collect your requested products from our relevant warehouse.`,
    };
    let userObj = this.props.users.find(
      (user) => user.uid == requestObj.agentId
    );
    if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
      userObj.deviceToken.map((token) => {
        sendNotifications(token, message);
      });
    }
  };

  handleAssign = async (requestObj, status) => {
    const { updateP2pAgentRequestRedux, updateP2PRedux, users } = this.props;
    console.log(requestObj);

    let user = users.find((user) => user.uid == requestObj.agentId);
    console.log(user);
    if (user && user.uid && user.agentsName) {
      await updateP2pAgentRequestRedux({
        ...requestObj,
        shipmentStatus: status,
      });

      for (let i = 0; i < requestObj.cart.length; i++) {
        await updateP2PRedux({
          ...requestObj.cart[i],
          agentStatus: "Approved",
          agentId: requestObj.agentId,
          assignedDate: new Date().toLocaleDateString("en-GB"),
          arrivalDate: requestObj.arrivalDate,
          status: "in shipment",
          agentsName: user.agentsName,
          agentsAddress: user.agentsAddress,
          agentsNid: user.agentsNid,
          agentsPostcode: user.agentsPostcode,
          agentsMobileNo: user.agentsMobileNo,
        });
      }
      const message = {
        title: `P2P Product picked`,
        body: `Congrats you've picked your requested product from our warehouse. Please deliver it by the date ${requestObj.arrivalDate}`,
      };
      let userObj = this.props.users.find(
        (user) => user.uid == requestObj.agentId
      );
      if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
        userObj.deviceToken.map((token) => {
          sendNotifications(token, message);
        });
      }
    } else {
      alert("User not found. Try again later");
    }
  };

  renderActionButton = (product, cart) => {
    let addedToCart = cart.find((product2) => product2.id == product.id);
    console.log(addedToCart);
    if (product.agentStatus && product.agentStatus !== "Reject") {
      return (
        <div
          style={{
            color: "white",
            padding: "5px 7px",
            borderRadius: 5,
            backgroundColor:
              product.agentStatus == "Pending" ? "darkorange" : "red",
            textAlign: "center",

            fontWeight: "bold",
          }}
        >
          <i
            className="icofont-business-man"
            style={{
              fontSize: "130%",
              marginRight: "5px",
              color: "white",
            }}
          ></i>
          {product.agentStatus}
        </div>
      );
    } else {
      if (addedToCart) {
        return (
          <div
            style={{
              color: "white",
              padding: "5px 7px",
              borderRadius: 5,
              backgroundColor: "darkgreen",
              textAlign: "center",

              fontWeight: "bold",
            }}
          >
            <i
              className="icofont-tick-boxed"
              style={{
                fontSize: "130%",
                marginRight: "5px",
                color: "white",
              }}
            ></i>
            Added
          </div>
        );
      } else {
        return (
          <div
            style={{
              color: "white",
              padding: "5px 7px",
              borderRadius: 5,
              backgroundColor: "green",
              textAlign: "center",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => {
              this.props.addToCart(product);
              return toast.success("Added to Cart");
            }}
          >
            <i
              className="icofont-cart-alt"
              style={{
                fontSize: "130%",
                marginRight: "5px",
                color: "white",
              }}
            ></i>
            Add
          </div>
        );
      }
    }
  };

  handleCartSubmit = async () => {
    const { cart, currentUser } = this.props;
    if (!this.state.arrivalDate) {
      alert("Please Select Your arrival date.");
      return;
    }
    for (let i = 0; i < cart.length; i++) {
      await this.props.updateP2PRedux({
        ...cart[i],
        agentId: currentUser.id,
        agentStatus: "Pending",
        arrivalDate: this.state.arrivalDate,
      });
      this.props.removeFromCart(cart[i]);
    }
  };

  getUserName = (agentId) => {
    const { users } = this.props;
    if (users.length > 0) {
      const agentName = users.find((user) => user.uid === agentId).displayName;
      return agentName;
    } else {
      return "";
    }
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { productObj } = this.state;
    const { p2p } = this.props;

    let renderableP2p = [];
    if (!this.state.searchFor) {
      renderableP2p = p2p;
    } else {
      renderableP2p = p2p.filter((booking) =>
        this.getUserName(booking.agentId)
          .toLowerCase()
          .includes(this.state.searchFor.toLowerCase())
      );
    }

    let total = 0;
    if (productObj) {
      productObj.cart.map((product) => {
        total += parseInt(product.agentCost);
      });
    }

    return (
      <Fragment>
        <Breadcrumb title={"Pickup Request"} parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    Pickup Request
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form className="form-inline search-form">
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search by Agent Name"
                            onChange={this.handleSearchBarChange}
                          />
                          <span>
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>
                  </div>
                </div>

                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Arrival Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Id
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderableP2p.map((product, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{this.getUserName(product.agentId)}</td>
                            <td>{product.arrivalDate}</td>

                            <td>{product.id}</td>

                            <td>
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  color:
                                    product.status == "Pending"
                                      ? "darkorange"
                                      : product.status == "Approved"
                                      ? "green"
                                      : product.status == "Reject"
                                      ? "red"
                                      : "cadetblue",
                                }}
                              >
                                {product.status}
                              </div>
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#instructionModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  padding: "5px 7px",
                                  borderRadius: 5,
                                  backgroundColor: "cadetblue",
                                  maxWidth: "100px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                see
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}

        <div
          className="modal fade"
          id="instructionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "1400px" }}
          >
            <div className="modal-content" style={{ top: 10 }}>
              <div
                className="modal-header"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Product Details
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Booking Id
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Customer
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Image
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          colSpan={3}
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Product Price
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Shipping Cost
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Agent Cost
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.productObj &&
                        this.state.productObj.cart.map((product, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{product.bookingId}</td>
                            <td>{product.date}</td>
                            <td>
                              {this.props.users.find(
                                (user) => user.uid === product.userId
                              ) &&
                                this.props.users.find(
                                  (user) => user.uid === product.userId
                                ).displayName}
                            </td>
                            <td>
                              <a href={product.imageUrl} target="_blank">
                                <img
                                  style={{ height: 70, width: 70 }}
                                  src={product.imageUrl}
                                />
                              </a>
                            </td>
                            <td>{product.name}</td>
                            <td colSpan={3}>
                              <pre>{product.description}</pre>
                            </td>
                            <td>{product.quantity}</td>
                            <td>{product.amount}Tk</td>
                            <td>{product.shippingCost || 0}Tk</td>
                            <td>{product.agentCost || 0}Tk</td>
                          </tr>
                        ))}
                      <tr>
                        <td
                          colSpan={12}
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                            textAlign: "end",
                          }}
                        >
                          Total
                        </td>
                        <td
                          style={{ fontWeight: "bold", fontSize: 24 }}
                          colSpan={1}
                        >
                          {total}Tk
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {this.state.productObj &&
              this.state.productObj.shipmentStatus == "Assigned" ? (
                <div
                  className="modal-footer"
                  style={{
                    background: "green",
                    color: "white",
                    padding: "7px 7px",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                >
                  Success! Product is in Shipment by the agent.{" "}
                </div>
              ) : (
                <div className="modal-footer">
                  {this.state.productObj &&
                  this.state.productObj.status === "Pending" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={() => {
                          this.handleSubmit(this.state.productObj, "Reject");
                        }}
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => {
                          this.handleSubmit(this.state.productObj, "Approved");
                        }}
                      >
                        Approve
                      </button>
                    </>
                  ) : this.state.productObj &&
                    this.state.productObj.status === "Approved" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => {
                          this.handleAssign(this.state.productObj, "Assigned");
                        }}
                      >
                        Assign
                      </button>
                    </>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2pAgentRequest,
    users: state.users.users,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    uploadP2PRedux,
    updateP2PRedux,
    deleteP2PRedux,
    getAllP2pAgentRequestRedux,
    updateP2pAgentRequestRedux,
  })(MyShipmentRequest)
);

import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { ToastContainer, toast } from "react-toastify";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { DollarSign } from "react-feather";
import {
  getAllPurchasingsAgentRedux,
  getAllEmployeeRedux,
} from "../../../../actions";
import { Link } from "react-router-dom";
import "./monthly-invoice.css";
import AllTransactions from "./allTransactions";
export class MonthlyInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchasingObj: null,
      filterCountry: "",
      selectedTab: "month",
    };
  }

  componentDidMount = async () => {
    const { agent, month } = this.props.match.params;
    this.props.getAllEmployeeRedux();
    this.props.getAllPurchasingsAgentRedux(agent, month);
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { purchasingObj } = this.state;
    const { purchasings, currentAdmin } = this.props;
    let date = new Date();
    let totalBill = 0;
    let totalRmb = 0;
    let totalPaid = 0;
    let buyingCost = 0;
    let totalProfit = 0;
    let sellRate = 0;
    let buyRate = 0;
    let shipping = 0;

    let renderablePurchasings = [];
    if (!this.state.filterCountry) {
      renderablePurchasings = purchasings.filter(
        (purchasing) => purchasing.agent
      );
    } else {
      renderablePurchasings = purchasings
        .filter((purchasing) => purchasing.agent)
        .filter(
          (purchasing) => purchasing.country === this.state.filterCountry
        );
    }
    if (renderablePurchasings.length > 0) {
      renderablePurchasings.map((purchasing) => {
        totalBill += parseInt(purchasing.totalBill || 0);
        totalRmb += parseInt(purchasing.totalRmb || 0);
        totalPaid += parseInt(purchasing.totalPaid || 0);
        buyingCost += parseInt(purchasing.buyingCost || 0);
        totalProfit += parseInt(purchasing.finalProfit || 0);
        sellRate += parseFloat(purchasing.sellRate || 0);
        buyRate += parseFloat(purchasing.buyRate || 0);
        shipping += parseFloat(purchasing.shipping || 0);
      });
    }

    console.log(this.props);
    let employee = this.props.allEmployees.find(
      (employee) => employee.employeeId == this.props.match.params.agent
    );
    return (
      <Fragment>
        <Breadcrumb
          title={this.props.match.params.agent}
          parent="Business Consultancy/Purchasing"
        />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <>
              <div
                className="card o-hidden widget-cards"
                style={{
                  marginLeft: 10,
                  border:
                    this.state.selectedTab === "month"
                      ? "2px solid red"
                      : "2px solid white",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#00254c",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ selectedTab: "month" });
                  }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <DollarSign className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span
                        className="m-0"
                        style={{
                          fontWeight: "bold",
                          fontSize: "100%",
                          color: "white",
                        }}
                      >
                        {this.props.match.params.month}{" "}
                        {this.props.match.params.agent}
                      </span>
                      <h3 className="mb-0" style={{ color: "darkorange" }}>
                        <CountUp className="counter" end={totalBill} />
                        Tk
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card o-hidden widget-cards"
                style={{
                  marginLeft: 10,
                  border:
                    this.state.selectedTab === "rmb"
                      ? "2px solid red"
                      : "2px solid white",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "rgb(46 146 137)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ selectedTab: "rmb" });
                  }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <DollarSign className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span
                        className="m-0"
                        style={{
                          fontWeight: "bold",
                          fontSize: "100%",
                          color: "white",
                        }}
                      >
                        RMB BALANCE
                      </span>
                      <h3 className="mb-0" style={{ color: "darkorange" }}>
                        <CountUp
                          className="counter"
                          end={
                            employee && employee.rmbBalance
                              ? employee.rmbBalance
                              : 0
                          }
                        />
                        Tk
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card o-hidden widget-cards"
                style={{
                  marginLeft: 10,
                  border:
                    this.state.selectedTab === "rupee"
                      ? "2px solid red"
                      : "2px solid white",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "rgb(79 136 195)",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({ selectedTab: "rupee" });
                  }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <DollarSign className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span
                        className="m-0"
                        style={{
                          fontWeight: "bold",
                          fontSize: "100%",
                          color: "white",
                        }}
                      >
                        RUPEE BALANCE
                      </span>
                      <h3 className="mb-0" style={{ color: "darkorange" }}>
                        <CountUp
                          className="counter"
                          end={
                            employee && employee.rupeeBalance
                              ? employee.rupeeBalance
                              : 0
                          }
                        />
                        Tk
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
            {this.state.selectedTab === "month" ? (
              <div className="col-sm-12">
                <div className="card">
                  <div
                    className="card-header"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "1px solid gainsboro",
                    }}
                  >
                    <h5>
                      <i
                        className="icofont-calendar"
                        style={{
                          fontSize: "130%",
                          marginRight: "5px",
                          color: "darkblue",
                        }}
                      ></i>
                      {this.props.match.params.month}
                    </h5>
                    <div>
                      <select
                        title="Filter by country"
                        required
                        name="filterCountry"
                        className="custom-select"
                        aria-required="true"
                        aria-invalid="false"
                        onChange={this.handleChange}
                        value={this.state.filterCountry}
                      >
                        <option value="">Filter by country </option>
                        <option value="">All</option>
                        <option value="INDIA">INDIA</option>
                        <option value="CHINA">CHINA</option>
                      </select>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="clearfix"></div>
                    <div id="basicScenario" className="product-physical">
                      <table className="table table-bordered table-striped table-hover table-responsive-md">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              #
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Agent
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Customer
                            </th>

                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Total RMB
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              RMB SELL RATE
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              RMB BUY RATE
                            </th>

                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Total Paid
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Total Bill
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Buying Cost
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Total Profit
                            </th>
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {renderablePurchasings.map((purchasing, index) => (
                            <tr key={index}>
                              <th
                                scope="row"
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {index + 1}
                              </th>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.date}
                              </td>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.agent}
                              </td>
                              <td style={{ color: "#ff8084" }}>
                                <Link
                                  to={`/users/list-user/${purchasing.customerUid}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {purchasing.customer}
                                </Link>
                              </td>

                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.totalRmb || 0}Rmb
                              </td>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.sellRate || 0}Tk
                              </td>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.buyRate || 0}Tk
                              </td>

                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.totalPaid || 0}Tk
                              </td>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.totalBill || 0}Tk
                              </td>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.buyingCost || 0}Tk
                              </td>
                              <td
                                onClick={() => {
                                  this.props.history.push(
                                    `/business-consultancy/purchasing2/${purchasing.id}`
                                  );
                                }}
                              >
                                {purchasing.finalProfit || 0}Tk
                              </td>
                              <td>
                                <span
                                  style={{
                                    padding: "3px 5px",
                                    background:
                                      purchasing.purchasingStatus ==
                                        "Purchasing" ||
                                      purchasing.purchasingStatus == "Purchased"
                                        ? "#f9ac21"
                                        : purchasing.purchasingStatus ==
                                            "Delivered" ||
                                          purchasing.purchasingStatus ==
                                            "Bangladesh Warehouse"
                                        ? "green"
                                        : purchasing.purchasingStatus
                                        ? "cadetblue"
                                        : "red",
                                    color: "white",
                                    borderRadius: "5px",
                                    fontSize: 12,
                                    cursor: "pointer",
                                  }}
                                  data-toggle="modal"
                                  data-target="#StatusModal"
                                  onClick={() => {
                                    this.setState({
                                      purchasingObj: purchasing,
                                      purchasingStatus:
                                        purchasing.purchasingStatus
                                          ? purchasing.purchasingStatus
                                          : "",
                                    });
                                  }}
                                >
                                  {purchasing.purchasingStatus || "Update"}
                                </span>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              colSpan={4}
                              style={{ fontWeight: "bold", textAlign: "end" }}
                            >
                              Total
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {totalRmb}Rmb
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {renderablePurchasings.length > 0
                                ? parseFloat(
                                    sellRate / renderablePurchasings.length
                                  ).toFixed(2)
                                : 0}
                              Tk
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {renderablePurchasings.length > 0
                                ? parseFloat(
                                    buyRate / renderablePurchasings.length
                                  ).toFixed(2)
                                : 0}
                              Tk
                            </td>

                            <td style={{ fontWeight: "bold" }}>
                              {totalPaid}Tk
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {totalBill}Tk
                            </td>
                            <td style={{ fontWeight: "bold" }}>
                              {buyingCost}Tk
                            </td>
                            <td style={{ fontWeight: "bold" }} colSpan={2}>
                              {totalProfit}Tk
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <AllTransactions
                props={this.props}
                selectedTab={this.state.selectedTab}
                agent={this.props.match.params.agent}
                month={this.props.match.params.month}
              />
            )}
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="StatusModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Purchasing Status
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div
                  style={{
                    padding: "10px 0px",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    Invoice Id:{purchasingObj && purchasingObj.id}
                  </div>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Status{" "}
                  <span
                    style={{
                      padding: "3px 5px",
                      background: purchasingObj
                        ? purchasingObj.purchasingStatus == "Purchasing" ||
                          purchasingObj.purchasingStatus == "Purchased"
                          ? "#f9ac21"
                          : purchasingObj.purchasingStatus == "Delivered" ||
                            purchasingObj.purchasingStatus ==
                              "Bangladesh Warehouse"
                          ? "green"
                          : purchasingObj.purchasingStatus
                          ? "cadetblue"
                          : "red"
                        : "red",
                      color: "white",
                      borderRadius: "5px",
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                  >
                    {" "}
                    {purchasingObj && purchasingObj.purchasingStatus}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Order Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.purchasingDate}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Purchased Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.purchasedDate}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Abroad Warehouse Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.abroadWarehouseDate}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  In Shipment Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.InShipmentDate}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Bangladesh Warehouse Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj &&
                      purchasingObj.bangladeshWarehouseDate}{" "}
                  </span>
                </div>
                <div style={{ padding: "5px 0px" }}>
                  Delivered Date:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    {purchasingObj && purchasingObj.deliveredDate}{" "}
                  </span>
                </div>
                <div
                  style={{
                    padding: "2px 0px",
                    borderBottom: "1px solid gainsboro",
                    marginBottom: 20,
                  }}
                ></div>
                {purchasingObj && purchasingObj.productDetails && (
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT DETAILS
                    </label>
                    <div
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5px",
                        minWidth: "100%",
                        padding: 8,
                      }}
                    >
                      {purchasingObj && purchasingObj.productDetails}
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    purchasings: state.purchasings.purchasings,
    allEmployees: state.employees.employees.filter(
      (employee) => employee.status == "Active"
    ),
  };
};

export default connect(mapStateToProps, {
  getAllPurchasingsAgentRedux,
  getAllEmployeeRedux,
})(MonthlyInvoices);

const INITIAL_STATE = {
  products: [],
  soldProducts: [],
  soldProductsMonthly: [],
};

const setProductsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_PRODUCTS":
      return { ...state, products: [...action.payload] };
    case "GET_ALL_SOLD_PRODUCTS":
      return { ...state, soldProducts: [...action.payload] };
    case "GET_ALL_SOLD_PRODUCTS_MONTHLY":
      return { ...state, soldProductsMonthly: [...action.payload] };
    case "UPLOAD_PRODUCT":
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case "UPDATE_PRODUCT":
      const filteredProductsArray = state.products.filter(
        (product) => product.id !== action.payload.id
      );

      return {
        ...state,
        products: [...filteredProductsArray, action.payload],
      };
    case "DELETE_PRODUCT":
      const updatedProductsArray = state.products.filter(
        (product) => product.id !== action.payload
      );
      return {
        ...state,
        products: [...updatedProductsArray],
      };
    default:
      return { ...state };
  }
};
export default setProductsReducer;

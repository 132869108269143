import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./payInvoiceDatatable";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Search } from "react-feather";
import MakePaymentModal from "./makePaymentModal";
import {
  getAllOrdersOfSingleLotRedux,
  getAllCustomerLoansRedux,
} from "../../actions/index";
export class PayInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      invoiceArray: [],
      searchFor: "",
      allOrders: [],
      toggleModalMakePayment: true,
      parcelIdArray: [],
    };
  }
  componentDidMount = async () => {
    this.props.getAllCustomerLoansRedux();
    const [shipmentMethod, lotNo] =
      this.props.match.params.shipmentMethodLotNo.split("-");

    let shippingMethod;
    if (shipmentMethod.includes("D2D")) {
      shippingMethod = "D2D";
    } else {
      shippingMethod = "Freight";
    }
    console.log("component did mount getting called");
    await this.props.getAllOrdersOfSingleLotRedux({
      shipmentMethod: shippingMethod,
      lotNo,
    });
    console.log("data fech finished");
    this.setState({
      allOrders: this.props.orders
        .filter((order) => order.ratePerKg)
        .sort((a, b) => a.cartonNo - b.cartonNo),
    });
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      allOrders: nextProps.orders
        .filter((order) => order.ratePerKg)
        .sort((a, b) => a.cartonNo - b.cartonNo),
    });
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  startToggleMakePaymentModal = async (parcelIdArray) => {
    if (parcelIdArray == null) {
      this.setState({
        toggleModalMakePayment: !this.state.toggleModalMakePayment,
        parcelIdArray: [],
      });
    } else {
      this.setState({
        toggleModalMakePayment: !this.state.toggleModalMakePayment,
        parcelIdArray,
      });
    }
  };

  render() {
    const { allOrders } = this.state;
    const [shipmentMethod, lotNo] =
      this.props.match.params.shipmentMethodLotNo.split("-");

    console.log(this.props);
    return (
      <Fragment>
        <MakePaymentModal
          parcelIdArray={this.state.parcelIdArray}
          toggleModalMakePayment={this.state.toggleModalMakePayment}
          startToggleMakePaymentModal={this.startToggleMakePaymentModal}
        />
        <Breadcrumb title={lotNo} parent={"Invoices"} />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-cart-alt"
                      style={{
                        fontSize: "180%",
                        marginRight: "5px",
                        marginTop: "5px",
                        color: "#430861",
                      }}
                    ></i>
                    Lot no: {lotNo}
                  </h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    {" "}
                    <li
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5rem",
                        padding: "0px 20px",
                        background: "whitesmoke",
                        marginRight: "20px",
                      }}
                    >
                      <form
                        className="form-inline search-form"
                        onSubmit={this.handleSubmit}
                      >
                        <div
                          // className="form-group"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-around",
                          }}
                        >
                          <input
                            className={
                              "form-control-plaintext " +
                              (this.state.searchbar ? "open" : "")
                            }
                            name="searchFor"
                            value={this.state.searchFor}
                            type="search"
                            placeholder="Search Parcel"
                            onChange={this.handleSearchBarChange}
                          />
                          <span
                            // className="d-sm-none mobile-search"
                            onClick={() => this.handleSearchClick()}
                          >
                            <Search
                              style={{
                                marginTop: "5px",
                                borderLeft: "1px solid gainsboro",
                                paddingLeft: "7px",
                                color: "gray",
                              }}
                            />
                          </span>
                        </div>
                      </form>
                    </li>
                  </div>
                </div>

                <div className="card-body order-datatable">
                  <Datatable
                    startToggleMakePaymentModal={
                      this.startToggleMakePaymentModal
                    }
                    history={this.props.history}
                    multiSelectOption={false}
                    myData={
                      !this.state.searchFor
                        ? allOrders
                        : allOrders.filter(
                            (order) =>
                              order.customer
                                .toLowerCase()
                                .includes(this.state.searchFor) ||
                              order.cartonNo.includes(this.state.searchFor) ||
                              order.shippingMark
                                .toLowerCase()
                                .includes(this.state.searchFor.toLowerCase()) ||
                              order.trackingNo
                                .toLowerCase()
                                .includes(this.state.searchFor.toLowerCase())
                          )
                    }
                    pageSize={100}
                    pagination={true}
                    class="-striped -highlight"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.ordersAlg.orders,
  };
};

export default connect(mapStateToProps, {
  getAllOrdersOfSingleLotRedux,
  getAllCustomerLoansRedux,
})(PayInvoice);

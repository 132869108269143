import {
  Home,
  Box,
  DollarSign,
  Tag,
  Clipboard,
  Camera,
  AlignLeft,
  UserPlus,
  Users,
  Chrome,
  BarChart,
  Settings,
  Archive,
  LogIn,
  FileText,
  CreditCard,
} from "react-feather";

const countriesAir = [
  "china",
  "india",
  "thailand",
  "singapore",
  "dubai",
  "hongkong",
  "malaysia",
  "pakistan",
];
const countriesSea = ["china", "thailand", "singapore", "hongkong", "malaysia"];
export const MENUITEMSFORADMIN = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },

  {
    title: "P2P",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/p2p/member-request/new",
        title: "Agent Request",
        type: "link",
      },
      {
        path: "/p2p/member-request/agents",
        title: "All Agents",
        type: "link",
      },
      {
        path: "/p2p/payment-request/new",
        title: "Payment Request",
        type: "link",
      },
      {
        path: "/p2p/new-request",
        title: "New Booking Request",
        type: "link",
      },
      {
        path: "/p2p/approved",
        title: "Approved Booking Request",
        type: "link",
      },

      {
        title: "Warehouse Product",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/warehouse/India warehouse",
          },
          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/warehouse/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/warehouse/USA warehouse",
          },
          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/warehouse/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/warehouse/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/agent/new-request",
        title: "New Pickup Request",
        type: "link",
      },
      {
        path: "/p2p/agent/approved",
        title: "Approved Pickup Request",
        type: "link",
      },
      {
        path: "/p2p/assigned/in shipment",
        title: "In Shipment",
        type: "link",
      },
      {
        title: "Warehouse Delivery",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/assigned/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/assigned/China warehouse",
          },

          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/assigned/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/assigned/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/assigned/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/assigned/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/p2p/rejected",
        title: "Rejected",
        type: "link",
      },
    ],
  },
  {
    title: "Cash in/Cash out",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/daily-expense",
        title: new Date().toDateString(),
        type: "link",
      },
      {
        path: "/expense/expense-history",
        title: "History",
        type: "link",
      },
      {
        path: "/expense/salary",
        title: "Salary",
        type: "link",
      },
      {
        path: "/expense/refunds",
        title: "Refund",
        type: "link",
      },
      {
        path: "/expense/agent-commision",
        title: "Agent commision",
        type: "link",
      },
      {
        path: "/expense/lot-transport",
        title: "Lot Transport",
        type: "link",
      },
      {
        title: "Loans",
        type: "sub",
        active: false,
        children: [
          {
            title: "Monthly",
            type: "link",
            path: "/expense/loans",
          },
          {
            title: "Customer wise",
            type: "link",
            path: "/expense/loans-by-customers",
          },
        ],
      },
      {
        path: "/expense/office",
        title: "Office",
        type: "link",
      },
      {
        path: "/expense/monthly-installment",
        title: "Monthly Installment",
        type: "link",
      },
    ],
  },
  {
    title: "Cash in/out History",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/approve-expense",
        title: "Pending",
        type: "link",
      },
      {
        path: "/expense/expense-history",
        title: "Monthly",
        type: "link",
      },
      {
        path: "/expense/cnf",
        title: "CNF",
        type: "link",
      },
      {
        path: "/expense/office",
        title: "Office",
        type: "link",
      },
      {
        path: "/expense/salary",
        title: "Salary",
        type: "link",
      },
      {
        path: "/expense/refunds",
        title: "Refund",
        type: "link",
      },
      {
        path: "/expense/agent-commision",
        title: "Agent commision",
        type: "link",
      },
      {
        path: "/expense/invest",
        title: "Invest",
        type: "link",
      },
      {
        title: "Partner Payment",
        type: "sub",
        active: false,
        children: [
          {
            title: "TIPU",
            type: "link",
            path: "/expense/partner-payment/TIPU",
          },
          {
            title: "NIBIR",
            type: "link",
            path: "/expense/partner-payment/NIBIR",
          },
        ],
      },

      {
        path: "/expense/daily-cost",
        title: "Daily Cost",
        type: "link",
      },
      {
        path: "/expense/lot-transport",
        title: "Lot Transport",
        type: "link",
      },
      {
        title: "Loans",
        type: "sub",
        active: false,
        children: [
          {
            title: "Monthly",
            type: "link",
            path: "/expense/loans",
          },
          {
            title: "Customer wise",
            type: "link",
            path: "/expense/loans-by-customers",
          },
        ],
      },
      {
        path: "/expense/monthly-installment",
        title: "Monthly Installment",
        type: "link",
      },

      {
        path: "/expense/employee",
        title: "Employee",
        type: "link",
      },
      {
        path: "/expense/fund",
        title: "Fund",
        type: "link",
      },
    ],
  },
  {
    title: "Cash Summary",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/cash-summary",
        title: "Monthly",
        type: "link",
      },
    ],
  },
  {
    title: "Bill History",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/bill-summary",
        title: "Monthly",
        type: "link",
      },
    ],
  },
  {
    title: "Customer Due",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/loans-by-customers2",
        title: "All Customer due",
        type: "link",
      },
    ],
  },
  {
    title: "Business Consultancy",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        title: "Payment Request",
        type: "link",
        path: "/business-consultancy/paymentRequest",
      },
      {
        title: "New Request",
        type: "link",
        path: "/business-consultancy/sourcingRequest",
      },
      {
        title: "Stock",
        type: "sub",
        active: false,
        children: [
          {
            title: "Stock Products",
            type: "link",
            path: "/business-consultancy/stock",
          },
          {
            title: "Sold Products",
            type: "link",
            path: "/business-consultancy/sold",
          },
        ],
      },

      {
        title: "Sourcing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-months",
          },
          {
            title: "Sourcing Agents",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-agents",
          },
        ],
      },
      {
        title: "Purchasing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/purchasing/invoice",
          },
          {
            title: "Purchasing Agents",
            type: "link",
            path: "/business-consultancy/purchasing/agents",
          },
        ],
      },
    ],
  },
  {
    title: "Booking Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/booking-request/Pending",
        title: "Requested",
        type: "link",
      },
      {
        path: "/booking-request/Success",
        title: "Approved",
        type: "link",
      },
      {
        path: "/booking-request-warehouse",
        title: "Warehouse Address",
        type: "link",
      },
    ],
  },
  {
    title: "Lot",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/lot/lot-list/Abroad Warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/lot/lot-list/Abroad Customs",
        title: "Abroad Customs",
        type: "link",
      },
      {
        path: "/lot/lot-list/Ready to Fly",
        title: "Ready to Fly",
        type: "link",
      },
      {
        path: "/lot/lot-list/Bangladesh Customs",
        title: "Bangladesh Customs",
        type: "link",
      },
      {
        path: "/lot/lot-list/Local Warehouse",
        title: "Local Warehouse",
        type: "link",
      },
    ],
  },
  {
    title: "Delivered Lot",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/lot/delivered-lot",
        title: "Delivered Lot",
        type: "link",
      },
    ],
  },
  {
    title: "Orders",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        title: "Door to Door",
        type: "sub",
        active: false,
        children: [
          {
            path: "/order/D2D/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/order/D2D/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/order/D2D/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/order/D2D/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/order/D2D/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      {
        title: "Freight",
        type: "sub",
        active: false,
        children: [
          {
            path: "/order/Freight/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/order/Freight/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/order/Freight/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/order/Freight/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/order/Freight/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      { path: "/orders/express", title: "Express", type: "link" },
    ],
  },

  {
    title: "Invoice",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/invoice-d2d-freight",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/invoice-express",
        title: "Express",
        type: "link",
      },
    ],
  },
  // {
  //   title: "Recharge",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/recharge/recharge-request",
  //       title: "Recharge Request",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-wallet",
  //       title: "Recharge Wallet",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-history",
  //       title: "Recharge History",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   title: "Recharge",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/recharge/recharge-request",
  //       title: "Recharge Request",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-wallet",
  //       title: "Recharge Wallet",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-history",
  //       title: "Recharge History",
  //       type: "link",
  //     },
  //   ],
  // },
  {
    title: "Payments",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/payment-request",
        title: "Payment Request",
        type: "link",
      },
      {
        path: "/pay-invoice",
        title: "Pay Invoice",
        type: "link",
      },
      {
        path: "/payments",
        title: "Payments History",
        type: "link",
      },
    ],
  },
  {
    title: "Payments Express",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/payment-request-express",
        title: "Payment Request",
        type: "link",
      },
      {
        path: "/pay-invoice-express",
        title: "Pay Invoice",
        type: "link",
      },
      {
        path: "/payments-express",
        title: "Payments History",
        type: "link",
      },
    ],
  },
  {
    title: "Delivery",
    icon: FileText,
    type: "sub",
    active: false,
    children: [
      {
        path: "/delivery/D2D",
        title: "D2D",
        type: "link",
      },
      {
        path: "/delivery/Freight",
        title: "Freight",
        type: "link",
      },
      {
        path: "/delivery-express",
        title: "Express",
        type: "link",
      },
    ],
  },
  {
    title: "Refunds",
    icon: CreditCard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/refund/refund-request",
        title: "Refund Request",
        type: "link",
      },
      {
        path: "/refund/all-refunds",
        title: "All Refunds",
        type: "link",
      },
    ],
  },
  {
    title: "Calculation",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        title: "Door to Door",
        type: "sub",
        active: false,
        children: [
          {
            path: "/calculation/D2D/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/calculation/D2D/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/calculation/D2D/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/calculation/D2D/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/calculation/D2D/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      {
        title: "Freight",
        type: "sub",
        active: false,
        children: [
          {
            path: "/calculation/Freight/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/calculation/Freight/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/calculation/Freight/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/calculation/Freight/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/calculation/Freight/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      { path: "/calculation/express", title: "Express", type: "link" },
    ],
  },

  {
    title: "Express Rates",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/express-rates/document",
        title: "Document",
        type: "link",
      },
      {
        path: "/express-rates/parcel",
        title: "Parcel",
        type: "link",
      },
    ],
  },
  {
    title: "D2D Rates",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        title: "Air",
        type: "sub",
        active: false,
        children: countriesAir.map((country) => {
          return {
            path: `/d2d-rates/air-${country}`,
            title: country,
            type: "link",
          };
        }),
      },
      {
        title: "Sea",
        type: "sub",
        active: false,
        children: countriesSea.map((country) => {
          return {
            path: `/d2d-rates/sea-${country}`,
            title: country,
            type: "link",
          };
        }),
      },
    ],
  },
  {
    title: "Users",
    icon: UserPlus,
    type: "sub",
    active: false,
    children: [
      { path: "/users/list-user", title: "User List", type: "link" },
      // { path: '/users/create-user', title: 'Create User', type: 'link' },
    ],
  },

  {
    title: "Communication",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      {
        path: "/communication/lots",
        title: "Lots",
        type: "link",
      },
      {
        path: "/communication/customers",
        title: "Customers",
        type: "link",
      },
      {
        path: "/communication/notice",
        title: "Notice",
        type: "link",
      },
      {
        path: "/communication/blogs",
        title: "Blogs",
        type: "link",
      },
      {
        path: "/communication/intro-modal",
        title: "Intro Modal",
        type: "link",
      },
      {
        path: "/communication/app-banner",
        title: "App Banner",
        type: "link",
      },
      {
        path: "/communication/freight-forwarder",
        title: "Freight Forwarder",
        type: "link",
      },
    ],
  },

  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    active: false,
    children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  },

  {
    title: "Admins",
    icon: UserPlus,
    type: "sub",
    active: false,
    children: [
      { path: "/admins/list-admin", title: "All Admins", type: "link" },
      // { path: '/users/create-user', title: 'Create User', type: 'link' },
    ],
  },

  {
    title: "Register a manager",
    path: "/",
    icon: LogIn,
    type: "link",
    active: false,
  },
];
export const MENUITEMSFORACCOUNTS = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },
  {
    title: "P2P",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/p2p/member-request/new",
        title: "Agent Request",
        type: "link",
      },
      {
        path: "/p2p/member-request/agents",
        title: "All Agents",
        type: "link",
      },
      {
        path: "/p2p/payment-request/new",
        title: "Payment Request",
        type: "link",
      },
      {
        path: "/p2p/new-request",
        title: "New Booking Request",
        type: "link",
      },
      {
        path: "/p2p/approved",
        title: "Approved Booking Request",
        type: "link",
      },

      {
        title: "Warehouse Product",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/warehouse/India warehouse",
          },
          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/warehouse/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/warehouse/USA warehouse",
          },
          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/warehouse/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/warehouse/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/agent/new-request",
        title: "New Pickup Request",
        type: "link",
      },
      {
        path: "/p2p/agent/approved",
        title: "Approved Pickup Request",
        type: "link",
      },
      {
        path: "/p2p/assigned/in shipment",
        title: "In Shipment",
        type: "link",
      },
      {
        title: "Warehouse Delivery",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/assigned/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/assigned/China warehouse",
          },

          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/assigned/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/assigned/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/assigned/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/assigned/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/p2p/rejected",
        title: "Rejected",
        type: "link",
      },
    ],
  },
  {
    title: "Cash in/Cash out",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        path: "/expense/daily-expense",
        title: new Date().toDateString(),
        type: "link",
      },
      {
        path: "/expense/expense-history",
        title: "History",
        type: "link",
      },
      {
        path: "/expense/salary",
        title: "Salary",
        type: "link",
      },
      {
        path: "/expense/refunds",
        title: "Refund",
        type: "link",
      },
      {
        title: "Loans",
        type: "sub",
        active: false,
        children: [
          {
            title: "Monthly",
            type: "link",
            path: "/expense/loans",
          },
          {
            title: "Customer wise",
            type: "link",
            path: "/expense/loans-by-customers",
          },
        ],
      },
      {
        path: "/expense/monthly-installment",
        title: "Monthly Installment",
        type: "link",
      },
      {
        path: "/expense/invest",
        title: "Invest",
        type: "link",
      },
    ],
  },
  {
    title: "Users",
    icon: UserPlus,
    type: "sub",
    active: false,
    children: [
      { path: "/users/list-user", title: "User List", type: "link" },
      // { path: '/users/create-user', title: 'Create User', type: 'link' },
    ],
  },

  {
    title: "Business Consultancy",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        title: "Payment Request",
        type: "link",
        path: "/business-consultancy/paymentRequest",
      },
      {
        title: "New Request",
        type: "link",
        path: "/business-consultancy/sourcingRequest",
      },
      {
        title: "Stock",
        type: "sub",
        active: false,
        children: [
          {
            title: "Stock Products",
            type: "link",
            path: "/business-consultancy/stock",
          },
          {
            title: "Sold Products",
            type: "link",
            path: "/business-consultancy/sold",
          },
        ],
      },

      {
        title: "Sourcing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-months",
          },
          {
            title: "Sourcing Agents",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-agents",
          },
        ],
      },
      {
        title: "Purchasing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/purchasing/invoice",
          },
          {
            title: "Purchasing Agents",
            type: "link",
            path: "/business-consultancy/purchasing/agents",
          },
        ],
      },
    ],
  },
  {
    title: "Booking Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/booking-request/Pending",
        title: "Requested",
        type: "link",
      },
      {
        path: "/booking-request/Success",
        title: "Approved",
        type: "link",
      },
      {
        path: "/booking-request-warehouse",
        title: "Warehouse Address",
        type: "link",
      },
    ],
  },

  {
    title: "Invoice",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/invoice-d2d-freight",
        title: "D2D/Freight",
        type: "link",
      },
      {
        path: "/invoice-express",
        title: "Express",
        type: "link",
      },
    ],
  },
  // {
  //   title: "Recharge",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/recharge/recharge-request",
  //       title: "Recharge Request",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-wallet",
  //       title: "Recharge Wallet",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-history",
  //       title: "Recharge History",
  //       type: "link",
  //     },
  //   ],
  // },
  // {
  //   title: "Recharge",
  //   icon: Tag,
  //   type: "sub",
  //   active: false,
  //   children: [
  //     {
  //       path: "/recharge/recharge-request",
  //       title: "Recharge Request",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-wallet",
  //       title: "Recharge Wallet",
  //       type: "link",
  //     },
  //     {
  //       path: "/recharge/recharge-history",
  //       title: "Recharge History",
  //       type: "link",
  //     },
  //   ],
  // },
  {
    title: "Payments",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/payment-request",
        title: "Payment Request",
        type: "link",
      },
      {
        path: "/pay-invoice",
        title: "Pay Invoice",
        type: "link",
      },
      {
        path: "/payments",
        title: "Payments History",
        type: "link",
      },
    ],
  },
  {
    title: "Payments Express",
    icon: BarChart,
    type: "sub",
    active: false,
    children: [
      {
        path: "/payment-request-express",
        title: "Payment Request",
        type: "link",
      },
      {
        path: "/pay-invoice-express",
        title: "Pay Invoice",
        type: "link",
      },
      {
        path: "/payments-express",
        title: "Payments History",
        type: "link",
      },
    ],
  },

  {
    title: "Refunds",
    icon: CreditCard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/refund/refund-request",
        title: "Refund Request",
        type: "link",
      },
      {
        path: "/refund/all-refunds",
        title: "All Refunds",
        type: "link",
      },
    ],
  },

  {
    title: "Communication",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      {
        path: "/communication/lots",
        title: "Lots",
        type: "link",
      },
      {
        path: "/communication/customers",
        title: "Customers",
        type: "link",
      },
      {
        path: "/communication/notice",
        title: "Notice",
        type: "link",
      },
      {
        path: "/communication/blogs",
        title: "Blogs",
        type: "link",
      },
      {
        path: "/communication/intro-modal",
        title: "Intro Modal",
        type: "link",
      },
      {
        path: "/communication/app-banner",
        title: "App Banner",
        type: "link",
      },
      {
        path: "/communication/freight-forwarder",
        title: "Freight Forwarder",
        type: "link",
      },
    ],
  },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    active: false,
    children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  },
];

export const MENUITEMSFOREMPLOYEE = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },

  {
    title: "Business Consultancy",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        title: "Payment Request",
        type: "link",
        path: "/business-consultancy/paymentRequest",
      },
      {
        title: "New Request",
        type: "link",
        path: "/business-consultancy/sourcingRequest",
      },
      {
        title: "Stock",
        type: "sub",
        active: false,
        children: [
          {
            title: "Stock Products",
            type: "link",
            path: "/business-consultancy/stock",
          },
          {
            title: "Sold Products",
            type: "link",
            path: "/business-consultancy/sold",
          },
        ],
      },

      {
        title: "Sourcing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-months",
          },
          {
            title: "Sourcing Agents",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-agents",
          },
        ],
      },
      {
        title: "Purchasing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/purchasing/invoice",
          },
          {
            title: "Purchasing Agents",
            type: "link",
            path: "/business-consultancy/purchasing/agents",
          },
        ],
      },
    ],
  },
  {
    title: "Booking Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/booking-request/Pending",
        title: "Requested",
        type: "link",
      },
      {
        path: "/booking-request/Success",
        title: "Approved",
        type: "link",
      },
      {
        path: "/booking-request-warehouse",
        title: "Warehouse Address",
        type: "link",
      },
    ],
  },
  {
    title: "Lot",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/lot/lot-list/Abroad Warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/lot/lot-list/Abroad Customs",
        title: "Abroad Customs",
        type: "link",
      },
      {
        path: "/lot/lot-list/Ready to Fly",
        title: "Ready To Fly",
        type: "link",
      },
      {
        path: "/lot/lot-list/Bangladesh Customs",
        title: "Bangladesh Customs",
        type: "link",
      },
      {
        path: "/lot/lot-list/Local Warehouse",
        title: "Local Warehouse",
        type: "link",
      },
    ],
  },

  {
    title: "Orders",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        title: "Door to Door",
        type: "sub",
        active: false,
        children: [
          {
            path: "/order/D2D/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/order/D2D/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/order/D2D/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/order/D2D/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/order/D2D/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      {
        title: "Freight",
        type: "sub",
        active: false,
        children: [
          {
            path: "/order/Freight/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/order/Freight/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/order/Freight/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/order/Freight/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/order/Freight/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      { path: "/orders/express", title: "Express", type: "link" },
    ],
  },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    active: false,
    children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  },
];

export const MENUITEMSFOROFFICER = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: false,
  },

  {
    title: "P2P",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/p2p/member-request/new",
        title: "Agent Request",
        type: "link",
      },
      {
        path: "/p2p/member-request/agents",
        title: "All Agents",
        type: "link",
      },

      {
        path: "/p2p/new-request",
        title: "New Booking Request",
        type: "link",
      },
      {
        path: "/p2p/approved",
        title: "Approved Booking Request",
        type: "link",
      },

      {
        title: "Warehouse Product",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/warehouse/India warehouse",
          },
          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/warehouse/China warehouse",
          },
          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/warehouse/USA warehouse",
          },
          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/warehouse/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/warehouse/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/agent/new-request",
        title: "New Pickup Request",
        type: "link",
      },
      {
        path: "/p2p/agent/approved",
        title: "Approved Pickup Request",
        type: "link",
      },
      {
        path: "/p2p/assigned/in shipment",
        title: "In Shipment",
        type: "link",
      },
      {
        title: "Warehouse Delivery",
        type: "sub",
        active: false,
        children: [
          {
            title: "India Warehouse",
            type: "link",
            path: "/p2p/assigned/India warehouse",
          },

          {
            title: "China Warehouse",
            type: "link",
            path: "/p2p/assigned/China warehouse",
          },

          {
            title: "USA Warehouse",
            type: "link",
            path: "/p2p/assigned/USA warehouse",
          },

          {
            title: "Dubai Warehouse",
            type: "link",
            path: "/p2p/assigned/Dubai warehouse",
          },
          {
            title: "Bangladesh Warehouse",
            type: "link",
            path: "/p2p/assigned/Bangladesh warehouse",
          },
        ],
      },
      {
        path: "/p2p/assigned/delivered",
        title: "Delivered",
        type: "link",
      },
      {
        path: "/p2p/rejected",
        title: "Rejected",
        type: "link",
      },
    ],
  },

  {
    title: "Business Consultancy",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        title: "Payment Request",
        type: "link",
        path: "/business-consultancy/paymentRequest",
      },
      {
        title: "New Request",
        type: "link",
        path: "/business-consultancy/sourcingRequest",
      },
      {
        title: "Stock",
        type: "sub",
        active: false,
        children: [
          {
            title: "Stock Products",
            type: "link",
            path: "/business-consultancy/stock",
          },
          {
            title: "Sold Products",
            type: "link",
            path: "/business-consultancy/sold",
          },
        ],
      },

      {
        title: "Sourcing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-months",
          },
          {
            title: "Sourcing Agents",
            type: "link",
            path: "/business-consultancy/sourcing-invoice-agents",
          },
        ],
      },
      {
        title: "Purchasing",
        type: "sub",
        active: false,
        children: [
          {
            title: "Invoice",
            type: "link",
            path: "/business-consultancy/purchasing/invoice",
          },
          {
            title: "Purchasing Agents",
            type: "link",
            path: "/business-consultancy/purchasing/agents",
          },
        ],
      },
    ],
  },
  {
    title: "Booking Request",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/booking-request/Pending",
        title: "Requested",
        type: "link",
      },
      {
        path: "/booking-request/Success",
        title: "Approved",
        type: "link",
      },
      {
        path: "/booking-request-warehouse",
        title: "Warehouse Address",
        type: "link",
      },
    ],
  },
  {
    title: "Lot",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        path: "/lot/lot-list/Abroad Warehouse",
        title: "Abroad Warehouse",
        type: "link",
      },
      {
        path: "/lot/lot-list/Abroad Customs",
        title: "Abroad Customs",
        type: "link",
      },
      {
        path: "/lot/lot-list/Ready to Fly",
        title: "Ready To Fly",
        type: "link",
      },
      {
        path: "/lot/lot-list/Bangladesh Customs",
        title: "Bangladesh Customs",
        type: "link",
      },
      {
        path: "/lot/lot-list/Local Warehouse",
        title: "Local Warehouse",
        type: "link",
      },
    ],
  },
  {
    title: "Orders",
    icon: DollarSign,
    type: "sub",
    active: false,
    children: [
      {
        title: "Door to Door",
        type: "sub",
        active: false,
        children: [
          {
            path: "/order/D2D/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/order/D2D/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/order/D2D/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/order/D2D/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/order/D2D/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      {
        title: "Freight",
        type: "sub",
        active: false,
        children: [
          {
            path: "/order/Freight/Abroad Warehouse",
            title: "Abroad Warehouse",
            type: "link",
          },
          {
            path: "/order/Freight/Abroad Customs",
            title: "Abroad Customs",
            type: "link",
          },
          {
            path: "/order/Freight/Ready to Fly",
            title: "Ready To Fly",
            type: "link",
          },
          {
            path: "/order/Freight/Bangladesh Customs",
            title: "Bangladesh Customs",
            type: "link",
          },
          {
            path: "/order/Freight/Local Warehouse",
            title: "Local Warehouse",
            type: "link",
          },
        ],
      },
      { path: "/orders/express", title: "Express", type: "link" },
    ],
  },
  {
    title: "Invoice",
    icon: Archive,
    type: "sub",
    active: false,
    children: [
      {
        path: "/invoice-d2d-freight",
        title: "D2D/Freight",
        type: "link",
      },
    ],
  },

  {
    title: "Delivery",
    icon: FileText,
    type: "sub",
    active: false,
    children: [
      {
        path: "/delivery/D2D",
        title: "D2D",
        type: "link",
      },
      {
        path: "/delivery/Freight",
        title: "Freight",
        type: "link",
      },
      {
        path: "/delivery-express",
        title: "Express",
        type: "link",
      },
    ],
  },

  {
    title: "Express Rates",
    icon: Clipboard,
    type: "sub",
    active: false,
    children: [
      {
        path: "/express-rates/document",
        title: "Document",
        type: "link",
      },
      {
        path: "/express-rates/parcel",
        title: "Parcel",
        type: "link",
      },
    ],
  },
  {
    title: "D2D Rates",
    icon: Box,
    type: "sub",
    active: false,
    children: [
      {
        title: "Air",
        type: "sub",
        active: false,
        children: countriesAir.map((country) => {
          return {
            path: `/d2d-rates/air-${country}`,
            title: country,
            type: "link",
          };
        }),
      },
      {
        title: "Sea",
        type: "sub",
        active: false,
        children: countriesSea.map((country) => {
          return {
            path: `/d2d-rates/sea-${country}`,
            title: country,
            type: "link",
          };
        }),
      },
    ],
  },
  {
    title: "Communication",
    icon: Users,
    type: "sub",
    active: false,
    children: [
      {
        path: "/communication/lots",
        title: "Lots",
        type: "link",
      },
      {
        path: "/communication/customers",
        title: "Customers",
        type: "link",
      },
      {
        path: "/communication/notice",
        title: "Notice",
        type: "link",
      },
      {
        path: "/communication/intro-modal",
        title: "Intro Modal",
        type: "link",
      },
      {
        path: "/communication/app-banner",
        title: "App Banner",
        type: "link",
      },
      {
        path: "/communication/freight-forwarder",
        title: "Freight Forwarder",
        type: "link",
      },
    ],
  },
  {
    title: "Settings",
    icon: Settings,
    type: "sub",
    active: false,
    children: [{ path: "/settings/profile", title: "Profile", type: "link" }],
  },
];

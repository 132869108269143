import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "./plus image.jpeg";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import {
  getAllWarehoueProductsp2pRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
} from "../../actions/index";
export class Warehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",

      id: "",
      name: "",
      quantity: "",
      amount: "",
      description: "",

      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      shippingCost: "",
      agentCost: "",
      status: "",
      warehouse: "",
    };
  }

  componentDidMount = async () => {
    const { status } = this.props.match.params;
    const { getAllWarehoueProductsp2pRedux } = this.props;

    if (status) {
      await getAllWarehoueProductsp2pRedux(status);
    }
  };

  componentWillReceiveProps = async (nextProps) => {
    const { status } = this.props.match.params;
    const status2 = nextProps.match.params.status;
    const { getAllWarehoueProductsp2pRedux } = this.props;
    if (status !== status2) {
      await getAllWarehoueProductsp2pRedux(status2);
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);
      console.log(imgUrl);

      this.setState({
        imageUrl: imgUrl,
      });
      console.log(imageUrl);
      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    let bookingObj = {
      id: this.state.id,
      date: this.state.date,
      name: this.state.name,
      imageUrl: this.state.imageUrl,
      description: this.state.description,
      amount: this.state.amount,
      quantity: this.state.quantity,
      userId: this.state.userId,
      status: this.state.status,
      shippingCost: this.state.shippingCost,
      agentCost: this.state.agentCost,
      warehouse: this.state.warehouse,
    };
    console.log(bookingObj);
    await this.props.updateP2PRedux(bookingObj);

    this.setState({
      name: "",
      quantity: "",
      amount: "",
      description: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      shippingCost: "",
      agentCost: "",
      status: "",
      warehouse: "",
    });
  };
  handleReceive = async (bookingObj) => {
    console.log(bookingObj);
    await this.props.updateP2PRedux({
      ...bookingObj,
      status: this.state.warehouse,
    });

    this.setState({
      warehouse: "",
    });
  };

  handleReceiveProduct = async () => {
    let date = new Date();

    if (this.state.loading) {
      alert("Please wait.. your image is uploading");
      return;
    }
    if (this.state.type === "upload") {
      let bookingObj = {
        id: date.getTime().toString(),
        date: date.toLocaleDateString("en-GB"),
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        userId: this.state.userId,
        status: this.props.match.params.status,
        shippingCost: this.state.shippingCost,
        agentCost: this.state.agentCost,
        warehouse: this.state.warehouse,
      };

      await this.props.uploadP2PRedux(bookingObj);
    } else if (this.state.type === "update") {
      let bookingObj = {
        id: this.state.id,
        date: this.state.date,
        name: this.state.name,
        imageUrl: this.state.imageUrl,
        description: this.state.description,
        amount: this.state.amount,
        quantity: this.state.quantity,
        userId: this.state.userId,
        status: this.state.status,
      };
      await this.props.updateP2PRedux(bookingObj);
    }

    this.setState({
      name: "",
      quantity: "",
      amount: "",
      description: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
    });
  };

  getUserName = (agentId) => {
    const { users } = this.props;
    if (users.length > 0) {
      const agentName = users.find((user) => user.uid === agentId);
      if (agentName) {
        return agentName.displayName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  render() {
    const { productObj } = this.state;
    return (
      <Fragment>
        <Breadcrumb title={this.props.match.params.status} parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    {this.props.match.params.status}
                  </h5>
                  {/* <button
                    className="btn"
                    data-toggle="modal"
                    data-target="#personalInfoModal"
                    onClick={() => {
                      this.setState({
                        name: "",
                        quantity: "",
                        amount: "",
                        description: "",
                        productObj: null,
                        loading: false,
                        imageUrl: man,
                        file: "",
                        type: "upload",
                      });
                    }}
                    style={{
                      backgroundColor: "rgb(0, 37, 76)",
                      padding: "10px",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    New Product
                  </button> */}
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Booking Id
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Customer
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Name
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Shipping Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent Status
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.p2p.map((product, index) => (
                          <tr
                            key={index}
                            data-toggle="modal"
                            data-target="#detailInfoModal"
                            onClick={() => {
                              this.setState({
                                productObj: product,
                              });
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{product.bookingId}</td>
                            <td>{product.date}</td>
                            <td>
                              {this.props.users.find(
                                (user) => user.uid === product.userId
                              ) &&
                                this.props.users.find(
                                  (user) => user.uid === product.userId
                                ).displayName}
                            </td>
                            <td>
                              <a href={product.imageUrl} target="_blank">
                                <img
                                  style={{ height: 70, width: 70 }}
                                  src={
                                    product.imageUrl ? product.imageUrl : man
                                  }
                                />
                              </a>
                            </td>
                            <td>{product.name}</td>
                            <td>{product.quantity}</td>
                            <td>{product.amount}Tk</td>
                            <td>{product.shippingCost || 0}Tk</td>
                            <td>{product.agentCost || 0}Tk</td>
                            <td>
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  backgroundColor:
                                    product.status == "pending"
                                      ? "darkorange"
                                      : product.status == "approved"
                                      ? "green"
                                      : product.status == "reject"
                                      ? "red"
                                      : "cadetblue",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {product.status}
                              </div>
                            </td>
                            <td>
                              {product.agentStatus &&
                              product.agentStatus !== "Reject" ? (
                                <div
                                  style={{
                                    color: "white",
                                    padding: "3px 7px",
                                    borderRadius: 5,
                                    backgroundColor:
                                      product.agentStatus == "Pending"
                                        ? "darkorange"
                                        : "green",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {product.agentStatus}
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </td>
                            <td>
                              <div>{this.getUserName(product.agentId)}</div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "100%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  New Product
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT IMAGE
                    </label>
                    <div
                      className="box-input-file"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      {this.state.loading ? (
                        <div
                          className="spinner-border text-light mt-3 ml-4"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <>
                          <img
                            className="img-100 lazyloaded blur-up"
                            src={this.state.imageUrl}
                            alt="#"
                            style={{
                              zIndex: 10,
                              cursor: "pointer",
                              border: "1px solid gainsboro",
                            }}
                            onClick={() => {
                              document
                                .getElementById("upload-image-input")
                                .click();
                            }}
                          />

                          <input
                            id="upload-image-input"
                            className="upload"
                            type="file"
                            style={{
                              position: "absolute",
                              zIndex: 5,
                              maxWidth: "50px",
                              marginTop: "10px",
                            }}
                            onChange={(e) => this._handleImgChange(e, 0)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT NAME
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Product Name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      DESCRIPTION
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Ex: size, color, other details etc"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      QUANTITY
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="quantity"
                      onChange={this.handleChange}
                      value={this.state.quantity}
                      id="exampleFormControlInput1"
                      placeholder="Enter Product Quantity"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRICE
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="amount"
                      onChange={this.handleChange}
                      value={this.state.amount}
                      id="exampleFormControlInput1"
                      placeholder="Enter Amount"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Customer Shipping Cost
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="shippingCost"
                      onChange={this.handleChange}
                      value={this.state.shippingCost}
                      id="exampleFormControlInput1"
                      placeholder="Enter Customer Shipping Cost"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Agent Shipping Cost
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="agentCost"
                      onChange={this.handleChange}
                      value={this.state.agentCost}
                      id="exampleFormControlInput1"
                      placeholder="Enter Agent Shipping Cost"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleReceiveProduct();
                  }}
                >
                  Receive
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Product Image</td>
                      <td style={{ fontWeight: "bold" }}>Product Name</td>
                      <td style={{ fontWeight: "bold" }}>Total Quantity</td>
                      <td style={{ fontWeight: "bold" }}>Total Weight</td>
                      <td style={{ fontWeight: "bold" }}>Total Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {productObj && (
                          <img
                            style={{ height: 70, width: 70 }}
                            src={
                              productObj.imageUrl ? productObj.imageUrl : man
                            }
                          />
                        )}
                      </td>
                      <td>{productObj && productObj.name}</td>
                      <td>{productObj && productObj.quantity}</td>
                      <td>{productObj && productObj.totalWeight}Kg</td>
                      <td>{productObj && productObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="row"
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Shipping Cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {(productObj && productObj.shippingCost) || "0"}Tk
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent Cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {(productObj && productObj.agentCost) || "0"}Tk
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          paddingTop: 0,
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.status == "pending"
                              ? "darkorange"
                              : productObj.status == "approved"
                              ? "green"
                              : productObj.status == "reject"
                              ? "red"
                              : productObj.status == "delivered"
                              ? "darkgreen"
                              : "cadetblue"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.status}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Country:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.selectCountry}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Payment Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.paymentStatus == "Not Generated"
                              ? "gray"
                              : productObj.paymentStatus == "Not Paid"
                              ? "orange"
                              : productObj.paymentStatus == "Pending"
                              ? "darkorange"
                              : productObj.paymentStatus == "Paid"
                              ? "green"
                              : "red"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.paymentStatus}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Payment Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.paymentDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Product Details:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.description}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Booking Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.date}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      From Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouse}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      {productObj && productObj.fromWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouseReceiveDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Assigned Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.assignedDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      To Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouse}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      {productObj && productObj.toWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouseReceiveDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsName}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Payment Status:{" "}
                      {productObj && productObj.agentsName ? (
                        <span
                          style={{
                            padding: 10,
                            fontWeight: "bold",

                            color: "white",
                            padding: "3px 7px",
                            borderRadius: 5,
                            backgroundColor: productObj
                              ? productObj.agentPaid == "Not Generated"
                                ? "gray"
                                : productObj.agentPaid == "Not Paid"
                                ? "orange"
                                : productObj.agentPaid == "Pending"
                                ? "darkorange"
                                : productObj.agentPaid == "Paid"
                                ? "green"
                                : "red"
                              : "white",
                            textAlign: "center",
                          }}
                        >
                          {productObj && productObj.agentPaid
                            ? productObj.agentPaid
                            : "Not Paid"}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Payment Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsPaymentDate}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsAddress}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Mobile No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsMobileNo}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's Postcode:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsPostcode}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Agent's NID/Passport No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsNid}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversName}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversAddress}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Mobile No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversMobileNo}
                      </span>
                    </div>
                    <div
                      style={{ padding: 10, fontWeight: "bold", paddingTop: 0 }}
                    >
                      Receiver's Postcode:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversPostcode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="receiveModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "100%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Receive in Warehouse
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Receiving in Warehouse
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="warehouse"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.warehouse}
                    >
                      <option value="">Select Warehouse</option>
                      <option value="India warehouse">India</option>
                      <option value="Pakistan warehouse">Pakistan</option>
                      <option value="China warehouse">China</option>
                      <option value="Thailand warehouse">Thailand</option>
                      <option value="Dubai warehouse">Dubai</option>
                      <option value="Malaysia warehouse">Malaysia</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleReceive(productObj);
                  }}
                >
                  Receive
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", maxWidth: "100%" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to remove this Product?</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {productObj && (
                          <img
                            style={{ height: 70, width: 70 }}
                            src={
                              productObj.imageUrl ? productObj.imageUrl : man
                            }
                          />
                        )}
                      </td>
                      <td>{productObj && productObj.name}</td>
                      <td>{productObj && productObj.quantity}</td>
                      <td>{productObj && productObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteP2PRedux(productObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2p,
    users: state.users.users,
  };
};

export default connect(mapStateToProps, {
  getAllWarehoueProductsp2pRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
})(Warehouse);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentRedux,
  updatePaymentRequestStatusRedux,
} from "../../../actions/index";
import "./makePaymentModal.css";
import { Link } from "react-router-dom";
import {
  getMultipleOrders,
  getAllSourcingPurchasingByTrackingNo,
} from "../../../firebase/firebase.utils";
import { sendNotifications } from "../../../firebase/fcmRestApi";
import { CircleLoader } from "react-spinners";
class MakePaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndCondition: false,
      loader: false,
      payableOrders: [],
      allSourcingPurchasing: [],
    };
  }

  componentWillReceiveProps = async (nextProps) => {
    const { payableOrders } = nextProps;
    if (nextProps.payableOrders && nextProps.payableOrders.length > 0) {
      console.log("componet will received is getting called inside!");

      let invoicesToPayWithPurchasingSourcingArray = [];
      for (let i = 0; i < payableOrders.length; i++) {
        const invoice = payableOrders[i];
        let allSourcingPurchsing = await getAllSourcingPurchasingByTrackingNo(
          invoice.trackingNo
        );
        invoicesToPayWithPurchasingSourcingArray.push({
          ...payableOrders[i],
          allSourcingPurchsing,
        });
        this.setState({
          payableOrders: invoicesToPayWithPurchasingSourcingArray,
        });
        console.log(allSourcingPurchsing);
      }
    }
  };

  handleSubmit = async (total) => {
    const { admin, paymentRequestObj, rechargeUser, payableOrders } =
      this.props;
    if (this.state.loader) {
      return;
    }
    // admin payment korle admin jabe, user payment korle user jabe
    this.setState({
      loader: true,
    });
    await this.props.makePaymentRedux(
      total,
      paymentRequestObj.paidInvoices,
      rechargeUser,
      admin,
      payableOrders,
      paymentRequestObj.method
    );
    await this.props.updatePaymentRequestStatusRedux({
      ...paymentRequestObj,
      status: "recharged",
    });
    this.setState({
      loader: false,
    });
    toast.success("Payment is successful");
    const message = {
      title: `Parcel Payment Request`,
      body: `Your Payment request is approved. Thank you`,
    };
    const userObj = this.props.allUsers.find(
      (user) => user.uid === paymentRequestObj.userId
    );
    if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
      userObj.deviceToken.map((token) => {
        sendNotifications(token, message);
      });
    }

    this.props.startToggleModal(null, []);
  };
  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  getAllDueSourcingPurchasingDue = (allSourcingPurchasing) => {
    if (allSourcingPurchasing && allSourcingPurchasing.length > 0) {
      let totalDue = 0;
      allSourcingPurchasing.map((sourcing) => {
        let due = sourcing.totalBill;
        if (sourcing.cashIns && sourcing.cashIns.length > 0) {
          let receivedCash = 0;
          sourcing.cashIns.map((cashIn) => {
            receivedCash += parseInt(cashIn.amount);
          });
          due = sourcing.totalBill - receivedCash;
        }

        totalDue += parseInt(due);
      });
      return (
        <div
          style={{ color: "#ff8084", fontWeight: "bold", cursor: "pointer" }}
          data-toggle="modal"
          data-target="#detailInfoModal"
          onClick={() => {
            this.setState({
              allSourcingPurchasing: allSourcingPurchasing,
            });
          }}
        >
          {totalDue} Tk
        </div>
      );
    }
    return "0 Tk";
  };

  render() {
    const { payableOrders } = this.state;

    console.log(payableOrders);
    let total = 0;
    let totalWeight = 0;
    payableOrders.forEach((invoice) => {
      console.log(invoice.finalTotal);
      return (total += invoice.finalTotal);
    });
    payableOrders.map((invoice) => {
      console.log(invoice.finalTotal);
    });
    payableOrders.forEach(
      (invoice) => (totalWeight += parseFloat(invoice.grossWeight))
    );
    console.log(total);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_make_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal order-details-modal-container"
              style={{ backgroundColor: "white" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => this.props.startToggleModal(null, [])}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <h2 className="h2-xl mb-2 fw-6 pb-2 order-details-header">
                            Order Details
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div
                            className="table-responsive-md"
                            style={{
                              border: "1px solid gainsboro",
                              borderBottom: "none",
                            }}
                          >
                            <table className="table">
                              <thead style={{ position: "sticky", top: -3 }}>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Parcel Id
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Carton
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Product
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Consultancy Due
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Gross Weight
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Rate/Kg
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total <br />{" "}
                                    <span style={{ fontSize: 13 }}>
                                      only shipment
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {payableOrders.length > 0 &&
                                  payableOrders.map((invoice) => {
                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.parcelId}
                                      >
                                        <th scope="row">{invoice.parcelId}</th>
                                        <td>{invoice.cartonNo}</td>
                                        <td>{invoice.productName}</td>
                                        <td>
                                          {this.getAllDueSourcingPurchasingDue(
                                            invoice.allSourcingPurchsing
                                          )}
                                        </td>
                                        <td>{invoice.grossWeight}Kg</td>
                                        <td>{invoice.ratePerKg}Tk</td>
                                        <td>{invoice.finalTotal}Tk</td>
                                      </tr>
                                    );
                                  })}

                                <tr className="table-light">
                                  <th scope="row" className="result-td"></th>
                                  <td className="result-td"></td>
                                  <td className="result-td">Total Weight</td>
                                  <td className="result-td">
                                    {parseFloat(totalWeight).toFixed(2)}Kg
                                  </td>

                                  <td className="result-td"></td>
                                  <td className="result-td">Grand Total</td>
                                  <td className="result-td">{total}Tk</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{ display: "flex", flexDirectioin: "row" }}
                          >
                            <input
                              className="terms-condition-checkbox"
                              style={{ marginLeft: 10 }}
                              type="checkbox"
                              name="termsAndCondition"
                              checked={this.state.termsAndCondition}
                              onChange={(e) =>
                                this.setState({
                                  termsAndCondition:
                                    !this.state.termsAndCondition,
                                })
                              }
                            ></input>
                            <div className="agree-terms-condition">
                              I checked and paying the invoices.
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                &nbsp; For any kind of fault
                              </span>
                              &nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                I will be responsible.
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {this.state.termsAndCondition && (
                              <div className="procced-to-checkout">
                                <button
                                  onClick={() => {
                                    this.handleSubmit(total);
                                  }}
                                  className="mt-3 btn btn-secondary "
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_payment_popup"
                                  style={{ minWidth: 120, minHeight: 40 }}
                                >
                                  {!this.state.loader && <>Approve Payment</>}
                                  <CircleLoader
                                    loading={this.state.loader}
                                    color="white"
                                    size={15}
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  All Sourcing and Purchasing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Invoice Type</td>
                      <td style={{ fontWeight: "bold" }}>Invoice Id</td>
                      <td style={{ fontWeight: "bold" }}>Date</td>
                      <td style={{ fontWeight: "bold" }}>Agent</td>
                      <td style={{ fontWeight: "bold" }}>Total Bill</td>
                      <td style={{ fontWeight: "bold" }}>Total Paid</td>
                      <td style={{ fontWeight: "bold" }}>Total Due</td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allSourcingPurchasing.length > 0 &&
                      this.state.allSourcingPurchasing.map((sourcing) => {
                        return (
                          <tr>
                            <td>{sourcing.category}</td>
                            <td>{sourcing.id}</td>
                            <td>{sourcing.date}</td>
                            <td>{sourcing.agent}</td>
                            <td>{sourcing.totalBill} Tk</td>
                            <td>{sourcing.totalPaid} Tk</td>
                            <td>
                              {parseInt(sourcing.totalBill) -
                                (parseInt(sourcing.totalPaid) || 0)}
                              Tk
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.paymentRequestObj);
  return {
    allUsers: state.users.users,
    rechargeUser: ownProps.paymentRequestObj
      ? state.users.users.find(
          (user) => user.uid === ownProps.paymentRequestObj.userId
        )
      : null,
    admin: state.admins.currentAdmin,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentRedux,
    updatePaymentRequestStatusRedux,
  })(MakePaymentModal)
);

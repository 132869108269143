import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentExpressRedux,
  getAllOrdersOfSingleLotRedux,
} from "../../actions/index";
import "./makePaymentModal.css";

class MakePaymentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceArray: [],
      paymentMethod: "",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (
      nextProps.expressOrdersArray &&
      nextProps.expressOrdersArray.length > 0
    ) {
      const invoiceArray = nextProps.expressOrdersArray.filter(
        (parcel) => !parcel.paid
      );
      this.setState({
        invoiceArray,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
  };

  handleChange = (event) => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handlePayment = async (total, invoicesToPay, parcelsArray) => {
    const { currentAdmin } = this.props;
    const { paymentMethod } = this.state;
    // admin payment korle admin jabe, user payment korle user jabe
    if (!paymentMethod) {
      alert("Selet a Payment Method first");
      return;
    }
    await this.props.makePaymentExpressRedux(
      total,
      invoicesToPay,
      null,
      currentAdmin,
      parcelsArray,
      paymentMethod
    );
    toast.success("Payment is successful");
    document.getElementById("modal-close-icon-payment-modal").click();
  };

  render() {
    const { invoiceArray } = this.state;
    const { parcelIdArray } = this.props;
    console.log(parcelIdArray);
    const invoicesToPay = invoiceArray.filter(
      (invoice) => parcelIdArray && parcelIdArray.includes(invoice.bookingId)
    );
    let total = 0;
    invoicesToPay.forEach(
      (invoice) =>
        (total += parseInt(invoice.totalCost) || parseInt(invoice.total))
    );
    console.log(total);
    return (
      <>
        <div
          className={
            this.props.toggleModalMakePayment
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-make-payment"
              style={{
                backgroundColor: "white",
                boxShadow: "rgb(0 0 0) 1px 5px 24px 0px",
              }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => {
                        this.setState({
                          invoiceArray: [],
                        });
                        this.props.startToggleMakePaymentModal(null);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h2
                              className="h2-xl mb-2 fw-6 pb-2"
                              style={{
                                color: "black",
                                textTransform: "none",
                                fontSize: "200%",
                              }}
                            >
                              Make Payment
                            </h2>
                          </div>

                          <div
                            className="table-responsive-md"
                            style={{
                              border: "1px solid gainsboro",
                              borderRadius: "10px",
                              marginLeft: "10px",
                              marginTop: "10px",
                              borderBottom: "none",
                            }}
                          >
                            <table className="table">
                              <thead>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Booking Id
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Box Size
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Product
                                  </th>

                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoicesToPay.length > 0 &&
                                  invoicesToPay.map((invoice) => {
                                    return (
                                      <tr
                                        className="table-light"
                                        key={invoice.bookingId}
                                      >
                                        <th scope="row">{invoice.date}</th>
                                        <td>{invoice.bookingId}</td>
                                        <td>{invoice.parcelType}</td>
                                        <td>{invoice.parcelBox}</td>
                                        <td>{invoice.productName}</td>
                                        <td>
                                          {invoice.totalCost || invoice.total}Tk
                                        </td>
                                      </tr>
                                    );
                                  })}
                                <tr className="table-light">
                                  <th scope="row"></th>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Total
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "150%",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {total}Tk
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <form className="rounded-field mt-4">
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Payment Method
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="paymentMethod"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.paymentMethod}
                                >
                                  <option value="">Payment Method</option>
                                  <option value="Cash">Cash</option>
                                  <option value="Bkash">Bkash</option>
                                  <option value="Rocket">Rocket</option>
                                  <option value="Nagad">Nagad</option>
                                  <option value="Brac Bank">Brac Bank</option>
                                  <option value="IBBL">IBBL</option>
                                  <option value="City Bank">City Bank</option>
                                </select>
                              </div>
                              <div className="col"></div>
                            </div>
                          </form>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              className="mt-3 btn btn-secondary"
                              onClick={() => {
                                this.handlePayment(
                                  total,
                                  parcelIdArray,
                                  invoicesToPay
                                );
                              }}
                            >
                              pay
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    expressOrdersArray: state.ordersAlg.ordersExpress,
    allUser: state.users.users,
    currentAdmin: state.admins.currentAdmin,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentExpressRedux,
    getAllOrdersOfSingleLotRedux,
  })(MakePaymentModal)
);

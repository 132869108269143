import React, { Component } from "react";
import "./editOrderModal.css";
import "./selectLotModal.css";
import { updateOrderInInvoiceRedux, updateLotRedux } from "../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class EditOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: "",
      customerUid: "",
      shippingMark: "",
      productName: "",
      grossWeight: "",
      trackingNo: "",
      showSuggestion: true,
      cursor: -1,
      quantity: "",
      productType: "",
      chineseNote: "",
      packagingCost: 0,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { singleLot, calculation, order } = nextProps;

    console.log("create Lot modal component will receive props is called");
    if (order) {
      this.setState({
        customer: order.customer,
        customerUid: order.customerUid,
        shippingMark: order.shippingMark,
        productName: order.productName,
        grossWeight: order.grossWeight,
        trackingNo: order.trackingNo,
        showSuggestion: true,
        cursor: -1,
        quantity: order.quantity,
        productType: order.productType,
        chineseNote: order.chineseNote,
        packagingCost: order.packagingCost,
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {
      customer,
      customerUid,
      shippingMark,

      productName,

      grossWeight,

      trackingNo,
      quantity,
      productType,
      chineseNote,
      packagingCost,
    } = this.state;
    const { order } = this.props;
    if (order.customer !== customer && order.customerUid === customerUid) {
      alert("please select a customer first");
      return;
    }

    const updatedOrder = await this.props.updateOrderInInvoiceRedux({
      customer,
      customerUid,
      shippingMark,
      productName,
      grossWeight,
      trackingNo,
      parcelId: order.parcelId,
      quantity,
      productType,
      chineseNote,
      packagingCost,
    });

    if (updatedOrder) {
      toast.success("Successfully edited order");
      document.getElementById("edit_order_modal_close_button").click();
    } else {
      alert("There was an error updating your order.");
    }
    this.setState({
      customer: "",
      customerUid: "",
      shippingMark: "",
      productName: "",
      grossWeight: "",
      trackingNo: "",
      showSuggestion: true,
      cursor: -1,
      quantity: "",
      productType: "",
      chineseNote: "",
      packagingCost: 0,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChangeCustomer = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestion: true, cursor: -1 });
  };

  renderShowSuggestion = () => {
    let suggestionArray = [];
    console.log(this.state.customer);
    if (this.state.customer) {
      console.log(this.state.customer);
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      suggestionArray = [...suggestionById, ...suggestionByName];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.userId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customer: user.userId,
              customerUid: user.uid,
              showSuggestion: false,
            })
          }
        >
          {user.userId}-{user.displayName ? user.displayName.slice(0, 13) : ""}
        </li>
      ));
    }
  };

  handleKeyDown = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.customer) {
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      result = [...suggestionById, ...suggestionByName].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customer: result[cursor].userId,
          customerUid: result[cursor].uid,
          showSuggestion: false,
        });
      }
    } else {
      result = [];
    }
  };
  render() {
    const { order } = this.props;
    return (
      <>
        <div
          className={
            this.props.toggleModalEditOrder
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-3"
              style={{
                backgroundColor: "rgb(68 0 97)",
                boxShadow: "rgb(31 29 32) 1px 5px 14px 4px",
              }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      id="edit_order_modal_close_button"
                      onClick={() => {
                        this.setState({
                          customer: "",
                          customerUid: "",
                          shippingMark: "",
                          productName: "",
                          grossWeight: "",
                          trackingNo: "",
                          showSuggestion: true,
                          cursor: -1,
                          quantity: "",
                          productType: "",
                          chineseNote: "",
                          packagingCost: 0,
                        });
                        this.props.startToggleModalEditOrder(null);
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "white", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "200%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            <span>
                              <i className="icofont-ship"></i>
                            </span>
                            Order Id
                            <span style={{ color: "#13c9ca" }}>
                              &nbsp;
                              {order && order.parcelId}
                            </span>
                          </h2>

                          <form
                            onSubmit={this.handleSubmit}
                            className="rounded-field mt-4"
                          >
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Tracking No:
                                </label>
                                <input
                                  type="text"
                                  name="trackingNo"
                                  className="form-control"
                                  placeholder="Enter tracking No"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.trackingNo}
                                  required
                                />
                              </div>
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Product Type:
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="productType"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.productType}
                                  required
                                >
                                  <option value="">Select Product Type</option>
                                  <option value="Liquid">Liquid</option>
                                  <option value="Battery">Battery</option>
                                  <option value="Powder">Powder</option>
                                  <option value="Copy">Copy</option>
                                  <option value="None">None</option>
                                </select>
                              </div>
                            </div>

                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Select Customer:
                                </label>
                                <input
                                  title="Please choose a package"
                                  type="text"
                                  name="customer"
                                  className="form-control"
                                  placeholder="Enter customer Id"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChangeCustomer}
                                  value={this.state.customer}
                                  required
                                  autoComplete="off"
                                  onKeyDown={this.handleKeyDown}
                                />
                                {this.state.customer && (
                                  <ul
                                    className="below-searchbar-recommendation"
                                    style={{
                                      display: this.state.showSuggestion
                                        ? "flex"
                                        : "none",
                                    }}
                                  >
                                    {this.renderShowSuggestion()}
                                  </ul>
                                )}
                              </div>
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Packaging Cost:
                                </label>
                                <input
                                  type="number"
                                  name="packagingCost"
                                  className="form-control"
                                  placeholder="Packaging Cost"
                                  value={this.state.packagingCost}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Quantity:
                                </label>
                                <input
                                  type="text"
                                  name="quantity"
                                  className="form-control"
                                  placeholder="Enter Product Quantity"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.quantity}
                                  required
                                />
                              </div>
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Shipping Mark:
                                </label>
                                <input
                                  type="text"
                                  name="shippingMark"
                                  className="form-control"
                                  placeholder="Enter Shipping Mark"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.shippingMark}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-3">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Note:
                                </label>
                                <textarea
                                  type="text"
                                  name="chineseNote"
                                  className="form-control"
                                  placeholder="Add additional Note"
                                  value={this.state.chineseNote}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Product Name:
                                </label>
                                <input
                                  type="text"
                                  name="productName"
                                  className="form-control"
                                  placeholder="Enter Product Name"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.productName}
                                  required
                                />
                              </div>
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    marginBottom: "5px",
                                    fontSize: "130%",
                                  }}
                                >
                                  Gross Weight:
                                </label>
                                <input
                                  type="text"
                                  name="grossWeight"
                                  className="form-control"
                                  placeholder="Total Weight"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.grossWeight}
                                  required
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div
                                className="col pt-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                >
                                  Update
                                  <i className="icofont-rounded-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allUsers: state.users.users,
  };
};
export default connect(mapStateToProps, {
  updateOrderInInvoiceRedux,
  updateLotRedux,
})(EditOrderModal);

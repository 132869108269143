import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  makePaymentP2pRedux,
  updatePaymentRequestP2pStatusRedux,
} from "../../actions/index";
import "./makePaymentModal.css";
import { Link } from "react-router-dom";
import { getMultipleOrders } from "../../firebase/firebase.utils";
import { CircleLoader } from "react-spinners";
import { sendNotifications } from "../../firebase/fcmRestApi";
class MakePaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsAndCondition: false,
      loader: false,
    };
  }

  handleSubmit = async (total) => {
    const { admin, paymentRequestObj, currentUser } = this.props;
    if (this.state.loader) {
      return;
    }
    // admin payment korle admin jabe, user payment korle user jabe
    this.setState({
      loader: true,
    });
    await this.props.makePaymentP2pRedux(
      total,
      paymentRequestObj.paidInvoices,
      currentUser,
      admin,
      paymentRequestObj.method
    );
    await this.props.updatePaymentRequestP2pStatusRedux({
      ...paymentRequestObj,
      status: "Paid",
    });
    const message = {
      title: `P2P Payment Request`,
      body: `Your Payment request is approved. Thank you`,
    };
    const userObj = this.props.allUsers.find(
      (user) => user.uid === paymentRequestObj.userId
    );
    if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
      userObj.deviceToken.map((token) => {
        sendNotifications(token, message);
      });
    }
    this.setState({
      loader: false,
    });
    toast.success("Payment is successful");

    this.props.startToggleModal(null);
  };
  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { paymentRequestObj } = this.props;

    let total = 0;
    let totalWeight = 0;
    if (paymentRequestObj) {
      paymentRequestObj.paidInvoices.forEach((booking) => {
        return (total += parseInt(booking.shippingCost));
      });
      paymentRequestObj.paidInvoices.forEach(
        (booking) => (totalWeight += parseFloat(booking.totalWeight))
      );
    }

    console.log(total);
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_make_payment_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content login-modal order-details-modal-container"
              style={{ backgroundColor: "white" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3 order-details-main-container">
                    <a
                      id="modal-close-icon-payment-modal"
                      onClick={() => this.props.startToggleModal(null)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "black", fontWeight: "bolder" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="order-details-container">
                          <h2 className="h2-xl mb-2 fw-6 pb-2 order-details-header">
                            Order Details
                          </h2>
                          <div style={{ marginTop: "20px" }}></div>
                          <div
                            className="table-responsive-md"
                            style={{
                              border: "1px solid gainsboro",
                              borderBottom: "none",
                            }}
                          >
                            <table className="table">
                              <thead style={{ position: "sticky", top: -3 }}>
                                <tr className="table-light">
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Booking Id
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Product Image
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Product Name
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total Qunatity
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total Weight
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Total Price
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Agent Cost
                                  </th>
                                  <th
                                    scope="col"
                                    style={{
                                      fontSize: "120%",
                                      fontWeight: "bold",
                                      backgroundColor: "#000066",
                                      color: "white",
                                    }}
                                  >
                                    Shipping Cost
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentRequestObj &&
                                  paymentRequestObj.paidInvoices.length > 0 &&
                                  paymentRequestObj.paidInvoices.map(
                                    (booking) => {
                                      return (
                                        <tr
                                          className="table-light"
                                          key={booking.bookingId}
                                        >
                                          <th scope="row">
                                            {booking.bookingId}
                                          </th>
                                          <td>
                                            {" "}
                                            <img
                                              style={{ height: 70, width: 70 }}
                                              src={booking.imageUrl}
                                            />
                                          </td>
                                          <td>{booking.name}</td>
                                          <td>{booking.quantity}</td>
                                          <td>{booking.totalWeight}Kg</td>
                                          <td>{booking.amount}Tk</td>
                                          <td>{booking.agentCost}Tk</td>
                                          <td>{booking.shippingCost}Tk</td>
                                        </tr>
                                      );
                                    }
                                  )}

                                <tr className="table-light">
                                  <th scope="row" className="result-td"></th>
                                  <td className="result-td"></td>
                                  <td className="result-td"></td>
                                  <td className="result-td"></td>
                                  <td className="result-td">Total Weight</td>
                                  <td className="result-td">
                                    {parseFloat(totalWeight).toFixed(2)}Kg
                                  </td>
                                  <td className="result-td">Grand Total</td>
                                  <td className="result-td">{total}Tk</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            style={{ display: "flex", flexDirectioin: "row" }}
                          >
                            <input
                              className="terms-condition-checkbox"
                              style={{ marginLeft: 10 }}
                              type="checkbox"
                              name="termsAndCondition"
                              checked={this.state.termsAndCondition}
                              onChange={(e) =>
                                this.setState({
                                  termsAndCondition:
                                    !this.state.termsAndCondition,
                                })
                              }
                            ></input>
                            <div className="agree-terms-condition">
                              I checked and paying the invoices.
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                &nbsp; For any kind of fault
                              </span>
                              &nbsp;
                              <span
                                style={{ color: "#ff8084", cursor: "pointer" }}
                              >
                                I will be responsible.
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            {this.state.termsAndCondition && (
                              <div className="procced-to-checkout">
                                <button
                                  onClick={() => {
                                    this.handleSubmit(total);
                                  }}
                                  className="mt-3 btn btn-secondary "
                                  data-dismiss="modal"
                                  data-toggle="modal"
                                  data-target="#request_payment_popup"
                                  style={{ minWidth: 120, minHeight: 40 }}
                                >
                                  {!this.state.loader && <>Approve Payment</>}
                                  <CircleLoader
                                    loading={this.state.loader}
                                    color="white"
                                    size={15}
                                  />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.paymentRequestObj);
  return {
    allUsers: state.users.users,
    currentUser: ownProps.paymentRequestObj
      ? state.users.users.find(
          (user) => user.uid === ownProps.paymentRequestObj.userId
        )
      : null,
    admin: state.admins.currentAdmin,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    makePaymentP2pRedux,
    updatePaymentRequestP2pStatusRedux,
  })(MakePaymentModal)
);

import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./monthData";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import {
  getAllEmployeeRedux,
  uploadPurchasingRedux,
} from "../../../../actions/index";

import { connect } from "react-redux";

export class SoldMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent: "",
      customerName: "",
      customerMobileNo: "",
      customerAddress: "",
      totalRmb: "",
      sellRate: "",
      buyRate: "",
      productLink: "",
      totalPaid: 0,
      buyingCost: 0,
      finalProfit: 0,
    };
  }

  componentDidMount = async () => {
    this.props.getAllEmployeeRedux();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  handleSubmit = () => {
    const date = new Date();
    this.props.uploadPurchasingRedux({
      ...this.state,
      id: date.getTime().toString(),
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      status: "pending",
      totalBill: parseInt(
        parseFloat(this.state.sellRate) * parseFloat(this.state.totalRmb)
      ),
    });
    this.setState({
      agent: "",
      customerName: "",
      customerMobileNo: "",
      customerAddress: "",
      totalRmb: "",
      sellRate: "",
      buyRate: "",
      productLink: "",
      totalPaid: 0,
      buyingCost: 0,
      finalProfit: 0,
    });
  };

  render() {
    const { months, allEmployees } = this.props;

    console.log(this.props);
    return (
      <Fragment>
        <Breadcrumb title="Monthly" parent="Business Consultancy/" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="col-sm-12">
            <div className="card">
              <div
                className="card-header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h5>
                  <i
                    className="icofont-list"
                    style={{
                      fontSize: "130%",
                      marginRight: "5px",
                      color: "darkblue",
                    }}
                  ></i>
                  {this.props.match.params.agent}
                </h5>
                <button
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#personalInfoModal"
                  onClick={() => {
                    this.setState({
                      agent: "",
                      customerName: "",
                      customerMobileNo: "",
                      customerAddress: "",
                      totalRmb: "",
                      sellRate: "",
                      buyRate: "",
                      productLink: "",
                      totalPaid: 0,
                      buyingCost: 0,
                      finalProfit: 0,
                    });
                  }}
                >
                  New Invoice
                </button>
              </div>
              <div className="card-body">
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Datatable
                    startToggleModal={this.startToggleModal}
                    history={this.props.history}
                    multiSelectOption={false}
                    myData={months}
                    pageSize={50}
                    pagination={true}
                    class="-striped -highlight"
                    type="expense"
                    agent={this.props.match.params.agent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Create Purchasing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PURCHASING AGENT
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="agent"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.agent}
                      required
                    >
                      <option value="">SELECT EMPLOYEE</option>
                      {allEmployees.map((employee) => (
                        <option value={`${employee.name}`}>
                          {employee.name}-{employee.designation}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      CUSTOMER NAME
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="customerName"
                      onChange={this.handleChange}
                      value={this.state.customerName}
                      id="exampleFormControlInput1"
                      placeholder="Customer name"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      CUSTOMER MOBILE NO
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="customerMobileNo"
                      onChange={this.handleChange}
                      value={this.state.customerMobileNo}
                      id="exampleFormControlInput1"
                      placeholder="Customer Mobile No"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      CUSTOMER ADDRESS
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="customerAddress"
                      onChange={this.handleChange}
                      value={this.state.customerAddress}
                      id="exampleFormControlInput1"
                      placeholder="Customer Address"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT LINK
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="productLink"
                      onChange={this.handleChange}
                      value={this.state.productLink}
                      id="exampleFormControlInput1"
                      placeholder="Product link"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TOTAL RMB
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="totalRmb"
                      value={this.state.totalRmb}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Total RMB"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      RMB SELL RATE
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="sellRate"
                      value={this.state.sellRate}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="sell rate"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      RMB BUY RATE
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="buyRate"
                      value={this.state.buyRate}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="buy rate"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allEmployees: state.employees.employees,
    months: state.admins.months,
  };
};

export default connect(mapStateToProps, {
  getAllEmployeeRedux,
  uploadPurchasingRedux,
})(SoldMonth);

const INITIAL_STATE = {
  forwarders: [],
};

const forwardersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_FORWARDERS":
      return { ...state, forwarders: [...action.payload] };

      return { ...state, singleMonth: [] };
    case "UPLOAD_FORWARDER":
      return {
        ...state,
        forwarders: [...state.forwarders, action.payload],
      };
    case "UPDATE_FORWARDER":
      const filteredForwardersArray = state.forwarders.filter(
        (forwarder) => forwarder.id !== action.payload.id
      );

      return {
        ...state,
        forwarders: [...filteredForwardersArray, action.payload],
      };
    case "DELETE_FORWARDER":
      const updatedForwardersArray = state.forwarders.filter(
        (forwarder) => forwarder.id !== action.payload
      );
      return {
        ...state,
        forwarders: [...updatedForwardersArray],
      };
    default:
      return { ...state };
  }
};
export default forwardersReducer;

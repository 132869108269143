import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "./plus image.jpeg";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import {
  getAllP2pMemberRequestRedux,
  updateP2pMemberRequestRedux,
} from "../../actions";
import { ShoppingCart, Bell } from "react-feather";
import { withRouter } from "react-router-dom";
import { sendNotifications } from "../../firebase/fcmRestApi";
export class MemberRequestP2p extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",
      id: "",
      name: "",
      quantity: "",
      amount: "",
      description: "",

      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      arrivalDate: "",
    };
  }

  componentDidMount = async () => {
    const { getAllP2pMemberRequestRedux } = this.props;
    await getAllP2pMemberRequestRedux("Pending");
  };

  componentWillReceiveProps = async (nextProps) => {
    const { getAllP2pMemberRequestRedux } = nextProps;
    if (nextProps.match.params.status !== this.props.match.params.status) {
      await getAllP2pMemberRequestRedux("Pending");
    }
  };

  handleSubmit = async (requestObj, status) => {
    const { updateP2pMemberRequestRedux } = this.props;
    await updateP2pMemberRequestRedux({ ...requestObj, status: status });
    const message = {
      title: `P2P member request`,
      body: `Your P2P member request is ${status}`,
    };
    let userObj = this.props.users.find((user) => user.uid == requestObj.uid);
    if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
      userObj.deviceToken.map((token) => {
        sendNotifications(token, message);
      });
    }
  };

  render() {
    const { productObj } = this.state;

    return (
      <Fragment>
        <Breadcrumb title={"Pending Request"} parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    Member Request
                  </h5>
                </div>

                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            User Id
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            User
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Details
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.p2p.map((product, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>

                            <td>
                              {this.props.users.find(
                                (user) => user.uid == product.uid
                              ) &&
                                this.props.users.find(
                                  (user) => user.uid == product.uid
                                ).userId}
                            </td>
                            <td>
                              {this.props.users.find(
                                (user) => user.uid == product.uid
                              ) &&
                                this.props.users.find(
                                  (user) => user.uid == product.uid
                                ).displayName}
                            </td>

                            <td>
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  color:
                                    product.status == "Pending"
                                      ? "darkorange"
                                      : product.status == "Approved"
                                      ? "green"
                                      : product.status == "Reject"
                                      ? "red"
                                      : "cadetblue",
                                }}
                              >
                                {product.status}
                              </div>
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#instructionModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  padding: "5px 7px",
                                  borderRadius: 5,
                                  backgroundColor: "cadetblue",
                                  maxWidth: "100px",
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                see
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}

        <div
          className="modal fade"
          id="instructionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "1400px" }}
          >
            <div className="modal-content" style={{ top: 10 }}>
              <div
                className="modal-header"
                style={{
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "black",
                  }}
                  id="exampleModalLabel"
                >
                  Member Information
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Image
                        </th>
                        <th
                          colSpan={3}
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          NID/Passport No
                        </th>
                        <th
                          scope="col"
                          style={{
                            padding: "30px 15px",
                            color: "white",
                            backgroundColor: "#00254c",
                          }}
                        >
                          Postcode
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {productObj && (
                        <tr>
                          <th scope="row">{productObj.agentsName}</th>

                          <td>
                            <a href={productObj.imageUrl} target="_blank">
                              <img
                                style={{ height: 70, width: 70 }}
                                src={productObj.imageUrl}
                              />
                            </a>
                          </td>
                          <td colSpan={3}>{productObj.agentsAddress}</td>
                          <td>{productObj.agentsNid}</td>
                          <td>{productObj.agentsPostcode}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.handleSubmit(this.state.productObj, "Reject");
                  }}
                >
                  Reject
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.handleSubmit(this.state.productObj, "Approved");
                  }}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2pMemberRequest,
    users: state.users.users,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getAllP2pMemberRequestRedux,
    updateP2pMemberRequestRedux,
  })(MemberRequestP2p)
);

import React, { Component, Fragment } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import "./payInvoiceDatatable.css";
import { connect } from "react-redux";

export class Datatable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      parcelValue: "",
      deliveryAddress: "ALG OFFICE",
      packagingChosed: "",
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.setState(
        {
          checkedValues: [...this.state.checkedValues, i],
        },
        () => {
          console.log("checked values updated!");
        }
      );
    }
  };
  renderEditable = (cellInfo) => {
    const { myData } = this.props;
    if (myData.length > 0) {
      const newData = [];
      myData.forEach((parcel) => {
        //  this is not affecting my output see line 104
        const userObj = this.props.allUser.find(
          (user) => user.uid === parcel.customerUid
        );
        newData.push({
          "Parcel Id": parcel.parcelId,
          CustomerId: parcel.customer,
          Name: userObj && userObj.displayName,
          "Shipping Mark": parcel.shippingMark,
          "Carton No": parcel.cartonNo,
          "Tracking No": parcel.trackingNo,
          "Gross Weight": `${parcel.grossWeight}Kg`,
          "Rate/Kg": `${parcel.ratePerKg}Tk`,
          Total: `${parcel.finalTotal}Tk`,
        });
      });
      return (
        <div
          style={{ backgroundColor: "#fafafa" }}
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => {
            const data = [...newData];
            data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
            this.setState({ myData: data });
          }}
          dangerouslySetInnerHTML={{
            __html: newData[cellInfo.index][cellInfo.column.id],
          }}
        />
      );
    } else {
      return null;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  renderButton = (row, array) => {
    if (array.length > 0) {
      const parcelObj = array.find(
        (parcel) => parcel.parcelId === row.original["Parcel Id"]
      );
      if (parcelObj.invoiceStatus && parcelObj.invoiceStatus === "Paid") {
        return (
          <div
            className="btn-theme icon-left no-shadow align-self-center my_parcel_update_button"
            style={{
              background: "green",
              fontSize: "85%",
              fontFamily: "sans-serif",
              padding: "5px 10px",
              color: "white",
              border: "1px solid white",
              borderRadius: "10px",
            }}
          >
            <i className="icofont-tick-mark"></i> Paid
          </div>
        );
      } else {
        return (
          <div
            className="btn-theme icon-left no-shadow align-self-center my_parcel_update_button"
            to="#"
            style={{
              background: "#7d017d",
              fontSize: "85%",
              fontFamily: "sans-serif",
              padding: "5px 10px",
              color: "white",
              border: "1px solid white",
              borderRadius: "10px",
            }}
          >
            <i className="icofont-location-arrow"></i> Not Paid
          </div>
        );
      }
    } else {
      return "";
    }
  };

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    console.log(this.props);
    const { myData } = this.props;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      myData.forEach((parcel) => {
        const userObj = this.props.allUser.find(
          (user) => user.uid === parcel.customerUid
        );
        newData.push({
          "Parcel Id": parcel.parcelId,
          CustomerId: parcel.customer,
          Name: userObj && userObj.displayName,
          "Shipping Mark": parcel.shippingMark,
          "Carton No": parcel.cartonNo,
          "Tracking No": parcel.trackingNo,
          "Gross Weight": `${parcel.grossWeight}Kg`,
          "Rate/Kg": `${parcel.ratePerKg}Tk`,
          Total: `${parcel.finalTotal}Tk`,
        });
      });
    }
    const columns = [];
    for (var key in newData[0]) {
      let editable = this.renderEditable;
      if (key == "image") {
        editable = null;
      }
      if (key == "status") {
        editable = null;
      }
      if (key === "avtar") {
        editable = null;
      }
      if (key === "vendor") {
        editable = null;
      }
      if (key === "order_status") {
        editable = null;
      }

      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: null,
        style: {
          textAlign: "center",
        },
      });
    }

    columns.push(
      {
        Header: <b>Product Information</b>,
        id: "orderDetails",
        accessor: (str) => "orderDetails",
        Cell: (row) => (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id={`popover-positioned-bottom`}
                style={{ minWidth: "20%" }}
              >
                <Popover.Title
                  style={{ backgroundColor: "#13c9ca", color: "white" }}
                  as="h3"
                >{`Parcel Id: ${row.original["Parcel Id"]}`}</Popover.Title>
                <Popover.Content className="popover-body-container">
                  <div
                    style={{
                      paddingBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      <h3>Product Information</h3>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Product Name:&nbsp;
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).productName
                            : ""}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Product Quantity:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).quantity
                            : ""}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Total CBM:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).totalCbm
                            : ""}
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Total Weight:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? myData.find(
                                (parcel) =>
                                  parcel.parcelId === row.original["Parcel Id"]
                              ).grossWeight
                            : ""}
                          kg
                        </span>
                      </p>
                      <p
                        style={{
                          marginBottom: "0px",
                          borderBottom: "2px solid gainsboro",
                        }}
                      >
                        Chargeable Weight:&nbsp;{" "}
                        <span
                          style={{
                            color: "#13c9ca",
                            fontSize: "130%",
                            fontWeight: "bold",
                          }}
                        >
                          {myData.length > 0
                            ? Math.round(
                                myData.find(
                                  (parcel) =>
                                    parcel.parcelId ===
                                    row.original["Parcel Id"]
                                ).totalCbm * 167
                              )
                            : ""}
                          kg
                        </span>
                      </p>
                    </div>
                  </div>
                </Popover.Content>
              </Popover>
            }
          >
            <button className="btn btn-secondary">info</button>
          </OverlayTrigger>
        ),
        style: {
          textAlign: "center",
        },
        sortable: false,
      },
      {
        Header: <b>Invoice</b>,
        id: "orderDetails",
        accessor: (str) => "orderDetails",
        Cell: (row) => this.renderButton(row, myData),

        style: {
          textAlign: "center",
        },
        sortable: false,
      },

      {
        Header:
          this.state.checkedValues.length > 0 ? (
            <button
              className="btn btn-sm btn-delete mb-0 b-r-4"
              style={{ backgroundColor: "purple", color: "white" }}
              role="button"
              data-toggle="modal"
              data-target="#request_make_payment_popup"
              onClick={() => {
                this.props.startToggleMakePaymentModal(
                  this.state.checkedValues
                );
                console.log(this.state.checkedValues);
                this.setState({ checkedValues: [] });
              }}
            >
              Pay
            </button>
          ) : (
            <button
              style={{
                backgroundColor: "gray",
                color: "white",
                padding: "10px",
                border: "none",
                borderRadius: "5px",
              }}
              onClick={() => window.alert("Please choose an invoice first")}
            >
              multiple pay
            </button>
          ),
        id: "delete",
        accessor: (str) => "delete",
        sortable: false,
        style: {
          textAlign: "center",
        },
        Cell: (row) => {
          const parcelObj = myData.find(
            (parcel) => parcel.parcelId === row.original["Parcel Id"]
          );
          return (
            <div>
              <span>
                {parcelObj.invoiceStatus === "Paid" ? null : (
                  <input
                    type="checkbox"
                    style={{ height: 25, width: 25 }}
                    name={row.original["Parcel Id"]}
                    checked={this.state.checkedValues.includes(
                      row.original["Parcel Id"]
                    )}
                    onChange={(e) =>
                      this.selectRow(e, row.original["Parcel Id"])
                    }
                  />
                )}
              </span>
            </div>
          );
        },
        accessor: key,
        style: {
          textAlign: "center",
        },
      },
      {
        Header: (
          <button
            className="btn btn-sm btn-delete mb-0 b-r-4"
            style={{ backgroundColor: "gainsboro", color: "black" }}
            role="button"
            onClick={() => {
              const parcelIds = myData
                .filter((parcel) => parcel.invoiceStatus !== "Paid")
                .map((parcel) => parcel.parcelId);
              if (this.state.checkedValues.length == parcelIds.length) {
                this.setState(
                  {
                    checkedValues: [],
                  },
                  () => {
                    console.log(this.state.checkedValues.length);
                  }
                );
              } else {
                this.setState({ checkedValues: [...parcelIds] }, () => {
                  console.log(this.state.checkedValues.length);
                });
              }
            }}
          >
            {this.state.checkedValues.length ==
            myData
              .filter((parcel) => parcel.invoiceStatus !== "Paid")
              .map((parcel) => parcel.parcelId).length
              ? "Unselect All"
              : "Select All"}
          </button>
        ),
        id: "delete",
        accessor: (str) => "delete",
        sortable: false,
        style: {
          textAlign: "center",
        },
        Cell: (row) => {},
        accessor: key,
        style: {
          textAlign: "center",
        },
      }
    );

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allUser: state.users.users,
    currentAdmin: state.admins.currentAdmin,
  };
};
export default withRouter(connect(mapStateToProps, {})(Datatable));

import React, { Component } from "react";
import "./blogModal.css";

import { connect } from "react-redux";
import { toast } from "react-toastify";
import { createBlogRedux, updateBlogRedux } from "../../actions/index";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

class AddBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogTitle: "",
      blogBody: "",
    };
  }

  componentDidMount = () => {
    const { blogObj } = this.props;
    this.setState({
      blogTitle: blogObj ? blogObj.blogTitle : "",
      blogBody: blogObj ? blogObj.blogBody : "",
    });
  };

  componentWillReceiveProps = (nextProps) => {
    const { blogObj } = nextProps;
    this.setState({
      blogTitle: blogObj ? blogObj.blogTitle : "",
      blogBody: blogObj ? blogObj.blogBody : "",
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleCkChange = (e, editor) => {
    let data = editor.getData();
    this.setState({
      blogBody: data,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { blogObj } = this.props;
    if (blogObj) {
      await this.props.updateBlogRedux({ id: blogObj.id, ...this.state });
      this.props.history.push("/communication/blogs");
    } else {
      const uniqueId = Math.floor(Math.random() * 10000 - 1);
      const createdAt = new Date().toLocaleDateString("en-US");
      await this.props.createBlogRedux({
        id: uniqueId,
        createdAt,
        ...this.state,
      });
      this.props.history.push("/communication/blogs");
    }
  };

  render() {
    console.log(this.state.blogBody);
    return (
      <>
        <section className="pos-rel bg-light-gray">
          <div className="container-fluid p-3">
            <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
              <div className="col">
                <div className="px-3 m-5">
                  <h2
                    className="h2-xl mb-3 fw-6 pb-2"
                    style={{
                      color: "black",
                      textTransform: "none",
                      fontSize: "200%",
                      borderBottom: "2px dotted white",
                    }}
                  >
                    {this.props.blogObj ? "Update blog" : "Create blog"}
                  </h2>
                  <form
                    onSubmit={this.handleSubmit}
                    className="rounded-field mt-4"
                  >
                    <div className="form-row mb-4">
                      <label
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          marginBottom: "5px",
                        }}
                      >
                        blog Title
                      </label>
                      <textarea
                        type="text"
                        name="blogTitle"
                        className="form-control"
                        placeholder="Enter text here..."
                        style={{ fontSize: "1rem", minHeight: "100px" }}
                        onChange={this.handleChange}
                        value={this.state.blogTitle}
                        required
                      />
                    </div>

                    <label
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      blog Description
                    </label>

                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.blogBody}
                      onChange={this.handleCkChange}
                      config={{
                        simpleUpload: {
                          uploadUrl: "https://alglimited.com/image-upload",
                        },
                        toolbar: [
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "blockQuote",
                          "link",
                          "numberedList",
                          "bulletedList",
                          "imageUpload",
                          "insertTable",
                          "tableColumn",
                          "tableRow",
                          "mergeTableCells",
                          "mediaEmbed",
                          "|",
                          "undo",
                          "redo",
                        ],
                      }}
                    />

                    <div className="form-row">
                      <div
                        className="col pt-3"
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {this.props.blogObj ? (
                          <button type="submit" className="btn btn-secondary">
                            Update
                            <i className="icofont-rounded-right"></i>
                          </button>
                        ) : (
                          <button type="submit" className="btn btn-secondary">
                            Create
                            <i className="icofont-rounded-right"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(ownProps.match.params.id);
  console.log(state);
  return {
    allUsers: state.users.users,
    blogObj: state.blogs.blogs.find(
      (blog) => blog.id == ownProps.match.params.id
    ),
  };
};
export default connect(mapStateToProps, {
  createBlogRedux,
  updateBlogRedux,
})(AddBlog);

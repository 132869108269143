import React, { Component } from "react";
import "./changeExpressStatusModal.css";

import {
  uploadLotRedux,
  updateLotRedux,
  updateBookingRedux,
  getAllReceivedExpressBookingsRedux,
} from "../../actions/index";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { updateExpressMonth } from "../../firebase/firebase.utils";
class ChangeExpressStatusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderStatus: "",
      bookingId: "",
      agent: "",
      tracking: "",
      shipmentNotice: "",
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { singleParcel } = nextProps;

    if (singleParcel != null) {
      this.setState({
        orderStatus: singleParcel["Order Status"],
        bookingId: singleParcel["Booking Id"],
        agent: singleParcel["Agent"] ? singleParcel["Agent"] : "",
        tracking: singleParcel["Tracking"] ? singleParcel["Tracking"] : "",
      });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log(this.state.orderStatus);
    const { bookingObj, singleParcel } = this.props;

    console.log(this.state.orderStatus);
    console.log(singleParcel["Order Status"]);
    if (this.state.orderStatus === "Delivered") {
      console.log(this.state.orderStatus);
      console.log(singleParcel["Order Status"] !== "Delivered");
      await updateExpressMonth(this.props.match.params.month);
    }
    if (this.state.orderStatus) {
      let shipmentStatusScore;
      let LocalWarehouseDate = bookingObj.LocalWarehouseDate
        ? bookingObj.LocalWarehouseDate
        : "";
      let BangladeshCustomsDate = bookingObj.BangladeshCustomsDate
        ? bookingObj.BangladeshCustomsDate
        : "";
      let ReadyToFlyDate = bookingObj.ReadyToFlyDate
        ? bookingObj.ReadyToFlyDate
        : "";
      let AbroadCustomsDate = bookingObj.AbroadCustomsDate
        ? bookingObj.AbroadCustomsDate
        : "";
      let DeliveredDate = bookingObj.DeliveredDate
        ? bookingObj.DeliveredDate
        : "";

      if (this.state.orderStatus === "Local Warehouse") {
        shipmentStatusScore = 1;
        LocalWarehouseDate = new Date().toLocaleDateString("en-US");
      } else if (this.state.orderStatus === "Bangladesh Customs") {
        shipmentStatusScore = 2;
        BangladeshCustomsDate = new Date().toLocaleDateString("en-US");
      } else if (this.state.orderStatus === "Ready to Fly") {
        shipmentStatusScore = 3;
        ReadyToFlyDate = new Date().toLocaleDateString("en-US");
      } else if (this.state.orderStatus === "Abroad Customs") {
        shipmentStatusScore = 4;
        AbroadCustomsDate = new Date().toLocaleDateString("en-US");
      } else if (this.state.orderStatus === "Delivered") {
        shipmentStatusScore = 5;
        DeliveredDate = new Date().toLocaleDateString("en-US");
      }
      await this.props.updateBookingRedux({
        ...this.props.bookingObj,
        orderStatus: this.state.orderStatus,
        agent: this.state.agent,
        tracking: this.state.tracking,
        shipmentNotice: this.state.shipmentNotice,
        shipmentStatusScore,
        LocalWarehouseDate,
        BangladeshCustomsDate,
        ReadyToFlyDate,
        AbroadCustomsDate,
        DeliveredDate,
      });
      await this.props.getAllReceivedExpressBookingsRedux(
        this.props.match.params.month
      );
    }

    this.setState({
      orderStatus: "",
      bookingId: "",
      agent: "",
      tracking: "",
      shipmentNotice: "",
    });
    this.props.startToggleModal(null);
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  render() {
    return (
      <>
        <div
          className={
            this.props.toggleModal
              ? "modal fade show"
              : "modal fade show visible-modal"
          }
          id="request_popup"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered request_popup"
            role="document"
          >
            <div
              className="modal-content visible-modal-content-3"
              style={{ backgroundColor: "rgb(68 0 97)" }}
            >
              <div className="modal-body p-0">
                <section className="pos-rel bg-light-gray">
                  <div className="container-fluid p-3">
                    <a
                      onClick={() => this.props.startToggleModal(null)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i
                        className="icofont-close-line"
                        style={{ color: "white" }}
                      ></i>
                    </a>
                    <div className="d-lg-flex justify-content-end no-gutters mb-spacer-md">
                      <div className="col">
                        <div className="px-3 m-5">
                          <h2
                            className="h2-xl mb-3 fw-6 pb-2"
                            style={{
                              color: "white",
                              textTransform: "none",
                              fontSize: "200%",
                              borderBottom: "2px dotted white",
                            }}
                          >
                            Booking Id: {this.state.bookingId}
                          </h2>
                          <form
                            onSubmit={this.handleSubmit}
                            className="rounded-field mt-4"
                          >
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontSize: "120%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Agent
                                </label>
                                <input
                                  type="text"
                                  name="agent"
                                  className="form-control"
                                  placeholder="Enter Agent Name"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.agent}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontSize: "120%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Tracking No
                                </label>
                                <input
                                  type="text"
                                  name="tracking"
                                  className="form-control"
                                  placeholder="Enter Tracking No"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.tracking}
                                  required
                                />
                              </div>
                            </div>
                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontSize: "120%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Order Status
                                </label>
                                <select
                                  title="Please choose a package"
                                  required
                                  name="orderStatus"
                                  className="custom-select"
                                  aria-required="true"
                                  aria-invalid="false"
                                  onChange={this.handleChange}
                                  value={this.state.orderStatus}
                                  required
                                >
                                  <option value="">Select Order Status</option>

                                  <option value="Local Warehouse">
                                    Local Warehouse
                                  </option>
                                  <option value="Bangladesh Customs">
                                    Bangladesh Customs
                                  </option>
                                  <option value="Ready to Fly">
                                    Ready to Fly
                                  </option>
                                  <option value="Abroad Customs">
                                    Abroad Customs
                                  </option>

                                  <option value="Delivered">Delivered</option>
                                </select>
                              </div>
                            </div>

                            <div className="form-row mb-4">
                              <div className="col">
                                <label
                                  style={{
                                    color: "white",
                                    fontSize: "120%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  জরুরী নোটিশ
                                </label>
                                <textarea
                                  type="text"
                                  name="shipmentNotice"
                                  className="form-control"
                                  placeholder="শিপমেন্ট সংক্রান্ত জরুরী নোটিশ"
                                  style={{ fontSize: "1rem" }}
                                  onChange={this.handleChange}
                                  value={this.state.shipmentNotice}
                                />
                              </div>
                            </div>

                            <div className="form-row">
                              <div
                                className="col pt-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-secondary"
                                >
                                  Update
                                  <i className="icofont-rounded-right"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bookingObj: ownProps.singleParcel
      ? state.ordersAlg.ordersExpress.find(
          (booking) => booking.bookingId === ownProps.singleParcel["Booking Id"]
        )
      : null,
  };
};
export default connect(mapStateToProps, {
  uploadLotRedux,
  updateLotRedux,
  updateBookingRedux,
  getAllReceivedExpressBookingsRedux,
})(ChangeExpressStatusModal);

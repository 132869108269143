import React, { Component, Fragment } from "react";
import Breadcrumb from "../../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { connect } from "react-redux";
import { DollarSign } from "react-feather";
import {
  getAllSourcingsRedux,
  uploadSourcingRedux,
  updateSourcingRedux,
  deleteSourcingRedux,
  getAllEmployeeRedux,
  handleSourcingCashInRedux,
  handleSourcingCashOutRedux,
} from "../../../../actions";
import "./monthly-invoice.css";
export class MonthlyInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalBill: "",
      totalPaid: 0,
      agent: "",

      buyingCost: 0,
      initialProfit: 0,
      agentPaid: 0,
      finalProfit: 0,
      sourcingObj: null,
      type: "",
      description: "",
      subCategory: "",
      customer: "",
      customerUid: "",
      cursor: -1,
    };
  }

  componentDidMount = async () => {
    this.props.getAllSourcingsRedux(this.props.match.params.month);
    this.props.getAllEmployeeRedux();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleChangeCustomer = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, showSuggestion: true, cursor: -1 });
  };
  handleKeyDown = (e) => {
    const { cursor } = this.state;
    let result = [];
    if (this.state.customer) {
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      result = [...suggestionById, ...suggestionByName].slice(0, 10);

      // arrow up/down button should select next/previous list element
      if (e.keyCode === 38 && cursor > -1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor - 1,
        }));
      } else if (e.keyCode === 40 && cursor < result.length - 1) {
        this.setState((prevState) => ({
          cursor: prevState.cursor + 1,
        }));
      } else if (e.keyCode === 13 && cursor > -1) {
        this.setState({
          customer: `${result[cursor].userId}-${result[cursor].displayName}`,
          customerUid: result[cursor].uid,
          showSuggestion: false,
        });
      }
    } else {
      result = [];
    }
  };
  renderShowSuggestion = () => {
    let suggestionArray = [];
    console.log(this.state.customer);
    if (this.state.customer) {
      console.log(this.state.customer);
      const suggestionById = this.props.allUsers.filter((user) =>
        user.userId.includes(this.state.customer)
      );
      const suggestionByName = this.props.allUsers.filter(
        (user) =>
          user.displayName &&
          user.displayName
            .toLowerCase()
            .includes(this.state.customer.toLowerCase())
      );
      suggestionArray = [...suggestionById, ...suggestionByName];
      const uniqueUser = [...new Set(suggestionArray)];
      console.log(suggestionArray);
      return uniqueUser.slice(0, 10).map((user, index) => (
        <li
          key={user.userId}
          style={{
            minWidth: "195px",
            backgroundColor: this.state.cursor == index ? "gainsboro" : "white",
          }}
          onClick={() =>
            this.setState({
              customer: `${user.userId}-${user.displayName}`,
              customerUid: user.uid,
              showSuggestion: false,
              subCategory: `${user.userId}-${user.displayName}`,
            })
          }
        >
          {user.userId}-{user.displayName ? user.displayName.slice(0, 13) : ""}
        </li>
      ));
    }
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  handleSubmit = async (sourcing) => {
    if (this.state.customerUid == "") {
      alert("Please select a customer first");
      return;
    }
    let date = new Date();

    if (this.state.type === "upload") {
      await this.props.uploadSourcingRedux({
        ...this.state,
        id: date.getTime().toString(),
        month: this.getMonthName(),
        date: date.toLocaleDateString("en-GB"),
        status: "pending",
        category: "sourcing",
        invoiceStatus: "Not Paid",
      });
    } else if (this.state.type === "update") {
      let sourcingObj = {
        ...this.state,

        id: sourcing.id,
        month: sourcing.month,
        date: sourcing.date,
        status: "pending",
        sourcingObj: null,
        invoiceStatus: "Not Paid",
      };
      await this.props.updateSourcingRedux(sourcingObj);
    }

    this.setState({
      totalBill: "",
      totalPaid: 0,
      agent: "",
      buyingCost: 0,
      initialProfit: 0,
      agentPaid: 0,
      finalProfit: 0,
      sourcingObj: null,
      type: "",
      description: "",
      customer: "",
      customerUid: "",
      cursor: -1,
    });
  };

  handleCashIn = async () => {
    const { currentAdmin } = this.props;
    let date = new Date();
    if (!this.state.totalPaid) {
      alert("Please enter an amount first");
      return;
    }
    this.props.handleSourcingCashInRedux({
      ...this.state.sourcingObj,
      totalPaid: this.state.totalPaid,
      paymentId: date.getTime().toString(),
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      receiveBy: currentAdmin.name,
    });
    this.setState({
      totalPaid: 0,
    });
  };

  handleCashOut = async () => {
    const { currentAdmin } = this.props;
    let date = new Date();
    if (!this.state.buyingCost) {
      alert("Please enter an amount first");
      return;
    }
    this.props.handleSourcingCashOutRedux({
      ...this.state.sourcingObj,
      buyingCost: this.state.buyingCost,
      paymentId: date.getTime().toString(),
      month: this.getMonthName(),
      date: date.toLocaleDateString("en-GB"),
      receiveBy: currentAdmin.name,
    });
    this.setState({
      buyingCost: 0,
    });
  };

  render() {
    const { open, sourcingObj } = this.state;
    const { sourcings, allEmployees, currentAdmin } = this.props;
    let date = new Date();
    let totalBill = 0;
    let totalPaid = 0;
    let buyingCost = 0;
    let totalProfit = 0;
    let agentProfit = 0;
    let algProfit = 0;
    if (sourcings.length > 0) {
      sourcings.map((sourcing) => {
        totalBill += parseInt(sourcing.totalBill || 0);
        totalPaid += parseInt(sourcing.totalPaid || 0);
        buyingCost += parseInt(sourcing.buyingCost || 0);
        totalProfit += parseInt(sourcing.initialProfit || 0);
        agentProfit += parseInt(sourcing.agentPaid || 0);
        algProfit += parseInt(sourcing.finalProfit || 0);
      });
    }

    console.log(this.props);
    return (
      <Fragment>
        <Breadcrumb title={"Sourcing"} parent="Business Consultancy" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <>
              <div className="card o-hidden widget-cards">
                <div
                  className="card-body"
                  style={{ backgroundColor: "#00254c" }}
                >
                  <div className="media static-top-widget row">
                    <div className="icons-widgets col-4">
                      <div className="align-self-center text-center">
                        <DollarSign className="font-warning" />
                      </div>
                    </div>
                    <div className="media-body col-8">
                      <span
                        className="m-0"
                        style={{
                          fontWeight: "bold",
                          fontSize: "100%",
                          color: "white",
                        }}
                      >
                        {this.props.match.params.month} Total Sourcing Bill
                      </span>
                      <h3 className="mb-0" style={{ color: "darkorange" }}>
                        <CountUp className="counter" end={totalBill} />
                        Tk
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    {this.props.match.params.month}
                  </h5>
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#personalInfoModal"
                    onClick={() => {
                      this.setState({
                        totalBill: "",
                        totalPaid: 0,
                        agent: "",
                        customer: "",
                        customerUid: "",
                        cursor: -1,
                        buyingCost: 0,
                        initialProfit: 0,
                        agentPaid: 0,
                        finalProfit: 0,
                        sourcingObj: null,
                        type: "upload",
                        description: "",
                      });
                    }}
                  >
                    New Invoice
                  </button>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover table-responsive-md">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Invoice Id
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Customer
                          </th>

                          <th
                            scope="col"
                            colSpan={2}
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                              maxWidth: "100px",
                            }}
                          >
                            Product Details
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Paid
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Bill
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Buying Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Total Profit
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent Profit
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            ALG Profit
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Action
                          </th>
                          {currentAdmin ? (
                            currentAdmin.status === "Admin" ||
                            currentAdmin.status === "Accounts" ? (
                              <>
                                <th
                                  scope="col"
                                  style={{
                                    padding: "30px 15px",
                                    color: "white",
                                    backgroundColor: "#00254c",
                                  }}
                                >
                                  Cash In
                                </th>
                                <th
                                  scope="col"
                                  style={{
                                    padding: "30px 15px",
                                    color: "white",
                                    backgroundColor: "#00254c",
                                  }}
                                >
                                  Cash Out
                                </th>
                              </>
                            ) : null
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {sourcings.map((sourcing, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{sourcing.id}</td>
                            <td>{sourcing.date}</td>
                            <td>{sourcing.agent}</td>
                            <td style={{ color: "#ff8084" }}>
                              <Link
                                to={`/users/list-user/${sourcing.customerUid}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {sourcing.customer}
                              </Link>
                            </td>

                            <td colSpan={2}>
                              <pre>{sourcing.description}</pre>
                            </td>
                            <td>{sourcing.totalPaid || 0}Tk</td>
                            <td>{sourcing.totalBill || 0}Tk</td>
                            <td>{sourcing.buyingCost || 0}Tk</td>
                            <td>{sourcing.initialProfit || 0}Tk</td>
                            <td>{sourcing.agentPaid || 0}Tk</td>
                            <td>{sourcing.finalProfit || 0}Tk</td>

                            {sourcing.status === "pending" ? (
                              <td>
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <i
                                    className="icofont-edit"
                                    data-toggle="modal"
                                    data-target="#personalInfoModal"
                                    onClick={() => {
                                      this.setState({
                                        sourcingObj: sourcing,
                                        totalBill: sourcing.totalBill || 0,
                                        totalPaid: 0,
                                        agent: sourcing.agent,

                                        buyingCost: 0,
                                        initialProfit: 0,
                                        agentPaid: 0,
                                        finalProfit: 0,
                                        status: "pending",
                                        type: "update",
                                        description: sourcing.description,
                                        customerUid: sourcing.customerUid,
                                        customer: sourcing.customer,
                                        cursor: -1,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      marginRight: 8,
                                      cursor: "pointer",
                                    }}
                                  />{" "}
                                  <i
                                    className="icofont-trash"
                                    data-toggle="modal"
                                    data-target="#deleteExpenseModal"
                                    onClick={() => {
                                      this.setState({
                                        sourcingObj: sourcing,
                                      });
                                    }}
                                    style={{
                                      color: "red",
                                      marginLeft: 8,
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </td>
                            ) : (
                              <td></td>
                            )}
                            {currentAdmin ? (
                              currentAdmin.status === "Admin" ||
                              currentAdmin.status === "Accounts" ? (
                                <>
                                  <td>
                                    <div
                                      style={{
                                        color: "white",
                                        padding: "3px 7px",
                                        borderRadius: 5,
                                        backgroundColor: "darkgreen",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                      data-toggle="modal"
                                      data-target="#InvoiceInfoModal1"
                                      onClick={() => {
                                        this.setState({
                                          sourcingObj: sourcing,
                                        });
                                      }}
                                    >
                                      <i className="icofont-tick-boxed" />{" "}
                                      receive
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        color: "white",
                                        padding: "3px 7px",
                                        borderRadius: 5,
                                        backgroundColor: "darkorange",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        fontSize: 12,
                                      }}
                                      data-toggle="modal"
                                      data-target="#InvoiceInfoModal2"
                                      onClick={() => {
                                        this.setState({
                                          sourcingObj: sourcing,
                                        });
                                      }}
                                    >
                                      <i className="icofont-tick-boxed" /> spend
                                    </div>
                                  </td>
                                </>
                              ) : (
                                ""
                              )
                            ) : (
                              ""
                            )}
                          </tr>
                        ))}
                        <tr>
                          <td
                            colSpan={7}
                            style={{ fontWeight: "bold", textAlign: "end" }}
                          >
                            Total
                          </td>
                          <td style={{ fontWeight: "bold" }}>{totalPaid}Tk</td>
                          <td style={{ fontWeight: "bold" }}>{totalBill}Tk</td>
                          <td style={{ fontWeight: "bold" }}>{buyingCost}Tk</td>
                          <td style={{ fontWeight: "bold" }}>
                            {totalProfit}Tk
                          </td>
                          <td style={{ fontWeight: "bold" }}>
                            {agentProfit}Tk
                          </td>
                          <td style={{ fontWeight: "bold" }} colSpan={4}>
                            {algProfit}Tk
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "95%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Create Sourcing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      SOURCING AGENT
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="agent"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.agent}
                    >
                      <option value="">SELECT EMPLOYEE</option>
                      {allEmployees.map((employee) => (
                        <option value={`${employee.name}`}>
                          {employee.name}-{employee.designation}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      SELECT CUSTOMER
                    </label>
                    <input
                      title="Please choose a package"
                      style={{ padding: 18 }}
                      type="text"
                      name="customer"
                      className="form-control"
                      placeholder="Enter customer Id"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChangeCustomer}
                      value={this.state.customer}
                      required
                      autoComplete="off"
                      onKeyDown={this.handleKeyDown}
                    />
                    {this.state.customer && (
                      <ul
                        className="below-searchbar-recommendation"
                        style={{
                          display: this.state.showSuggestion ? "flex" : "none",
                          zIndex: 11,
                        }}
                      >
                        {this.renderShowSuggestion()}
                      </ul>
                    )}
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      PRODUCT DETAILS
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="description"
                      onChange={this.handleChange}
                      value={this.state.description}
                      id="exampleFormControlInput1"
                      placeholder="Product information"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      TOTAL BILL
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="totalBill"
                      value={this.state.totalBill}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Total Bill"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit(sourcingObj);
                  }}
                >
                  Create Invoice
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="deleteExpenseModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Sourcing Invoice
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Invoice?</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{sourcingObj && sourcingObj.agent}</td>
                      <td>{sourcingObj && sourcingObj.customer}</td>
                      <td colSpan={2}>
                        <pre>{sourcingObj && sourcingObj.description}</pre>
                      </td>
                      <td>{(sourcingObj && sourcingObj.totalBill) || 0}Tk</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteSourcingRedux(sourcingObj.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="InvoiceInfoModal1"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Receive Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Invoice Id:{sourcingObj && sourcingObj.id}</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{sourcingObj && sourcingObj.agent}</td>
                      <td>{sourcingObj && sourcingObj.customer}</td>
                      <td colSpan={2}>
                        <pre>{sourcingObj && sourcingObj.description}</pre>
                      </td>
                      <td>{(sourcingObj && sourcingObj.totalBill) || 0}Tk</td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                      marginBottom: 5,
                    }}
                  >
                    RECEIVED CASH
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="totalPaid"
                    value={this.state.totalPaid}
                    onChange={this.handleChange}
                    id="exampleFormControlInput1"
                    placeholder="Enter amount"
                    style={{
                      borderColor: "gainsboro",
                      borderRadius: 5,
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkgreen",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.handleCashIn(sourcingObj);
                  }}
                >
                  Cash In
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="InvoiceInfoModal2"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div className="modal-content responsive-modal">
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Spend Cash
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Invoice Id:{sourcingObj && sourcingObj.id}</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{sourcingObj && sourcingObj.agent}</td>
                      <td>{sourcingObj && sourcingObj.customer}</td>
                      <td colSpan={2}>
                        <pre>{sourcingObj && sourcingObj.description}</pre>
                      </td>
                      <td>{(sourcingObj && sourcingObj.totalBill) || 0}Tk</td>
                    </tr>
                  </tbody>
                </table>
                <div className="form-group">
                  <label
                    style={{
                      fontWeight: "bold",
                      color: "#505050",
                      marginBottom: 5,
                    }}
                  >
                    SPEND CASH
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="buyingCost"
                    value={this.state.buyingCost}
                    onChange={this.handleChange}
                    id="exampleFormControlInput1"
                    placeholder="Enter amount"
                    style={{
                      borderColor: "gainsboro",
                      borderRadius: 5,
                    }}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.handleCashOut(sourcingObj);
                  }}
                >
                  Cash Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allUsers: state.users.users,
    currentAdmin: state.admins.currentAdmin,
    sourcings: state.sourcings.sourcings,
    allEmployees: state.employees.employees,
  };
};

export default connect(mapStateToProps, {
  getAllSourcingsRedux,
  uploadSourcingRedux,
  updateSourcingRedux,
  deleteSourcingRedux,
  getAllEmployeeRedux,
  handleSourcingCashInRedux,
  handleSourcingCashOutRedux,
})(MonthlyInvoices);

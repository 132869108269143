const INITIAL_STATE = {
  p2p: [],
  p2pAgentRequest: [],
  p2pMemberRequest: [],
  paymentRequestArray: [],
};

const p2pReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_P2PS":
      return { ...state, p2p: [...action.payload] };
    case "GET_ALL_P2P_AGENT_REQUEST":
      return { ...state, p2pAgentRequest: [...action.payload] };
    case "GET_ALL_P2P_MEMBER_REQUEST":
      return { ...state, p2pMemberRequest: [...action.payload] };

    case "GET_ALL_PAYMENT_REQUEST_P2P":
      return { ...state, paymentRequestArray: [...action.payload] };

    case "UPDATE_PAYMENT_REQUEST_STATUS_P2P":
      const newRequestArray = state.paymentRequestArray.map(
        (paymentRequest) => {
          if (paymentRequest.paymentId == action.payload.paymentId) {
            return action.payload;
          } else {
            return paymentRequest;
          }
        }
      );
      return { ...state, paymentRequestArray: [...newRequestArray] };
    case "UPLOAD_P2P":
      return { ...state, p2p: [action.payload, ...state.p2p] };
    case "UPDATE_P2P":
      const filteredp2p = state.p2p.filter(
        (p2p) => p2p.id !== action.payload.id
      );
      return { ...state, p2p: [action.payload, ...filteredp2p] };
    case "UPDATE_P2P_AGENT_REQUEST":
      const filteredp2pAgent = state.p2pAgentRequest.filter(
        (p2p) => p2p.id !== action.payload.id
      );
      return {
        ...state,
        p2pAgentRequest: [action.payload, ...filteredp2pAgent],
      };
    case "UPDATE_P2P_MEMBER_REQUEST":
      const filteredp2pAgent2 = state.p2pMemberRequest.filter(
        (p2p) => p2p.id !== action.payload.id
      );
      return {
        ...state,
        p2pMemberRequest: [action.payload, ...filteredp2pAgent2],
      };

    case "DELETE_P2P":
      const updatedp2pArray = state.p2p.filter(
        (p2p) => p2p.id !== action.payload
      );
      return {
        ...state,
        p2p: [...updatedp2pArray],
      };
    default:
      return { ...state };
  }
};
export default p2pReducer;

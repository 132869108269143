import axios from "axios";
import jwt from "jsonwebtoken";

export const sendNotifications = async (token, message) => {
  const serviceAccount = {
    // Replace with your actual service account details
    client_email:
      "firebase-adminsdk-86jnu@alg-frontend.iam.gserviceaccount.com",
    private_key:
      "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDj1MP865EfuNlD\nK5dFcyMwTeObGAByj41FPtjkOZ+zQGmyv7c+fgcQmxiRU9DySawJV0bJeio6Qf13\nll1n8n2Fb5mLhGMHb8snEB9xafB83eyZhzA5DbQ0bSrP8mSywwY06M3DfYVvIXvG\noVBcdcPYckSETpi0w4hDdcMyY2kIbmp/6PhmQkCQBa5h+uQy0apIW8eLUKje6tZF\ntn1pZcUXX5y9agbVKAVJ2kmD6bUspKV/zH5zleVV35Qhi7NDC9HtKOK0KN0yMvou\njNrh4zpSteGXgsG+2T4xx49cCfgdRsrbqlvKR6SQWplHtw0/gLfd9tZlt3X8QL2I\nMWssnZl1AgMBAAECggEABMQfsXvQFmTMjr4rtPU6qDkI6Oawo7TjFK7C5Mtbzukr\nDOU71R/Xz/4ennOtbFQkODHONkrK9VsKDWVk4NbOz2rNQIDtfWtWSaPptHqt4D7Y\nQ0HzvU3PFkWuXplIiADScWrpWwyWLQP7TeV0A+smxLFnQ4lNYoKwqBCfm78+pEF4\n9l9vBJc3mFK7/JyHq7PjtLCXVUShTjjT7c7Sv2DUI0Vz+CTtWjN3AxEWZSg3cPEm\nJCxPtyuDFcs+PuXl9qCWNqWzuX/9EfNQADbYjGScFOJ7mXIdFwjghun1dHpVPKAu\n/NguD5bwRJp62un9hrXdhrjLLhJWyYDy4TZYyqFmBQKBgQD6ln+iU7uAAQn9ROPk\ngaj7CDlQRzYiK2oqItzXm12559Plgub0b4MyyUeJMqHwuUD41j5N4VRBT0FMUhpT\n/dB5xC+i2YmDepKbrveXw8KBZqb0RF2cdyRZFNuCmNjymWidEyWflf9SEzUi88qF\n7Qx7OUjs/TqYBOIdKUfrvLQI5wKBgQDowHHaROXyf/vSGXIRYzCjMSQkYuuNE9Ps\ncAOOnQB6dIYRrb2cXObiFn6vd3HbA83ny0SkEHCl1gIrxnQDHeq1epaW1IOb1E+G\nu4znZB7EOFLN9+Pg6Trb0NWS76vwnBBLGAg1DvAHIzEj6JX+IpRPNMV1GobKM+r4\n2Sy31RTzQwKBgQClR4KjtIqlIQpPrJKLcyHbgU8cLfv8iZoadKgo5WbqaYgPh0mB\ny9NDOhMe4PCC8A10kwrBH3ANK5MsHbMT1wMcbVPmG4lh2QS9/qd7yDiQVP6lejos\nK0EkKGeoOdxOFvUzNR/JpIukvNp9VUMtFm73D4+UEGoHPqFNDp9LU0mD3QKBgQDo\nmHDutE2rkpTAOGeS0rInU5OPvT8yQ+9T5/jWCzYQKeRVRHApE3oz1FET3uTr/xhF\nHRBieH9qarzOrEsBVmNYqsJUxtki3//9X+4Gyzon0zWE2cZj3A/S3hag+Vy1XfC2\narTA+UWLjHKuNN+4ESxvHwh452JuLYYS201iBNIj6wKBgBy3k/Bs8wrujA+SIfmd\nI8IUbVHA780K5o5aVvkyD6ZD5PJRWkeQ79ccW15UE+ShKPKK0bFbFVF9w4gDer6Z\n/OpNby31WkUNQjuE6D36jqgHNNB5kUINc3L4DoML8FLTaGvaPDzW+oOQ4ubPeNq9\nMcyfjcRUbck2U9klDkKjuDjo\n-----END PRIVATE KEY-----\n",
  };

  const now = Math.floor(Date.now() / 1000);
  const payload = {
    iss: serviceAccount.client_email,
    scope: "https://www.googleapis.com/auth/cloud-platform",
    aud: "https://oauth2.googleapis.com/token",
    exp: now + 3600,
    iat: now,
  };
  console.log(payload);

  const signedJwt = jwt.sign(payload, serviceAccount.private_key, {
    algorithm: "RS256",
  });
  console.log(signedJwt);
  // Request the access token
  const response = await axios.post(
    "https://oauth2.googleapis.com/token",
    new URLSearchParams({
      grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
      assertion: signedJwt,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  console.log(response);

  let access_token = response.data.access_token;
  console.log("send notification is called!");

  // var data = JSON.stringify({
  //   data: {},
  //   notification: {
  //     title: message.title,
  //     body: message.body,
  //   },
  //   // token
  //   to: `${token}`,
  // });
  console.log(access_token);
  var data = JSON.stringify({
    message: {
      token: `${token}`,
      notification: {
        title: message.title,
        body: message.body,
      },
    },
  });

  var config = {
    method: "post",
    url: "https://fcm.googleapis.com/v1/projects/alg-frontend/messages:send",
    headers: {
      Authorization: "Bearer " + access_token,
      "Content-Type": "application/json; UTF-8",
    },
    data: data,
  };

  let response2 = await axios(config);
  console.log(response2);
};

import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  getAllForwarderRedux,
  updateForwarderRedux,
  uploadForwarderRedux,
  deleteForwarderRedux,
} from "../../../actions/index";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import { getAllForwarders } from "../../../firebase/firebase.utils";
import building from "./building.png";
import user from "./user.png";
export class DailyExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      company: "",
      mobile: "",
      address: "",
      contactPerson: "",
      email: "",
      bmn: "",
      position: "",
      forwarderObj: null,
      type: "upload",
    };
  }

  componentDidMount = async () => {
    this.props.getAllForwarderRedux();
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = () => {
    let date = new Date();
    if (this.state.type === "upload") {
      this.props.uploadForwarderRedux({
        ...this.state,
        id: date.getTime().toString(),
        forwarderObj: null,
      });
    } else {
      this.props.updateForwarderRedux({
        ...this.state,
        forwarderObj: null,
      });
    }
    this.setState({
      company: "",
      mobile: "",
      address: "",
      contactPerson: "",
      email: "",
      bmn: "",
      forwarderObj: null,
    });
  };
  render() {
    const { forwarderObj, type } = this.state;
    const { forwarders } = this.props;

    console.log(this.props);

    return (
      <Fragment>
        <Breadcrumb title={"Freight Forwarder"} parent="communication" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-user-alt-1"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "darkblue",
                      }}
                    ></i>
                    Freight Forwarder
                  </h5>
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#forwarderInfoModal"
                    onClick={() => {
                      this.setState({
                        id: "",
                        company: "",
                        mobile: "",
                        address: "",
                        contactPerson: "",
                        email: "",
                        bmn: "",
                        position: "",
                        forwarderObj: null,
                        type: "upload",
                      });
                    }}
                  >
                    Add forwarder
                  </button>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                            colSpan={2}
                          >
                            Company Name
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            BMN
                          </th>
                          <th
                            scope="col"
                            colSpan={3}
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Address
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                            colSpan={2}
                          >
                            Contact Person
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Details
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        {forwarders.map((forwarder, index) => (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td colSpan={2}>{forwarder.company}</td>
                            <td>{forwarder.bmn}</td>
                            <td colSpan={3}>{forwarder.address}</td>
                            <td colSpan={2}>
                              {forwarder.contactPerson} <br />
                              {forwarder.position}
                            </td>
                            <td style={{ padding: "0px 28px" }}>
                              <div>
                                <p
                                  style={{
                                    padding: "4px",
                                    border: "1px solid red",
                                    color: "red",
                                    cursor: "pointer",
                                    textAlign: "center",
                                  }}
                                  data-toggle="modal"
                                  data-target="#forwarderDetailModal"
                                  onClick={() => {
                                    this.setState({
                                      forwarderObj: forwarder,
                                    });
                                  }}
                                >
                                  More..
                                </p>
                              </div>
                            </td>
                            <td>
                              <div
                                className="row"
                                style={{ justifyContent: "center" }}
                              >
                                <i
                                  className="icofont-edit"
                                  data-toggle="modal"
                                  data-target="#forwarderInfoModal"
                                  onClick={() => {
                                    this.setState({
                                      id: forwarder.id,
                                      company: forwarder.company,
                                      mobile: forwarder.mobile,
                                      address: forwarder.address,
                                      contactPerson: forwarder.contactPerson,
                                      email: forwarder.email,
                                      bmn: forwarder.bmn,
                                      position: forwarder.position,
                                      type: "update",
                                    });
                                  }}
                                  style={{
                                    color: "green",
                                    marginRight: 8,
                                    cursor: "pointer",
                                  }}
                                />{" "}
                                <i
                                  className="icofont-trash"
                                  data-toggle="modal"
                                  data-target="#deleteForwarderModal"
                                  onClick={() => {
                                    this.setState({
                                      id: forwarder.id,
                                      company: forwarder.company,
                                      mobile: forwarder.mobile,
                                      address: forwarder.address,
                                      contactPerson: forwarder.contactPerson,
                                      email: forwarder.email,
                                      bmn: forwarder.bmn,
                                      position: forwarder.position,
                                      type: "update",
                                    });
                                  }}
                                  style={{
                                    color: "red",
                                    marginLeft: 8,
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <div
          className="modal fade"
          id="forwarderInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "110%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  {this.state.type === "upload" ? "Add" : "Update"} Freight
                  Forwarder
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      COMPANY NAME
                    </label>
                    <input
                      title="Please choose a package"
                      style={{ padding: 18 }}
                      type="text"
                      name="company"
                      className="form-control"
                      placeholder="Company Name"
                      aria-required="true"
                      aria-invalid="false"
                      value={this.state.company}
                      onChange={this.handleChange}
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      ADDRESS
                    </label>
                    <textarea
                      title="Please choose a package"
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Detail Address"
                      aria-required="true"
                      aria-invalid="false"
                      value={this.state.address}
                      onChange={this.handleChange}
                      required
                      autoComplete="off"
                    />
                  </div>

                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      CONTACT PERSON
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="contactPerson"
                      onChange={this.handleChange}
                      value={this.state.contactPerson}
                      id="exampleFormControlInput1"
                      placeholder="Contact Person Details"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      POSITION
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      name="position"
                      onChange={this.handleChange}
                      value={this.state.position}
                      id="exampleFormControlInput1"
                      placeholder="Contact Person Postion"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      EMAIL
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="email address"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      MOBILE
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobile"
                      value={this.state.mobile}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="Mobile no/telephone"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      BMN
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="bmn"
                      value={this.state.bmn}
                      onChange={this.handleChange}
                      id="exampleFormControlInput1"
                      placeholder="BMN"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {this.state.type === "upload" ? "Create" : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="forwarderDetailModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "140%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "#f4eadb",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div className="row" style={{ minWidth: "100%" }}>
                  <div className="col-3">
                    <img
                      src={building}
                      style={{ height: 140, width: 120 }}
                    ></img>
                  </div>
                  <div className="col">
                    <div
                      className="row"
                      style={{
                        fontWeight: "bold",
                        fontSize: "20",
                        letterSpacing: 1,
                        marginBottom: 10,
                        marginTop: 20,
                      }}
                    >
                      {forwarderObj && forwarderObj.company}
                    </div>
                    <div
                      className="row"
                      style={{ fontWeight: "bold", marginBottom: 7 }}
                    >
                      Address:
                      <span style={{ fontWeight: "lighter" }}>
                        &nbsp;{forwarderObj && forwarderObj.address}
                      </span>
                    </div>
                    <div
                      className="row"
                      style={{ fontWeight: "bold", marginBottom: 7 }}
                    >
                      Mobile No:
                      <span style={{ fontWeight: "lighter" }}>
                        &nbsp;{forwarderObj && forwarderObj.mobile}
                      </span>
                    </div>
                    <div
                      className="row"
                      style={{ fontWeight: "bold", marginBottom: 7 }}
                    >
                      {" "}
                      Email:
                      <span style={{ fontWeight: "lighter" }}>
                        &nbsp;{forwarderObj && forwarderObj.email}
                      </span>
                    </div>
                  </div>
                </div>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "black" }}>
                    &times;
                  </span>
                </button>
              </div>

              <div className="modal-body">
                <div>
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "darkgray",
                    }}
                  >
                    BRIEF OF COMPANY{" "}
                  </div>
                  <div
                    style={{
                      height: 3,
                      width: 50,
                      backgroundColor: "darkorange",
                      marginBottom: 10,
                    }}
                  ></div>
                  <div
                    style={{
                      color: "black",
                      letterSpacing: 1,
                      lineHeight: 1.3,
                    }}
                  >
                    The Cargo Company was established in 2016 to supply
                    customers and trading partners with a cost effective,
                    reliable range of international logistics services. Today we
                    have completed over 4 years in the shipping industry and
                    this experience has helped us build a reputation as
                    professional, reliable and efficient company. We have been
                    immensely blessed by support from our customers which has
                    spelled our success story. We are today one of the fastest
                    growing logistics providers in Bangladesh with a strong
                    agency network all around the world. We are member of
                    Bangladesh China chamber of commerce and industry (BCCCI)
                    and E-Commerce association of Bangladesh (e-Cab). We have a
                    dedicated, hands-on team committed to providing the highest
                    standards of services. Our employees are regularly trained
                    and the market trends are systematically investigated by
                    experts; reason being that we believe our customers deserve
                    nothing but the best. Our employees arrange the most complex
                    of road, air and ocean shipments, door-to-door deliveries
                    and insurance, warehousing and distribution services. We are
                    also authorized custom house agents since 2006 and thus
                    render different types of custom clearance services to the
                    clients. We handle all documentation, custom broking and
                    inland clearance for our customers. Every day The Cargo Co.
                    solves logistic doubts of its clients and provides them a
                    tailor-made supply chain solution.
                  </div>
                  <div
                    style={{
                      backgroundColor: "#ece9e9",
                      border: "1px dashed #ccc",
                      marginTop: 35,
                      padding: 20,
                    }}
                  >
                    <div className="row">
                      <div className="col-3">
                        <img
                          src={user}
                          style={{ height: 100, widht: 100 }}
                        ></img>
                      </div>
                      <div className="col" style={{ marginTop: 20 }}>
                        <div
                          className="row"
                          style={{ fontWeight: "bold", marginBottom: 5 }}
                        >
                          {forwarderObj && forwarderObj.contactPerson}
                        </div>
                        <div className="row" style={{ color: "gray" }}>
                          {forwarderObj && forwarderObj.position}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deleteForwarderModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, margin: "auto", minWidth: "140%" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Delete Forwarder
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div>Are you sure you want to delete this Forwarder?</div>
                </div>
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.company}</td>
                      <td colSpan={2}>{this.state.address}</td>
                      <td>{this.state.contactPerson}</td>
                      <td>{this.state.mobile}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.props.deleteForwarderRedux(this.state.id);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forwarders: state.forwarder.forwarders,
  };
};

export default connect(mapStateToProps, {
  getAllForwarderRedux,
  updateForwarderRedux,
  uploadForwarderRedux,
  deleteForwarderRedux,
})(DailyExpense);

import React, { Component, Fragment } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { getSingleBookingRedux, updateBookingRedux } from "../../actions/index";
import "./css/invoice-by-order.css";
import Alg from "./alg.png";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Search } from "react-feather";
import Print from "./print";
export class InvoiceByOrderExpress extends Component {
  state = {
    userObj: null,
    discountInvoice: 0,
    otherCharges: 0,
  };
  componentDidMount = async () => {
    await this.props.getSingleBookingRedux(this.props.match.params.bookingId);
    if (this.props.bookingObj) {
      this.setState({
        userObj: this.props.users.find(
          (user) => user.uid == this.props.bookingObj.userId
        ),
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.bookingObj) {
      this.setState({
        userObj: nextProps.users.find(
          (user) => user.uid == nextProps.bookingObj.userId
        ),
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    console.log("handle submit is getting called");
    const { bookingObj } = this.props;
    const { userObj } = this.state;
    console.log(userObj);
    console.log(bookingObj);

    await this.props.updateBookingRedux({
      ...bookingObj,
      discountInvoice: this.state.discountInvoice,
      otherCharges: this.state.otherCharges,
      totalCost:
        parseInt(bookingObj.totalCost) +
        parseInt(this.state.otherCharges) -
        parseInt(this.state.discountInvoice),
      total:
        parseInt(bookingObj.total) +
        parseInt(this.state.otherCharges) -
        parseInt(this.state.discountInvoice),
    });
    await this.props.getSingleBookingRedux(this.props.match.params.bookingId);
    this.setState({ discountInvoice: 0, otherCharges: 0 });
  };

  render() {
    return (
      <Fragment>
        <Breadcrumb title="Invoice" parent="Invoice" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <h5>Invoice</h5>
                  <div>
                    <form
                      className="form"
                      onSubmit={this.handleSubmit}
                      style={{
                        padding: "15px",
                        background: "#8a0368",
                        borderRadius: "10px",
                      }}
                    >
                      <div className="form-row">
                        <div className="col">
                          <label
                            style={{
                              color: "white",
                              marginBottom: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            Discount Amount
                          </label>
                          <input
                            style={{
                              padding: "10px",
                              marginTop: "2px",
                              outline: "none",
                            }}
                            className="form-control"
                            name="discountInvoice"
                            value={this.state.discountInvoice}
                            type="number"
                            placeholder="Enter Amount"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col">
                          <label
                            style={{
                              color: "white",
                              marginBottom: "5px",
                              fontWeight: "bold",
                            }}
                          >
                            Other charges
                          </label>

                          <input
                            style={{
                              padding: "10px",
                              marginTop: "2px",
                              outline: "none",
                            }}
                            className="form-control"
                            name="otherCharges"
                            value={this.state.otherCharges}
                            type="number"
                            placeholder="Enter Amount"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div
                        className="form-row mt-3"
                        style={{
                          justifyContent: "flex-end",
                          marginRight: "4px",
                        }}
                      >
                        <button className="btn btn-secondary" type="submit">
                          UPDATE!
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="card-body">
                  <Print orderObj={false} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    bookingObj: state.bookings.bookingObj,
    users: state.users.users,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getSingleBookingRedux,

    updateBookingRedux,
  })(InvoiceByOrderExpress)
);

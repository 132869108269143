import React, { Component, Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Datatable from "./lotListDatatable";
import { getAllLotsRedux } from "../../../actions/index";
import { Link } from "react-router-dom";
import TextOrMailModal from "../textOrmailModal";
import { connect } from "react-redux";
import { Search } from "react-feather";

export class CommunicationByLots extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchFor: "",
      open: false,

      toggleModal: true,
      singleLot: null,
    };
  }

  componentDidMount = async () => {
    this.props.getAllLotsRedux();
  };

  startToggleModal = async (lotObj) => {
    if (lotObj == null) {
      this.setState({ toggleModal: !this.state.toggleModal, singleLot: null });
    } else {
      console.log(lotObj);
      this.setState({
        toggleModal: !this.state.toggleModal,
        singleLot: lotObj,
      });
    }
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { open, searchFor } = this.state;
    const { currentAdmin } = this.props;
    console.log(this.props);
    return (
      <Fragment>
        {currentAdmin && currentAdmin.adminId && (
          <>
            <TextOrMailModal
              toggleModal={this.state.toggleModal}
              startToggleModal={this.startToggleModal}
              singleLot={this.state.singleLot}
            />
            <Breadcrumb title="Text/Mail/Notification" parent="Lot" />
            {/* <!-- Container-fluid starts--> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h5>
                        {" "}
                        <i
                          className="icofont-send-mail"
                          style={{
                            fontSize: "180%",
                            marginRight: "5px",
                            marginTop: "5px",
                            color: "#ff8084",
                          }}
                        ></i>
                        Text,Mail,Notification
                      </h5>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        {" "}
                        <li
                          style={{
                            border: "1px solid gainsboro",
                            borderRadius: "5rem",
                            padding: "0px 20px",
                            background: "whitesmoke",
                            marginRight: "20px",
                          }}
                        >
                          <form
                            className="form-inline search-form"
                            onSubmit={this.handleSubmit}
                          >
                            <div
                              // className="form-group"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-around",
                              }}
                            >
                              <input
                                className={
                                  "form-control-plaintext " +
                                  (this.state.searchbar ? "open" : "")
                                }
                                name="searchFor"
                                value={this.state.searchFor}
                                type="search"
                                placeholder="Search lot"
                                onChange={this.handleSearchBarChange}
                              />
                              <span onClick={() => this.handleSearchClick()}>
                                <Search
                                  style={{
                                    marginTop: "5px",
                                    borderLeft: "1px solid gainsboro",
                                    paddingLeft: "7px",
                                    color: "gray",
                                  }}
                                />
                              </span>
                            </div>
                          </form>
                        </li>
                        <li>
                          {/* <button
                        className="btn"
                        style={{
                          backgroundColor: "rgb(68, 0, 97)",
                          color: "white",
                        }}
                        type="button"
                        onClick={() => this.startToggleModal(null)}
                      >
                        Text
                      </button> */}
                        </li>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="clearfix"></div>
                      <div id="basicScenario" className="product-physical">
                        <Datatable
                          startToggleModal={this.startToggleModal}
                          history={this.props.history}
                          multiSelectOption={false}
                          myData={
                            !searchFor
                              ? this.props.allLots
                              : this.props.allLots.filter((lot) =>
                                  lot.lotNo
                                    .toLowerCase()
                                    .includes(searchFor.toLowerCase())
                                )
                          }
                          pageSize={50}
                          pagination={true}
                          class="-striped -highlight"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allLots: state.lots.lots,
    currentAdmin: state.admins.currentAdmin,
  };
};

export default connect(mapStateToProps, { getAllLotsRedux })(
  CommunicationByLots
);

import React, { Component, Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, TabPanel, Tab } from "react-tabs";
import man from "./plus image.jpeg";
import { uploadImageRechargeRequest } from "../../firebase/firebase.utils";
import {
  getAllP2PRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
  getAllDeliveryWarehoueProductsp2pRedux,
  handleP2pAgentPayRedux,
} from "../../actions/index";
import { Search } from "react-feather";
import { sendNotifications } from "../../firebase/fcmRestApi";
export class P2pRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      toggleModal: true,
      singleLot: null,
      expenseObj: null,
      loader: false,
      type: "upload",
      checkedValues: [],
      checkedValues2: [],
      id: "",
      name: "",
      quantity: "",
      amount: "",
      description: "",
      searchFor: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      type: "",
      customer: "",
      customerUid: "",
      cursor: -1,
      shippingCost: "",
      agentCost: "",
      status: "",
      warehouse: "",
    };
  }

  componentDidMount = async () => {
    const { status } = this.props.match.params;
    const { getAllP2PRedux, getAllDeliveryWarehoueProductsp2pRedux } =
      this.props;
    if (status) {
      if (status.includes("warehouse")) {
        await getAllDeliveryWarehoueProductsp2pRedux(status);
      } else {
        await getAllP2PRedux(status);
      }
    }
  };

  componentWillReceiveProps = async (nextProps) => {
    const { status } = this.props.match.params;
    const status2 = nextProps.match.params.status;
    const { getAllP2PRedux, getAllDeliveryWarehoueProductsp2pRedux } =
      this.props;

    if (status !== status2) {
      this.setState({
        checkedValues: [],
        checkedValues2: [],
      });
      if (status2.includes("warehouse")) {
        await getAllDeliveryWarehoueProductsp2pRedux(status2);
      } else {
        await getAllP2PRedux(status2);
      }
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleSearchBarChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  _handleImgChange = async (e, i) => {
    e.preventDefault();
    const { currentAdmin } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];
    const { imageUrl } = this.state;

    reader.onloadend = () => {
      // imageUrl = reader.result;
      this.setState({
        file: file,
        imageUrl,
      });
    };
    if (file) {
      this.setState({ loading: true });
      reader.readAsDataURL(file);
      const imgUrl = await uploadImageRechargeRequest(file);

      this.setState({
        imageUrl: imgUrl,
      });

      this.setState({ loading: false });
    }
  };

  clickActive = (event) => {
    document.querySelector(".nav-link").classList.remove("show");
    event.target.classList.add("show");
  };

  handleSubmit = async () => {
    let bookingObj = {
      id: this.state.id,
      date: this.state.date,
      name: this.state.name,
      imageUrl: this.state.imageUrl,
      description: this.state.description,
      amount: this.state.amount,
      quantity: this.state.quantity,
      userId: this.state.userId,
      status: this.state.status,
      shippingCost: this.state.shippingCost,
      agentCost: this.state.agentCost,
      warehouse: this.state.warehouse,
    };

    await this.props.updateP2PRedux(bookingObj);

    this.setState({
      name: "",
      quantity: "",
      amount: "",
      description: "",
      productObj: null,
      loading: false,
      imageUrl: man,
      file: "",
      shippingCost: "",
      agentCost: "",
      status: "",
      warehouse: "",
    });
  };
  handleReceive = async () => {
    let date = new Date();
    if (!this.state.warehouse) {
      alert("Please select an warehouse first.");
    }
    let bookings = this.props.p2p.filter((product) =>
      this.state.checkedValues.includes(product.id)
    );

    for (let i = 0; i < bookings.length; i++) {
      await this.props.updateP2PRedux({
        ...bookings[i],
        status: this.state.warehouse,
        toWarehouse: this.state.warehouse,
        toWarehouseReceiveDate: date.toLocaleDateString("en-GB"),
        deliveryWarehouse: true,
      });
      const message = {
        title: `Ready for delivery`,
        body: `Your p2p booking Id:${bookings[i].id} is ready for delivery in our ${this.state.warehouse}. Thank you`,
      };
      const userObj = this.props.users.find(
        (user) => user.uid == bookings[i].userId
      );
      if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
        userObj.deviceToken.map((token) => {
          sendNotifications(token, message);
        });
      }
    }
    this.setState({
      warehouse: "",
      checkedValues: [],
    });
  };
  handleDelivery = async () => {
    let date = new Date();
    let bookings = this.props.p2p.filter((product) =>
      this.state.checkedValues.includes(product.id)
    );

    for (let i = 0; i < bookings.length; i++) {
      await this.props.updateP2PRedux({
        ...bookings[i],
        status: "delivered",
        deliveryDate: date.toLocaleDateString("en-GB"),
      });
      const message = {
        title: `Delivered`,
        body: `Your p2p booking Id:${bookings[i].id} is delivered from our ${this.state.warehouse}. Thank you`,
      };
      const userObj = this.props.users.find(
        (user) => user.uid == bookings[i].userId
      );
      if (userObj && userObj.deviceToken && userObj.deviceToken.length > 0) {
        userObj.deviceToken.map((token) => {
          sendNotifications(token, message);
        });
      }
    }
    this.setState({
      warehouse: "",
      checkedValues: [],
    });
  };

  getUserName = (agentId) => {
    const { users } = this.props;
    if (users.length > 0) {
      const agentName = users.find((user) => user.uid === agentId);
      if (agentName) {
        return agentName.displayName;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.setState({
        checkedValues: [...this.state.checkedValues, i],
      });
    }
  };
  selectRow2 = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues2: this.state.checkedValues2.filter(
          (item, j) => i !== item
        ),
      });
    } else {
      this.setState({
        checkedValues2: [...this.state.checkedValues2, i],
      });
    }
  };

  getMonthName = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const d = new Date();
    return `${monthNames[d.getMonth()]},${d.getFullYear()}`;
  };

  handleCashOut = async (p2pArray) => {
    const { currentAdmin } = this.props;
    let date = new Date();
    if (p2pArray.length == 0) {
      alert("Please select at least one p2p agent to pay");
      return;
    }
    for (let i = 0; i < p2pArray.length; i++) {
      const p2pObj = p2pArray[i];
      console.log(date.getTime().toString());
      console.log(p2pObj);
      await this.props.handleP2pAgentPayRedux({
        ...p2pObj,
        paymentId: `${date.getTime().toString()}${i}`,
        month: this.getMonthName(),
        date: date.toLocaleDateString("en-GB"),
        receiveBy: currentAdmin.name,
      });
    }
    this.setState({
      checkedValues2: [],
    });
  };

  render() {
    const { productObj } = this.state;
    const { p2p, currentAdmin } = this.props;
    const { status } = this.props.match.params;
    let agentPayProducts = [];
    let total = 0;
    let totalShippingCost = 0;
    if (this.state.checkedValues2.length > 0) {
      agentPayProducts = p2p.filter((product) => {
        if (this.state.checkedValues2.includes(product.id)) {
          total += parseInt(product.agentCost);
          totalShippingCost += parseInt(product.shippingCost);
          return product;
        }
      });
    }

    let renderableP2p = p2p;
    if (this.state.searchFor) {
      renderableP2p = renderableP2p.filter(
        (product) =>
          (product.agentsName &&
            product.agentsName
              .toLowerCase()
              .includes(this.state.searchFor.toLowerCase())) ||
          product.bookingId
            .toLowerCase()
            .includes(this.state.searchFor.toLowerCase())
      );
    }
    return (
      <Fragment>
        <Breadcrumb title={status} parent="P2P" />
        {/* <!-- Container-fluid starts--> */}
        <div className="container-fluid">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-sm-12">
              <div className="card">
                <div
                  className="card-header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <h5>
                    <i
                      className="icofont-money"
                      style={{
                        fontSize: "130%",
                        marginRight: "5px",
                        color: "#00254c",
                      }}
                    ></i>
                    {status}
                  </h5>
                  <li
                    style={{
                      border: "1px solid gainsboro",
                      borderRadius: "5rem",
                      padding: "0px 20px",
                      background: "whitesmoke",
                      marginRight: "20px",
                    }}
                  >
                    <form className="form-inline search-form">
                      <div
                        // className="form-group"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                        }}
                      >
                        <input
                          className={
                            "form-control-plaintext " +
                            (this.state.searchbar ? "open" : "")
                          }
                          name="searchFor"
                          value={this.state.searchFor}
                          type="search"
                          placeholder="Search Agent"
                          onChange={this.handleSearchBarChange}
                        />
                        <span>
                          <Search
                            style={{
                              marginTop: "5px",
                              borderLeft: "1px solid gainsboro",
                              paddingLeft: "7px",
                              color: "gray",
                            }}
                          />
                        </span>
                      </div>
                    </form>
                  </li>
                </div>
                <div className="card-body">
                  <div className="clearfix"></div>
                  <div id="basicScenario" className="product-physical">
                    <table className="table table-bordered table-striped table-hover">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Booking Id
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Customer
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Image
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Name
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Quantity
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Price
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Shipping Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent Cost
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Agent
                          </th>
                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            Status
                          </th>

                          <th
                            scope="col"
                            style={{
                              padding: "30px 15px",
                              color: "white",
                              backgroundColor: "#00254c",
                            }}
                          >
                            {this.state.checkedValues.length == 0 ? (
                              "Action"
                            ) : this.props.match.params.status ==
                              "in shipment" ? (
                              <div
                                style={{
                                  color: "white",
                                  padding: "5px 7px",
                                  borderRadius: 5,
                                  backgroundColor: "purple",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                data-toggle="modal"
                                data-target="#receiveModal"
                              >
                                receive
                              </div>
                            ) : (
                              <div
                                style={{
                                  color: "black",
                                  padding: "5px 7px",
                                  borderRadius: 5,
                                  backgroundColor: "white",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                                data-toggle="modal"
                                data-target="#deliverModal"
                              >
                                deliver
                              </div>
                            )}
                          </th>
                          {(currentAdmin && currentAdmin.status === "Admin") ||
                          (currentAdmin &&
                            currentAdmin.adminId ===
                              "w6YrzlWyiYeDXuobSFNyomyCvP43") ? (
                            <th
                              scope="col"
                              style={{
                                padding: "30px 15px",
                                color: "white",
                                backgroundColor: "#00254c",
                              }}
                            >
                              {this.state.checkedValues2.length == 0 ? (
                                "Agent Pay"
                              ) : (
                                <div
                                  style={{
                                    color: "white",
                                    padding: "5px 7px",
                                    borderRadius: 5,
                                    backgroundColor: "purple",
                                    textAlign: "center",
                                    cursor: "pointer",
                                  }}
                                  data-toggle="modal"
                                  data-target="#agentPayModal"
                                >
                                  Agent Pay
                                </div>
                              )}
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {renderableP2p.map((product, index) => (
                          <tr key={index}>
                            <th
                              scope="row"
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {index + 1}
                            </th>
                            <td
                              scope="row"
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.bookingId}
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.date}
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {this.props.users.find(
                                (user) => user.uid === product.userId
                              ) &&
                                this.props.users.find(
                                  (user) => user.uid === product.userId
                                ).displayName}
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <a href={product.imageUrl} target="_blank">
                                <img
                                  style={{ height: 70, width: 70 }}
                                  src={
                                    product.imageUrl ? product.imageUrl : man
                                  }
                                />
                              </a>
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.name}
                            </td>

                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.quantity}
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.amount}Tk
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.shippingCost || 0}Tk
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {product.agentCost || 0}Tk
                            </td>
                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              {this.getUserName(product.agentId)}
                            </td>

                            <td
                              data-toggle="modal"
                              data-target="#detailInfoModal"
                              onClick={() => {
                                this.setState({
                                  productObj: product,
                                });
                              }}
                            >
                              <div
                                style={{
                                  color: "white",
                                  padding: "3px 7px",
                                  borderRadius: 5,
                                  backgroundColor:
                                    product.status == "pending"
                                      ? "darkorange"
                                      : product.status == "approved"
                                      ? "green"
                                      : product.status == "reject"
                                      ? "red"
                                      : product.status == "delivered"
                                      ? "darkgreen"
                                      : "cadetblue",
                                  textAlign: "center",
                                  cursor: "pointer",
                                }}
                              >
                                {product.status}
                              </div>
                            </td>
                            <td>
                              {product.status !== "delivered" && (
                                <input
                                  type="checkbox"
                                  style={{ height: 25, width: 25 }}
                                  name={product.id}
                                  checked={this.state.checkedValues.includes(
                                    product.id
                                  )}
                                  onChange={(e) =>
                                    this.selectRow(e, product.id)
                                  }
                                />
                              )}
                            </td>
                            {(currentAdmin &&
                              currentAdmin.status === "Admin") ||
                            (currentAdmin &&
                              currentAdmin.adminId ==
                                "w6YrzlWyiYeDXuobSFNyomyCvP43") ? (
                              <td>
                                {product.agentPaid &&
                                product.agentPaid == "Paid" ? (
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "3px 7px",
                                      borderRadius: 5,
                                      backgroundColor: "green",
                                      textAlign: "center",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {product.agentPaid}
                                  </div>
                                ) : (
                                  <input
                                    type="checkbox"
                                    style={{ height: 25, width: 25 }}
                                    name={product.id}
                                    checked={this.state.checkedValues2.includes(
                                      product.id
                                    )}
                                    onChange={(e) =>
                                      this.selectRow2(e, product.id)
                                    }
                                  />
                                )}
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {/* <!-- Container-fluid Ends--> */}
        <div
          className="modal fade"
          id="personalInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "100%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Update Request
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Status
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="status"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.status}
                    >
                      <option value="">Select Status</option>
                      <option value="approved">approve</option>
                      <option value="reject">reject</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Customer Shipping Cost
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="shippingCost"
                      onChange={this.handleChange}
                      value={this.state.shippingCost}
                      id="exampleFormControlInput1"
                      placeholder="Enter Customer Shipping Cost"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Agent Shipping Cost
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="agentCost"
                      onChange={this.handleChange}
                      value={this.state.agentCost}
                      id="exampleFormControlInput1"
                      placeholder="Enter Agent Shipping Cost"
                      style={{
                        borderColor: "gainsboro",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="detailInfoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto", minWidth: "60%" }}
          >
            <div className="modal-content" style={{ top: 10, margin: "auto" }}>
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Booking Id: {productObj && productObj.bookingId}
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}></div>

                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>Product Image</td>
                      <td style={{ fontWeight: "bold" }}>Product Name</td>
                      <td style={{ fontWeight: "bold" }}>Total Quantity</td>
                      <td style={{ fontWeight: "bold" }}>Total Weight</td>
                      <td style={{ fontWeight: "bold" }}>Total Price</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {productObj && (
                          <img
                            style={{ height: 70, width: 70 }}
                            src={
                              productObj.imageUrl ? productObj.imageUrl : man
                            }
                          />
                        )}
                      </td>
                      <td>{productObj && productObj.name}</td>
                      <td>{productObj && productObj.quantity}</td>
                      <td>{productObj && productObj.totalWeight}Kg</td>
                      <td>{productObj && productObj.amount}Tk</td>
                    </tr>
                  </tbody>
                </table>
                <div
                  className="row"
                  style={{
                    marginBottom: 10,
                    borderBottom: "1px solid gainsboro",
                  }}
                >
                  <div className="col">
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Shipping Cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {(productObj && productObj.shippingCost) || "0"}Tk
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent Cost:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {" "}
                        {(productObj && productObj.agentCost) || "0"}Tk
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          paddingTop: 0,
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.status == "pending"
                              ? "darkorange"
                              : productObj.status == "approved"
                              ? "green"
                              : productObj.status == "reject"
                              ? "red"
                              : productObj.status == "delivered"
                              ? "darkgreen"
                              : "cadetblue"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.status}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Country:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.selectCountry}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Payment Status:{" "}
                      <span
                        style={{
                          padding: 10,
                          fontWeight: "bold",
                          color: "white",
                          padding: "3px 7px",
                          borderRadius: 5,
                          backgroundColor: productObj
                            ? productObj.paymentStatus == "Not Generated"
                              ? "gray"
                              : productObj.paymentStatus == "Not Paid"
                              ? "orange"
                              : productObj.paymentStatus == "Pending"
                              ? "darkorange"
                              : productObj.paymentStatus == "Paid"
                              ? "green"
                              : "red"
                            : "white",
                          textAlign: "center",
                        }}
                      >
                        {productObj && productObj.paymentStatus}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Payment Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.paymentDate}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Product Details:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.description}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Booking Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.date}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      From Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouse}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      {productObj && productObj.fromWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.fromWarehouseReceiveDate}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Assigned Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.assignedDate}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      To Warehouse:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouse}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      {productObj && productObj.toWarehouse} Receive Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.toWarehouseReceiveDate}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsName}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's Payment Status:{" "}
                      {productObj && productObj.agentsName ? (
                        <span
                          style={{
                            padding: 10,
                            fontWeight: "bold",

                            color: "white",
                            padding: "3px 7px",
                            borderRadius: 5,
                            backgroundColor: productObj
                              ? productObj.agentPaid == "Not Generated"
                                ? "gray"
                                : productObj.agentPaid == "Not Paid"
                                ? "orange"
                                : productObj.agentPaid == "Pending"
                                ? "darkorange"
                                : productObj.agentPaid == "Paid"
                                ? "green"
                                : "red"
                              : "white",
                            textAlign: "center",
                          }}
                        >
                          {productObj && productObj.agentPaid
                            ? productObj.agentPaid
                            : "Not Paid"}
                        </span>
                      ) : null}
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's Payment Date:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsPaymentDate}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsAddress}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's Mobile No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsMobileNo}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's Postcode:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsPostcode}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Agent's NID/Passport No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.agentsNid}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Receiver's Name:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversName}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Receiver's Address:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversAddress}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Receiver's Mobile No:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversMobileNo}
                      </span>
                    </div>
                    <div
                      style={{
                        padding: 10,
                        fontWeight: "bold",
                        paddingTop: 0,
                      }}
                    >
                      Receiver's Postcode:{" "}
                      <span style={{ fontWeight: "lighter" }}>
                        {productObj && productObj.receiversPostcode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="receiveModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "100%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Receive in Warehouse
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Receiving in Warehouse
                    </label>
                    <select
                      title="Please choose a package"
                      required
                      name="warehouse"
                      className="custom-select"
                      aria-required="true"
                      aria-invalid="false"
                      onChange={this.handleChange}
                      value={this.state.warehouse}
                    >
                      <option value="">Select Warehouse</option>
                      <option value="Bangladesh warehouse">Bangladesh</option>
                      <option value="India warehouse">India</option>
                      <option value="USA warehouse">USA</option>
                      <option value="China warehouse">China</option>
                      <option value="Dubai warehouse">Dubai</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleReceive();
                  }}
                >
                  Receive
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="deliverModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{ top: 10, width: "100%", margin: "auto" }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Product Delivery
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div style={{ padding: "10px 15px" }}>
                  <div className="form-group">
                    <label
                      style={{
                        fontWeight: "bold",
                        color: "#505050",
                        marginBottom: 5,
                      }}
                    >
                      Are you sure you want to delivery this products?
                    </label>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "darkorange",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                    fontWeight: "lighter",
                  }}
                  onClick={() => {
                    this.handleDelivery();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="agentPayModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ margin: "auto" }}
          >
            <div
              className="modal-content"
              style={{
                top: 10,
                margin: "auto",
                minWidth: "150%",
              }}
            >
              <div
                className="modal-header"
                style={{
                  backgroundColor: "rgb(0, 37, 76)",
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  className="modal-title"
                  style={{
                    fontWeight: "bold",
                    fontSize: 17,
                    color: "white",
                  }}
                  id="exampleModalLabel"
                >
                  Agent Pay
                </div>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="personal-info-close"
                >
                  <span aria-hidden="true" style={{ color: "white" }}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <table className="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Booking Id</th>
                      <th>Image</th>
                      <th>Product</th>
                      <th>Shipping Cost</th>
                      <th>Agent Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {agentPayProducts.map((productObj, index) => (
                      <tr key={index}>
                        <td>{productObj && productObj.bookingId}</td>
                        <td>
                          {productObj && (
                            <img
                              style={{ height: 70, width: 70 }}
                              src={
                                productObj.imageUrl ? productObj.imageUrl : man
                              }
                            />
                          )}
                        </td>
                        <td>{productObj && productObj.name}</td>
                        <td>{productObj && productObj.shippingCost}Tk</td>
                        <td>{productObj && productObj.agentCost}Tk</td>
                      </tr>
                    ))}
                    <tr>
                      <td
                        colSpan={3}
                        style={{ textAlign: "end", fontWeight: "bold" }}
                      >
                        Total
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {totalShippingCost}Tk
                      </td>
                      <td style={{ fontWeight: "bold" }}>{total}Tk</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  style={{
                    backgroundColor: "red",
                    color: "white",
                    padding: 8,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    this.handleCashOut(agentPayProducts);
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    p2p: state.p2p.p2p,
    users: state.users.users,
    currentAdmin: state.admins.currentAdmin,
  };
};

export default connect(mapStateToProps, {
  getAllP2PRedux,
  getAllDeliveryWarehoueProductsp2pRedux,
  uploadP2PRedux,
  updateP2PRedux,
  deleteP2PRedux,
  handleP2pAgentPayRedux,
})(P2pRequest);

import {
  getAllBookings,
  getAllReceivedExpressBookings,
  updateBooking,
  getSingleBooking,
  uploadLot,
  updateLot,
  getAllLots,
  getAllDeliveredLots,
  uploadOrder,
  updateOrder,
  updateOrderDeliveryStatus,
  updateOrderInInvoice,
  getAllOrdersOfSingleLot,
  deleteSingleOrder,
  getSingleOrder,
  getAllExpressOrders,
  updateExpressOrder,
  getAllDocumentExpressRates,
  uploadExpressRatesDocuments,
  updateExpressRatesDocuments,
  deleteExpressRatesDocuments,
  getAllExpressRatesParcel,
  uploadExpressRatesParcel,
  updateExpressRatesParcel,
  deleteExpressRatesParcel,
  getAllD2DRates,
  updateD2DRates,
  uploadD2DRates,
  deleteD2DRates,
  getAllUsers,
  getAllAdmins,
  getAllRechargeRequest,
  updateRechargeRequestStatus,
  updatePaymentRequestStatus,
  updatePaymentRequestP2pStatus,
  updatePaymentRequestSourcingStatus,
  updatePaymentRequestStatusExpress,
  rechargeUser,
  rechargeUserFromRechargeRequest,
  updateUserStatus,
  updateAdminStatus,
  getAllRechargeDays,
  getAllRechargesOfSingleDate,
  getAllPaymentDays,
  getAllPaymentDaysExpress,
  getAllPaymentsOfSingleDate,
  getAllPaymentsOfSingleDateExpress,
  getAllRefundRequest,
  updateRefund,
  createNotice,
  createIntro,
  updateNotice,
  getAllNotices,
  createBlog,
  updateBlog,
  getAllBlogs,
  getAllIntros,
  uploadImage,
  updateAdmin,
  getAllBookingsOfSingleUser,
  getAllParcelsOfSingleUser,
  getAllRechargeRequestsOfSingleUser,
  getAllRechargeOfSingleUser,
  getAllPaymentOfSingleUser,
  deleteLot,
  makePayment,
  makePaymentP2p,
  makePaymentExpress,
  makePaymentSourcing,
  getAllPaymentRequest,
  getAllPaymentRequestP2p,
  getAllPaymentRequestSourcings,
  getAllPaymentRequestExpress,
  getMultipleOrders,
  getAllOffices,
  uploadOffice,
  updateOffice,
  deleteOffice,
  getAllBookingOffices,
  uploadBookingOffice,
  updateBookingOffice,
  deleteBookingOffice,
  getAllCnfs,
  uploadCnf,
  updateCnf,
  deleteCnf,
  getAllEmployees,
  uploadEmployee,
  updateEmployee,
  deleteEmployee,
  getAllTransactions,
  uploadTransaction,
  updateTransaction,
  deleteTransaction,
  getAllExpenses,
  getAllCashIns,
  uploadExpense,
  uploadForwarder,
  updateForwarder,
  deleteForwarder,
  getAllForwarders,
  uploadSourcing,
  updateSourcing,
  deleteSourcing,
  getAllSourcings,
  getSingleSourcing,
  deleteSourcingProduct,
  deletePurchasingProduct,
  getSinglePurchasing,
  getAllSourcingsAgent,
  uploadPurchasing,
  updatePurchasing,
  deletePurchasing,
  getAllPurchasings,
  getAllSourcingRequest,
  updateSourcingRequest,
  deleteSourcingRequest,
  getAllPurchasingsAgent,
  uploadCashIn,
  updateExpense,
  updateCashIn,
  updateSalary,
  deleteExpense,
  deleteCashIn,
  approveExpense,
  getAllPendingExpenses,
  getAllPendingCashIns,
  getAllLoansCashIns,
  getAllLoansCashOuts,
  getAllLoansCashInCustomer,
  getAllLoansCashOutCustomer,
  getAllInstallmentsCashOutCustomer,
  getSingleCustomerLoan,
  getAllPendingExpensesByDay,
  getAllPendingCashInByDay,
  getAllMonthlyExpense,
  getAllMonthlyCashSummary,
  getAllMonthlyCashIn,
  getSingleMonthlyExpense,
  getSingleMonthlyCashSummary,
  getSingleCashSummary,
  getSingleMonthlyCashIn,
  getAllMonthly,
  getAllMonthlySalary,
  getAllFunds,
  getSingleMonthly,
  getSingleMonthlyLoanCashIn,
  getSingleMonthlyLoanCashOut,
  getSingleMonthlySalary,
  uploadCnfBill,
  getAllMonthsCnfBill,
  getAllCnfBills,
  getAllCnfExpenses,
  getAllCnfBillsAllMonths,
  getAllCnfExpensesAllMonths,
  uploadProduct,
  updateProduct,
  deleteProduct,
  getAllProducts,
  getAllSoldProducts,
  getAllSoldProductsMonthly,
  handleProductPay,
  handleProductPayGenerate,
  handleSourcingCashIn,
  handleSourcingCashOut,
  handleSourcingAgentPay,
  handleP2pAgentPay,
  handlePurchasingCashIn,
  handlePurchasingCashOut,
  createMonth,
  getAllCustomerLoans,
  getAllCustomerInstallments,
  getAllInvoiceDue,
  getAllp2p,
  getAllP2pAgent,
  getAllP2pMember,
  getAllWarehouseProductsp2p,
  getAllDeliveryWarehouseProductsp2p,
  updateP2pAgentRequest,
  updateP2pMemberRequest,
  uploadp2p,
  updatep2p,
  deletep2p,
  getAllBanners,
  uploadBanner,
  updateBanner,
  deleteBanner,
} from "../firebase/firebase.utils";

export const getAllUsersRedux = () => async (dispatch) => {
  const allUsers = await getAllUsers();
  dispatch({
    type: "GET_ALL_USERS",
    payload: allUsers,
  });
};

export const getAllCustomerLoansRedux = () => async (dispatch) => {
  const allCustomers = await getAllCustomerLoans();
  dispatch({
    type: "GET_ALL_CUSTOMERS",
    payload: allCustomers,
  });
};

export const getAllCustomerInstallmentRedux = () => async (dispatch) => {
  const allCustomers = await getAllCustomerInstallments();
  dispatch({
    type: "GET_ALL_CUSTOMERS_INSTALLMENT",
    payload: allCustomers,
  });
};

export const getAllBookingsOfSingleUserRedux = (userId) => async (dispatch) => {
  const bookingsArray = await getAllBookingsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_BOOKINGS_OF_SINGLE_USER", payload: bookingsArray });
};

export const getAllParcelsOfSingleUserRedux = (userId) => async (dispatch) => {
  const parcelsArray = await getAllParcelsOfSingleUser(userId);
  dispatch({ type: "GET_ALL_PARCELS_OF_SINGLE_USER", payload: parcelsArray });
};
export const getAllRechargeRequestsOfSingleUserRedux =
  (userId) => async (dispatch) => {
    const rechargeRequestArray = await getAllRechargeRequestsOfSingleUser(
      userId
    );
    dispatch({
      type: "GET_ALL_RECHARGE_REQUEST_OF_SINGLE_USER",
      payload: rechargeRequestArray,
    });
  };
export const getAllRechargeOfSingleUserRedux = (userId) => async (dispatch) => {
  const rechargeArray = await getAllRechargeOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_RECHARGE_OF_SINGLE_USER",
    payload: rechargeArray,
  });
};
export const getAllPaymentOfSingleUserRedux = (userId) => async (dispatch) => {
  const paymentArray = await getAllPaymentOfSingleUser(userId);
  dispatch({
    type: "GET_ALL_PAYMENT_OF_SINGLE_USER",
    payload: paymentArray,
  });
};

export const getAllAdminsRedux = () => async (dispatch) => {
  const allAdmins = await getAllAdmins();
  dispatch({
    type: "SET_ALL_ADMINS",
    payload: allAdmins,
  });
};

export const setAllOrders = (ordersArray) => ({
  type: "SET_ALL_ORDERS",
  payload: ordersArray,
});

export const setAllPayments = (paymentsArray) => ({
  type: "SET_ALL_PAYMENTS",
  payload: paymentsArray,
});

export const setAllAdmins = (adminsArray) => ({
  type: "SET_ALL_ADMINS",
  payload: adminsArray,
});

export const setCurrentAdmin = (adminObj) => ({
  type: "SET_CURRENT_ADMIN",
  payload: adminObj,
});

export const setAllProducts = (productsArray) => ({
  type: "SET_ALL_PRODUCTS",
  payload: productsArray,
});

export const rechargeAdminredux = (adminIdArray, balance) => {
  return {
    type: "RECHARGE_ADMIN",
    payload: {
      adminIdArray,
      balance,
    },
  };
};

export const getAllBannersRedux = () => async (dispatch) => {
  const allCats = await getAllBanners();
  dispatch({
    type: "GET_ALL_BANNERS",
    payload: allCats,
  });
};

export const uploadBannerRedux = (bannerObj) => async (dispatch) => {
  const uploadedBannerObj = await uploadBanner(bannerObj);
  dispatch({
    type: "UPLOAD_BANNER",
    payload: uploadedBannerObj,
  });
};

export const updateBannerRedux = (bannerObj) => async (dispatch) => {
  const updatedBannerObj = await updateBanner(bannerObj);
  dispatch({
    type: "UPDATE_BANNER",
    payload: updatedBannerObj,
  });
};

export const deleteBannerRedux = (bannerId) => async (dispatch) => {
  await deleteBanner(bannerId);
  dispatch({
    type: "DELETE_BANNER",
    payload: { id: bannerId },
  });
};

// bookings
export const getAllBookingsRedux = (bookingStatus) => async (dispatch) => {
  const bookingsArray = await getAllBookings(bookingStatus);
  dispatch({ type: "GET_ALL_BOOKINGS", payload: bookingsArray });
};
export const getAllReceivedExpressBookingsRedux =
  (month) => async (dispatch) => {
    const bookingsArray = await getAllReceivedExpressBookings(month);
    dispatch({ type: "GET_ALL_EXPRESS_ORDERS", payload: bookingsArray });
  };
export const updateBookingRedux = (bookingObj) => async (dispatch) => {
  const updatedBookingObj = await updateBooking(bookingObj);
  dispatch({ type: "UPDATE_BOOKING", payload: updatedBookingObj });
};

//  lots
export const getAllLotsRedux = () => async (dispatch) => {
  const lotsArray = await getAllLots();
  dispatch({ type: "GET_ALL_LOTS", payload: lotsArray });
};
export const getAllDeliveredLotsRedux = () => async (dispatch) => {
  const lotsArray = await getAllDeliveredLots();
  dispatch({ type: "GET_ALL_DELIVERED_LOTS", payload: lotsArray });
};

export const uploadLotRedux = (lotObj) => async (dispatch) => {
  const uploadedlotObj = await uploadLot(lotObj);
  dispatch({ type: "UPLOAD_LOT", payload: uploadedlotObj });
  return uploadedlotObj;
};

export const updateLotRedux = (lotObj) => async (dispatch) => {
  const updatedLotObj = await updateLot(lotObj);
  dispatch({ type: "UPDATE_LOT", payload: updatedLotObj });
};

export const deleteLotRedux = (lotNo) => async (dispatch) => {
  await deleteLot(lotNo);
  dispatch({ type: "DELETE_LOT", payload: lotNo });
};

export const updateProfileImageRedux = (imgUrl) => {
  return {
    type: "UPDATE_PROFILE_IMAGE",
    payload: imgUrl,
  };
};

// Orders
export const getAllOrdersOfSingleLotRedux = (lotObj) => async (dispatch) => {
  const ordersArray = await getAllOrdersOfSingleLot(lotObj);
  dispatch({ type: "GET_ALL_ORDERS", payload: ordersArray });
  return ordersArray;
};
export const getAllInvoiceDueRedux = () => async (dispatch) => {
  const ordersArray = await getAllInvoiceDue();
  dispatch({ type: "GET_ALL_DUE_INVOICES", payload: ordersArray });
  return ordersArray;
};
export const getMultipleOrdersRedux = (invoicesIdArray) => async (dispatch) => {
  const ordersArray = await getMultipleOrders(invoicesIdArray);
  dispatch({ type: "GET_ALL_PAYABLE_ORDERS", payload: ordersArray });
  return ordersArray;
};

export const deleteSingleOrderRedux = (orderObj) => async (dispatch) => {
  await deleteSingleOrder(orderObj);
  dispatch({ type: "DELETE_SINGLE_ORDER", payload: orderObj });
};

export const getSingleOrderRedux = (parcelId) => async (dispatch) => {
  const resultOrderObj = await getSingleOrder(parcelId);
  dispatch({ type: "GET_SINGLE_ORDER", payload: resultOrderObj });
};
export const getSingleSourcingRedux = (sourcingId) => async (dispatch) => {
  const resultOrderObj = await getSingleSourcing(sourcingId);
  dispatch({ type: "GET_SINGLE_SOURCING", payload: resultOrderObj });
};
export const deleteSourcingProductRedux =
  (sourcingId, productId) => async (dispatch) => {
    const resultOrderObj = await deleteSourcingProduct(sourcingId, productId);
    dispatch({ type: "GET_SINGLE_SOURCING", payload: resultOrderObj });
  };
export const deletePurchasingProductRedux =
  (purchasingId, productId) => async (dispatch) => {
    const resultOrderObj = await deletePurchasingProduct(
      purchasingId,
      productId
    );
    dispatch({ type: "GET_SINGLE_PURCHASING", payload: resultOrderObj });
  };
export const getSinglePurchasingRedux = (purchasingId) => async (dispatch) => {
  const resultOrderObj = await getSinglePurchasing(purchasingId);
  dispatch({ type: "GET_SINGLE_PURCHASING", payload: resultOrderObj });
};
export const getSingleBookingRedux = (bookingId) => async (dispatch) => {
  const resultbookingObj = await getSingleBooking(bookingId);
  dispatch({ type: "GET_SINGLE_BOOKING", payload: resultbookingObj });
};

export const uploadOrderRedux = (orderObj) => async (dispatch) => {
  const uploadedOrderObj = await uploadOrder(orderObj);
  dispatch({ type: "UPLOAD_ORDER", payload: uploadedOrderObj });
  return uploadedOrderObj;
};
export const updateOrderRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};
export const updateOrderDeliveryStatusRedux =
  (orderObj) => async (dispatch) => {
    const updatedOrderObj = await updateOrderDeliveryStatus(orderObj);
    dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
    return updatedOrderObj;
  };
export const updateOrderInInvoiceRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrderInInvoice(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};
export const updateOrderBeforeInvoiceRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};
export const updateOrderAfterInvoiceRedux = (orderObj) => async (dispatch) => {
  const updatedOrderObj = await updateOrder(orderObj);
  dispatch({ type: "UPDATE_ORDER", payload: updatedOrderObj });
  return updatedOrderObj;
};

// Express
export const getAllExpressOrdersRedux = () => async (dispatch) => {
  const ordersArrayExpress = await getAllExpressOrders();
  dispatch({ type: "GET_ALL_EXPRESS_ORDERS", payload: ordersArrayExpress });
};

export const updateExpressOrderStatusRedux =
  (id, month) => async (dispatch) => {
    const updatedExpressOrder = await updateExpressOrder(id, month);
    dispatch({
      type: "UPDATE_EXPRESS_ORDER_STATUS",
      payload: updatedExpressOrder,
    });
  };

// Express Rates

export const getAllDocumentExpressRatesRedux = () => async (dispatch) => {
  const documentExpressRatesArray = await getAllDocumentExpressRates();
  dispatch({
    type: "GET_ALL_DOCUMENT_EXPRESS_RATES",
    payload: documentExpressRatesArray,
  });
};

export const uploadExpressRatesDocumentsRedux =
  (countryObj) => async (dispatch) => {
    const uploadedExpressRatesDocumentsObj = await uploadExpressRatesDocuments(
      countryObj
    );
    dispatch({
      type: "UPLOAD_EXPRESS_RATES_DOCUMENTS",
      payload: uploadedExpressRatesDocumentsObj,
    });
  };

export const updateExpressRatesDocumentsRedux =
  (countryObj) => async (dispatch) => {
    const updatedExpressRatesDocumentsObj = await updateExpressRatesDocuments(
      countryObj
    );
    dispatch({
      type: "UPDATE_EXPRESS_RATES_DOCUMENTS",
      payload: updatedExpressRatesDocumentsObj,
    });
  };

export const deleteExpressRatesDocumentsRedux =
  (countryId) => async (dispatch) => {
    await deleteExpressRatesDocuments(countryId);
    dispatch({
      type: "DELETE_EXPRESS_RATES_DOCUMENTS",
      payload: countryId,
    });
  };

// OFFICE CRUD
export const getAllOfficeRedux = () => async (dispatch) => {
  const offices = await getAllOffices();
  dispatch({
    type: "GET_ALL_OFFICES",
    payload: offices,
  });
};

export const uploadOfficeRedux = (officeObj) => async (dispatch) => {
  const uploadedOfficeObj = await uploadOffice(officeObj);
  dispatch({
    type: "UPLOAD_OFFICE",
    payload: uploadedOfficeObj,
  });
};

export const updateOfficeRedux = (officeObj) => async (dispatch) => {
  const updatedOfficeObj = await updateOffice(officeObj);
  dispatch({
    type: "UPDATE_OFFICE",
    payload: updatedOfficeObj,
  });
};
export const deleteOfficeRedux = (officeId) => async (dispatch) => {
  await deleteOffice(officeId);
  dispatch({
    type: "DELETE_OFFICE",
    payload: officeId,
  });
};
// BOOKING WAREHOUSE REDUX
export const getAllBookingOfficeRedux = () => async (dispatch) => {
  const offices = await getAllBookingOffices();
  dispatch({
    type: "GET_ALL_BOOKING_OFFICES",
    payload: offices,
  });
};

export const uploadBookingOfficeRedux = (officeObj) => async (dispatch) => {
  const uploadedOfficeObj = await uploadBookingOffice(officeObj);
  dispatch({
    type: "UPLOAD_BOOKING_OFFICE",
    payload: uploadedOfficeObj,
  });
};

export const updateBookingOfficeRedux = (officeObj) => async (dispatch) => {
  const updatedOfficeObj = await updateBookingOffice(officeObj);
  dispatch({
    type: "UPDATE_BOOKING_OFFICE",
    payload: updatedOfficeObj,
  });
};
export const deleteBookingOfficeRedux = (officeId) => async (dispatch) => {
  await deleteBookingOffice(officeId);
  dispatch({
    type: "DELETE_BOOKING_OFFICE",
    payload: officeId,
  });
};

// CNF CRUD
export const getAllCnfRedux = () => async (dispatch) => {
  const cnfs = await getAllCnfs();
  dispatch({
    type: "GET_ALL_CNFS",
    payload: cnfs,
  });
};
export const getAllCnfBillRedux = (month, name) => async (dispatch) => {
  const cnfs = await getAllCnfBills(month, name);
  dispatch({
    type: "GET_ALL_CNF_BILLS",
    payload: cnfs,
  });
};
export const getAllCnfExpenseRedux = (month, name) => async (dispatch) => {
  const cnfs = await getAllCnfExpenses(month, name);
  dispatch({
    type: "GET_ALL_CNF_EXPENSES",
    payload: cnfs,
  });
};
export const getAllCnfBillAllMonthsRedux = (name) => async (dispatch) => {
  const cnfs = await getAllCnfBillsAllMonths(name);
  dispatch({
    type: "GET_ALL_CNF_BILLS_ALL_MONTHS",
    payload: cnfs,
  });
};
export const getAllCnfExpenseAllMonthsRedux = (name) => async (dispatch) => {
  const cnfs = await getAllCnfExpensesAllMonths(name);
  dispatch({
    type: "GET_ALL_CNF_EXPENSES_ALL_MONTHS",
    payload: cnfs,
  });
};

export const uploadCnfRedux = (cnfObj) => async (dispatch) => {
  const uploadedCnfObj = await uploadCnf(cnfObj);
  dispatch({
    type: "UPLOAD_CNF",
    payload: uploadedCnfObj,
  });
};
export const uploadCnfBillRedux = (billObj) => async (dispatch) => {
  await uploadCnfBill(billObj);
  dispatch({
    type: "UPLOAD_CNF_BILL",
    payload: billObj,
  });
};
export const getAllCnfBillMonthsRedux = (month) => async (dispatch) => {
  const allMonths = await getAllMonthsCnfBill(month);
  dispatch({
    type: "ALL_MONTHS_CNF_BILL",
    payload: allMonths,
  });
};

export const updateCnfRedux = (cnfObj) => async (dispatch) => {
  const updatedCnfObj = await updateCnf(cnfObj);
  dispatch({
    type: "UPDATE_CNF",
    payload: updatedCnfObj,
  });
};

export const deleteCnfRedux = (cnfId) => async (dispatch) => {
  await deleteCnf(cnfId);
  dispatch({
    type: "DELETE_CNF",
    payload: cnfId,
  });
};
// EMPLOYEE CRUD
export const getAllEmployeeRedux = () => async (dispatch) => {
  const employees = await getAllEmployees();
  dispatch({
    type: "GET_ALL_EMPLOYEES",
    payload: employees,
  });
};

export const uploadEmployeeRedux = (employeeObj) => async (dispatch) => {
  const uploadedEmployeeObj = await uploadEmployee(employeeObj);
  dispatch({
    type: "UPLOAD_EMPLOYEE",
    payload: uploadedEmployeeObj,
  });
};

export const updateEmployeeRedux = (employeeObj) => async (dispatch) => {
  const updatedEmployeeObj = await updateEmployee(employeeObj);
  dispatch({
    type: "UPDATE_EMPLOYEE",
    payload: updatedEmployeeObj,
  });
};

export const deleteEmployeeRedux = (employeeId) => async (dispatch) => {
  await deleteEmployee(employeeId);
  dispatch({
    type: "DELETE_EMPLOYEE",
    payload: employeeId,
  });
};
export const getAllTransactionRedux =
  (employeeId, month) => async (dispatch) => {
    const transactions = await getAllTransactions(employeeId, month);
    dispatch({
      type: "GET_ALL_TRANSACTIONS",
      payload: transactions,
    });
  };

export const uploadTransactionRedux = (transactionObj) => async (dispatch) => {
  const uploadedTransactionObj = await uploadTransaction(transactionObj);
  dispatch({
    type: "UPLOAD_TRANSACTION",
    payload: uploadedTransactionObj,
  });
};

export const updateTransactionRedux = (transactionObj) => async (dispatch) => {
  const updatedTransactionObj = await updateTransaction(transactionObj);
  dispatch({
    type: "UPDATE_TRANSACTION",
    payload: updatedTransactionObj,
  });
};

export const deleteTransactionRedux = (transactionId) => async (dispatch) => {
  await deleteTransaction(transactionId);
  dispatch({
    type: "DELETE_TRANSACTION",
    payload: transactionId,
  });
};
// DAILY EXPENSE CRUD
export const getAllExpenseRedux = (day) => async (dispatch) => {
  const expenses = await getAllExpenses(day);
  dispatch({
    type: "GET_ALL_EXPENSES",
    payload: expenses,
  });
};
export const getAllForwarderRedux = () => async (dispatch) => {
  const forwarders = await getAllForwarders();
  dispatch({
    type: "GET_ALL_FORWARDERS",
    payload: forwarders,
  });
};
export const getAllProductsRedux = () => async (dispatch) => {
  const allProducts = await getAllProducts();
  dispatch({
    type: "GET_ALL_PRODUCTS",
    payload: allProducts,
  });
};
export const getAllSourcingsRedux = (month) => async (dispatch) => {
  const allSourcings = await getAllSourcings(month);
  dispatch({
    type: "GET_ALL_SOURCINGS",
    payload: allSourcings,
  });
};
export const getAllSourcingsAgentRedux = (agent, month) => async (dispatch) => {
  const allSourcings = await getAllSourcingsAgent(agent, month);
  dispatch({
    type: "GET_ALL_SOURCINGS",
    payload: allSourcings,
  });
};
export const getAllPurchasingsRedux = (month) => async (dispatch) => {
  const allPurchasings = await getAllPurchasings(month);
  dispatch({
    type: "GET_ALL_PURCHASINGS",
    payload: allPurchasings,
  });
};
export const getAllSourcingRequestRedux = () => async (dispatch) => {
  const allPurchasings = await getAllSourcingRequest();
  dispatch({
    type: "GET_ALL_SOURCING_REQUEST",
    payload: allPurchasings,
  });
};
export const getAllPurchasingsAgentRedux =
  (agent, month) => async (dispatch) => {
    const allPurchasings = await getAllPurchasingsAgent(agent, month);
    dispatch({
      type: "GET_ALL_PURCHASINGS",
      payload: allPurchasings,
    });
  };
export const getAllSoldProductsRedux = () => async (dispatch) => {
  const allProducts = await getAllSoldProducts();
  dispatch({
    type: "GET_ALL_SOLD_PRODUCTS",
    payload: allProducts,
  });
};
export const getAllSoldProductsMonthlyRedux = (month) => async (dispatch) => {
  const allProducts = await getAllSoldProductsMonthly(month);
  dispatch({
    type: "GET_ALL_SOLD_PRODUCTS_MONTHLY",
    payload: allProducts,
  });
};
export const getAllCashInsRedux = (day) => async (dispatch) => {
  const cashIns = await getAllCashIns(day);
  dispatch({
    type: "GET_ALL_CASH_INS",
    payload: cashIns,
  });
};

export const getAllMonthlyExpenseRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlyExpense();
  dispatch({
    type: "GET_ALL_MONTHLY_EXPENSES",
    payload: expenses,
  });
};
export const getAllMonthlyCashSummaryRedux = () => async (dispatch) => {
  const months = await getAllMonthlyCashSummary();
  dispatch({
    type: "GET_ALL_MONTHLY_CASH_SUMMARY",
    payload: months,
  });
};
export const getAllMonthlyCashInRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlyCashIn();
  dispatch({
    type: "GET_ALL_MONTHLY_CASH_INS",
    payload: expenses,
  });
};

export const getAllMonthlyRedux =
  (category, subCategory) => async (dispatch) => {
    const expenses = await getAllMonthly(category, subCategory);
    dispatch({
      type: "GET_ALL_MONTHLY",
      payload: expenses,
    });
  };
export const getAllFundsRedux = () => async (dispatch) => {
  const funds = await getAllFunds();
  dispatch({
    type: "GET_ALL_FUNDS",
    payload: funds,
  });
};
export const getAllMonthlySalaryRedux = () => async (dispatch) => {
  const expenses = await getAllMonthlySalary();
  dispatch({
    type: "GET_ALL_MONTHLY",
    payload: expenses,
  });
};

export const getSingleMonthlyExpenseRedux = (month) => async (dispatch) => {
  const expenses = await getSingleMonthlyExpense(month);
  dispatch({
    type: "GET_SINGLE_MONTHLY_EXPENSES",
    payload: expenses,
  });
};
export const getSingleMonthlyCashSummaryRedux = (month) => async (dispatch) => {
  const expenses = await getSingleMonthlyCashSummary(month);
  dispatch({
    type: "GET_SINGLE_MONTHLY_CASH_SUMMARY",
    payload: expenses,
  });
};
export const getSingleCashSummaryRedux = () => async (dispatch) => {
  const cashObj = await getSingleCashSummary();
  dispatch({
    type: "GET_SINGLE_CASH_SUMMARY",
    payload: cashObj,
  });
};
export const createMonthRedux = () => async (dispatch) => {
  const months = await createMonth();
  dispatch({
    type: "GET_ALL_MONTHS",
    payload: months,
  });
};
export const getSingleMonthlyCashInRedux = (month) => async (dispatch) => {
  const expenses = await getSingleMonthlyCashIn(month);
  dispatch({
    type: "GET_SINGLE_MONTHLY_CASH_INS",
    payload: expenses,
  });
};

export const getSingleMonthlyRedux =
  (month, category, subCategory) => async (dispatch) => {
    const expenses = await getSingleMonthly(month, category, subCategory);
    dispatch({
      type: "GET_SINGLE_MONTHLY",
      payload: expenses,
    });
  };
export const getSingleMonthlySalaryRedux =
  (month, category) => async (dispatch) => {
    const expenses = await getSingleMonthlySalary(month, category);
    dispatch({
      type: "GET_SINGLE_MONTHLY",
      payload: expenses,
    });
  };
export const getSingleMonthlyLoanCashInRedux =
  (month, category) => async (dispatch) => {
    const cashIns = await getSingleMonthlyLoanCashIn(month, category);
    dispatch({
      type: "GET_SINGLE_MONTHLY_LOANS_CASHIN",
      payload: cashIns,
    });
  };
export const getSingleMonthlyLoanCashOutRedux =
  (month, category) => async (dispatch) => {
    const expenses = await getSingleMonthlyLoanCashOut(month, category);
    dispatch({
      type: "GET_SINGLE_MONTHLY_LOANS_CASHOUT",
      payload: expenses,
    });
  };
export const clearSingleMonthRedux = () => async (dispatch) => {
  dispatch({
    type: "CLEAR_SINGLE_MONTHLY",
  });
};

export const getAllPendingExpenseByDayRedux = (day) => async (dispatch) => {
  const expenses = await getAllPendingExpensesByDay(day);
  dispatch({
    type: "GET_ALL_EXPENSES",
    payload: expenses,
  });
};
export const getAllPendingCashInByDayRedux = (day) => async (dispatch) => {
  const cashIns = await getAllPendingCashInByDay(day);
  dispatch({
    type: "GET_ALL_CASH_INS",
    payload: cashIns,
  });
};
export const getAllPendingExpensesRedux = () => async (dispatch) => {
  const expenses = await getAllPendingExpenses();
  dispatch({
    type: "GET_ALL_PENDING_EXPENSES",
    payload: expenses,
  });
};
export const getAllPendingCashInsRedux = () => async (dispatch) => {
  const cashIns = await getAllPendingCashIns();
  dispatch({
    type: "GET_ALL_PENDING_CASH_INS",
    payload: cashIns,
  });
};
export const getAllLoansCashOutsRedux = () => async (dispatch) => {
  const expenses = await getAllLoansCashOuts();
  dispatch({
    type: "GET_ALL_LOANS_CASH_OUTS",
    payload: expenses,
  });
};
export const getAllLoansCashInsRedux = () => async (dispatch) => {
  const cashIns = await getAllLoansCashIns();
  dispatch({
    type: "GET_ALL_LOANS_CASH_INS",
    payload: cashIns,
  });
};
export const getAllLoansCashOutCustomerRedux =
  (customer) => async (dispatch) => {
    const expenses = await getAllLoansCashOutCustomer(customer);
    dispatch({
      type: "GET_ALL_LOANS_CASH_OUTS_CUSTOMER",
      payload: expenses,
    });
  };
export const getSingleCustomerLoanRedux = (customer) => async (dispatch) => {
  const loan = await getSingleCustomerLoan(customer);
  dispatch({
    type: "GET_SINGLE_CUSTOMER_LOAN",
    payload: loan,
  });
};
export const getAllInstallmentsCashOutCustomerRedux =
  (customer) => async (dispatch) => {
    const expenses = await getAllInstallmentsCashOutCustomer(customer);
    dispatch({
      type: "GET_ALL_INSTALLMENTS_CASH_OUTS_CUSTOMER",
      payload: expenses,
    });
  };
export const getAllLoansCashInCustomerRedux =
  (customer) => async (dispatch) => {
    const cashIns = await getAllLoansCashInCustomer(customer);
    dispatch({
      type: "GET_ALL_LOANS_CASH_INS_CUSTOMER",
      payload: cashIns,
    });
  };

export const uploadExpenseRedux = (expenseObj) => async (dispatch) => {
  const uploadedExpenseObj = await uploadExpense(expenseObj);
  dispatch({
    type: "UPLOAD_EXPENSE",
    payload: uploadedExpenseObj,
  });
};
export const uploadForwarderRedux = (forwarderObj) => async (dispatch) => {
  const uploadedForwarderObj = await uploadForwarder(forwarderObj);
  dispatch({
    type: "UPLOAD_FORWARDER",
    payload: uploadedForwarderObj,
  });
};
export const uploadSourcingRedux = (sourcingObj) => async (dispatch) => {
  const uploadedSourcingObj = await uploadSourcing(sourcingObj);
  dispatch({
    type: "UPLOAD_SOURCING",
    payload: uploadedSourcingObj,
  });
};

export const uploadPurchasingRedux = (purchasingObj) => async (dispatch) => {
  const uploadedPurchasingObj = await uploadPurchasing(purchasingObj);
  dispatch({
    type: "UPLOAD_PURCHASING",
    payload: uploadedPurchasingObj,
  });
};

export const uploadProductRedux = (productObj) => async (dispatch) => {
  const uploadedProductObj = await uploadProduct(productObj);
  dispatch({
    type: "UPLOAD_PRODUCT",
    payload: uploadedProductObj,
  });
};
export const uploadCashInRedux = (cashInObj) => async (dispatch) => {
  const uploadedCashInObj = await uploadCashIn(cashInObj);
  dispatch({
    type: "UPLOAD_CASH_IN",
    payload: uploadedCashInObj,
  });
};

export const updateExpenseRedux = (expenseObj) => async (dispatch) => {
  const updatedExpenseObj = await updateExpense(expenseObj);
  dispatch({
    type: "UPDATE_EXPENSE",
    payload: updatedExpenseObj,
  });
};
export const updateSalaryRedux = (employeeObj) => async (dispatch) => {
  const updatedEmployeeObj = await updateSalary(employeeObj);
  dispatch({
    type: "UPDATE_SALARY",
    payload: updatedEmployeeObj,
  });
};
export const updateForwarderRedux = (forwarderObj) => async (dispatch) => {
  const updatedForwarderObj = await updateForwarder(forwarderObj);
  dispatch({
    type: "UPDATE_FORWARDER",
    payload: updatedForwarderObj,
  });
};
export const updateProductRedux = (productObj) => async (dispatch) => {
  const updatedProductObj = await updateProduct(productObj);
  dispatch({
    type: "UPDATE_PRODUCT",
    payload: updatedProductObj,
  });
};
export const updateSourcingRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await updateSourcing(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING",
    payload: updatedSourcingObj,
  });
};
export const updateSourcingRequestRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await updateSourcingRequest(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING_REQUEST",
    payload: updatedSourcingObj,
  });
};
export const updatePurchasingRedux = (purchasingObj) => async (dispatch) => {
  const updatedPurchasingObj = await updatePurchasing(purchasingObj);
  dispatch({
    type: "UPDATE_PURCHASING",
    payload: updatedPurchasingObj,
  });
};
export const updateCashInRedux = (cashInObj) => async (dispatch) => {
  const updatedCashInObj = await updateCashIn(cashInObj);
  dispatch({
    type: "UPDATE_CASH_IN",
    payload: updatedCashInObj,
  });
};
export const handleProductPayRedux = (productObj) => async (dispatch) => {
  const updatedProductObj = await handleProductPay(productObj);
  dispatch({
    type: "UPDATE_PRODUCT",
    payload: updatedProductObj,
  });
};
export const handleProductPayGenerateRedux =
  (productObj) => async (dispatch) => {
    const updatedProductObj = await handleProductPayGenerate(productObj);
    dispatch({
      type: "UPDATE_PRODUCT",
      payload: updatedProductObj,
    });
  };
export const handleSourcingCashInRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await handleSourcingCashIn(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING",
    payload: updatedSourcingObj,
  });
};
export const handlePurchasingCashInRedux =
  (purchasingObj) => async (dispatch) => {
    const updatedPurchasingObj = await handlePurchasingCashIn(purchasingObj);
    dispatch({
      type: "UPDATE_PURCHASING",
      payload: updatedPurchasingObj,
    });
  };
export const handleSourcingCashOutRedux = (sourcingObj) => async (dispatch) => {
  const updatedSourcingObj = await handleSourcingCashOut(sourcingObj);
  dispatch({
    type: "UPDATE_SOURCING",
    payload: updatedSourcingObj,
  });
};
export const handleSourcingAgentPayRedux =
  (sourcingObj) => async (dispatch) => {
    const updatedSourcingObj = await handleSourcingAgentPay(sourcingObj);
    dispatch({
      type: "UPDATE_SOURCING",
      payload: updatedSourcingObj,
    });
  };
export const handleP2pAgentPayRedux = (p2pObj) => async (dispatch) => {
  const updatedP2pObj = await handleP2pAgentPay(p2pObj);
  dispatch({
    type: "UPDATE_P2P",
    payload: updatedP2pObj,
  });
};
export const handlePurchasingCashOutRedux =
  (purchasingObj) => async (dispatch) => {
    const updatedPurchasingObj = await handlePurchasingCashOut(purchasingObj);
    dispatch({
      type: "UPDATE_PURCHASING",
      payload: updatedPurchasingObj,
    });
  };

export const deleteExpenseRedux = (expenseId) => async (dispatch) => {
  await deleteExpense(expenseId);
  dispatch({
    type: "DELETE_EXPENSE",
    payload: expenseId,
  });
};
export const deleteForwarderRedux = (forwarderId) => async (dispatch) => {
  await deleteForwarder(forwarderId);
  dispatch({
    type: "DELETE_FORWARDER",
    payload: forwarderId,
  });
};
export const deleteProductRedux = (productId) => async (dispatch) => {
  await deleteProduct(productId);
  dispatch({
    type: "DELETE_PRODUCT",
    payload: productId,
  });
};
export const deleteSourcingRedux = (id) => async (dispatch) => {
  await deleteSourcing(id);
  dispatch({
    type: "DELETE_SOURCING",
    payload: id,
  });
};
export const deleteSourcingRequestRedux = (sourcingObj) => async (dispatch) => {
  await deleteSourcingRequest(sourcingObj);
  dispatch({
    type: "DELETE_SOURCING_REQUEST",
    payload: sourcingObj.id,
  });
};
export const deletePurchasingRedux = (id) => async (dispatch) => {
  await deletePurchasing(id);
  dispatch({
    type: "DELETE_PURCHASING",
    payload: id,
  });
};
export const deleteCashInRedux = (cashInId) => async (dispatch) => {
  await deleteCashIn(cashInId);
  dispatch({
    type: "DELETE_CASH_IN",
    payload: cashInId,
  });
};
export const approveExpenseRedux = (date) => async (dispatch) => {
  await approveExpense(date);

  dispatch({
    type: "APPROVE_EXPENSE",
    payload: date,
  });
};

// Parcel Rates

export const getAllExpressRatesParcelRedux = () => async (dispatch) => {
  const expressRatesParcelArray = await getAllExpressRatesParcel();
  dispatch({
    type: "GET_ALL_EXPRESS_RATES_PARCEL",
    payload: expressRatesParcelArray,
  });
};

export const uploadExpressRatesParcelRedux =
  (countryObj) => async (dispatch) => {
    const uploadedExpressRatesParcelObj = await uploadExpressRatesParcel(
      countryObj
    );
    dispatch({
      type: "UPLOAD_EXPRESS_RATES_PARCEL",
      payload: uploadedExpressRatesParcelObj,
    });
  };

export const updateExpressRatesParcelRedux =
  (countryObj) => async (dispatch) => {
    const updatedExpressRatesParcelObj = await updateExpressRatesParcel(
      countryObj
    );
    dispatch({
      type: "UPDATE_EXPRESS_RATES_PARCEL",
      payload: updatedExpressRatesParcelObj,
    });
  };
export const deleteExpressRatesParcelRedux =
  (countryId) => async (dispatch) => {
    await deleteExpressRatesParcel(countryId);
    dispatch({
      type: "DELETE_EXPRESS_RATES_PARCEL",
      payload: countryId,
    });
  };

// D2D Rates
export const getAllD2DRatesRedux =
  (freightType, country) => async (dispatch) => {
    const allD2DRatesAirArray = await getAllD2DRates(freightType, country);
    dispatch({
      type: "GET_ALL_D2D_RATES",
      payload: allD2DRatesAirArray,
    });
  };

export const uploadD2DRatesRedux =
  (freightType, country, productTypeObj) => async (dispatch) => {
    const uploadedD2DRatesObj = await uploadD2DRates(
      freightType,
      country,
      productTypeObj
    );
    dispatch({
      type: "UPLOAD_D2D_RATES",
      payload: uploadedD2DRatesObj,
    });
  };

export const updateD2DRatesRedux =
  (freightType, country, productTypeObj) => async (dispatch) => {
    const updatedD2DRatesObj = await updateD2DRates(
      freightType,
      country,
      productTypeObj
    );
    dispatch({
      type: "UPDATE_D2D_RATES",
      payload: updatedD2DRatesObj,
    });
  };
export const deleteD2DRatesRedux =
  (freightType, country, productTypeId) => async (dispatch) => {
    await deleteD2DRates(freightType, country, productTypeId);
    dispatch({
      type: "DELETE_D2D_RATES",
      payload: { freightType, country, productTypeId },
    });
  };

// Recharge
export const getAllRechargeRequestRedux = () => async (dispatch) => {
  const rechargeRequestArray = await getAllRechargeRequest();
  dispatch({ type: "GET_ALL_RECHARGE_REQUEST", payload: rechargeRequestArray });
};

export const getAllPaymentRequestRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequest();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};
export const getAllPaymentRequestP2pRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequestP2p();
  dispatch({
    type: "GET_ALL_PAYMENT_REQUEST_P2P",
    payload: paymentRequestArray,
  });
};
export const getAllPaymentRequestSourcingsRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequestSourcings();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};
export const getAllPaymentRequestExpressRedux = () => async (dispatch) => {
  const paymentRequestArray = await getAllPaymentRequestExpress();
  dispatch({ type: "GET_ALL_PAYMENT_REQUEST", payload: paymentRequestArray });
};

export const updateRechargeRequestStatusRedux =
  (rechargeRequestObj) => async (dispatch) => {
    const updatedRechargeRequestObj = await updateRechargeRequestStatus(
      rechargeRequestObj
    );
    dispatch({
      type: "UPDATE_RECHARGE_REQUEST_STATUS",
      payload: updatedRechargeRequestObj,
    });
  };
export const updatePaymentRequestStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };
export const updatePaymentRequestP2pStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestP2pStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS_P2P",
      payload: updatedPaymentRequestObj,
    });
  };
export const updatePaymentRequestSourcingStatusRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestSourcingStatus(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };
export const updatePaymentRequestStatusExpressRedux =
  (paymentRequestObj) => async (dispatch) => {
    const updatedPaymentRequestObj = await updatePaymentRequestStatusExpress(
      paymentRequestObj
    );
    dispatch({
      type: "UPDATE_PAYMENT_REQUEST_STATUS",
      payload: updatedPaymentRequestObj,
    });
  };

export const rechargeUserRedux = (rechargeObj) => async (dispatch) => {
  const rechargedUserObj = await rechargeUser(rechargeObj);
  dispatch({
    type: "RECHARGE_USER",
    payload: rechargedUserObj,
  });
};
export const rechargeUserFromRechargeRequestRedux =
  (rechargeObj) => async (dispatch) => {
    const updatedRechargeRequestObj = await rechargeUserFromRechargeRequest(
      rechargeObj
    );
    dispatch({
      type: "UPDATE_RECHARGE_REQUEST_STATUS",
      payload: updatedRechargeRequestObj,
    });
  };

export const updateUserStatusRedux = (userObj) => async (dispatch) => {
  const updatedUserObj = await updateUserStatus(userObj);
  dispatch({
    type: "UPDATE_USER_STATUS",
    payload: updatedUserObj,
  });
};
export const updateAdminStatusRedux = (adminObj) => async (dispatch) => {
  const updatedAdminObj = await updateAdminStatus(adminObj);
  dispatch({
    type: "UPDATE_ADMIN_STATUS",
    payload: updatedAdminObj,
  });
};

export const getAllRechargeDayRedux = () => async (dispatch) => {
  const rechargeDaysArray = await getAllRechargeDays();
  dispatch({ type: "GET_ALL_RECHARGE_DAY", payload: rechargeDaysArray });
};

export const getAllRechargesOfSingleDateRedux = (date) => async (dispatch) => {
  const rechargesArray = await getAllRechargesOfSingleDate(date);
  dispatch({
    type: "GET_ALL_RECHARGES_OF_SINGLE_DAY",
    payload: rechargesArray,
  });
  return rechargesArray;
};

// payments
export const getAllPaymentDayRedux = () => async (dispatch) => {
  const paymentDaysArray = await getAllPaymentDays();
  dispatch({ type: "GET_ALL_PAYMENT_DAY", payload: paymentDaysArray });
};
export const getAllPaymentDayExpressRedux = () => async (dispatch) => {
  const paymentDaysArray = await getAllPaymentDaysExpress();
  dispatch({ type: "GET_ALL_PAYMENT_DAY", payload: paymentDaysArray });
};

export const getAllPaymentsOfSingleDateRedux = (date) => async (dispatch) => {
  const paymentsArray = await getAllPaymentsOfSingleDate(date);
  dispatch({
    type: "GET_ALL_PAYMENTS_OF_SINGLE_DAY",
    payload: paymentsArray,
  });
  return paymentsArray;
};
export const getAllPaymentsOfSingleDateExpressRedux =
  (date) => async (dispatch) => {
    const paymentsArray = await getAllPaymentsOfSingleDateExpress(date);
    dispatch({
      type: "GET_ALL_PAYMENTS_OF_SINGLE_DAY",
      payload: paymentsArray,
    });
    return paymentsArray;
  };

// refund
export const getAllRefundRequestRedux = (refundStatus) => async (dispatch) => {
  const refundsArray = await getAllRefundRequest(refundStatus);
  dispatch({ type: "GET_ALL_REFUNDS", payload: refundsArray });
};

export const updateRefundRedux = (refundObj) => async (dispatch) => {
  const updatedRefundObj = await updateRefund(refundObj);
  dispatch({ type: "UPDATE_REFUND", payload: updatedRefundObj });
};

// notice
export const createNoticeRedux = (noticeObj) => async (dispatch) => {
  const createdNoticeObj = await createNotice(noticeObj);
  dispatch({ type: "CREATE_NOTICE", payload: createdNoticeObj });
};
export const createIntroRedux = (introObj) => async (dispatch) => {
  const createdIntroObj = await createIntro(introObj);
  dispatch({ type: "CREATE_INTRO", payload: createdIntroObj });
};

export const getAllNoticesRedux = () => async (dispatch) => {
  const noticesArray = await getAllNotices();
  dispatch({ type: "GET_ALL_NOTICES", payload: noticesArray });
};

// blogs
export const createBlogRedux = (blogObj) => async (dispatch) => {
  const createdBlogObj = await createBlog(blogObj);
  dispatch({ type: "CREATE_BLOG", payload: createdBlogObj });
};
export const getAllBlogsRedux = () => async (dispatch) => {
  const blogsArray = await getAllBlogs();
  dispatch({ type: "GET_ALL_BLOGS", payload: blogsArray });
};
export const updateBlogRedux = (blogObj) => async (dispatch) => {
  const updatedBlogObj = await updateBlog(blogObj);
  dispatch({ type: "UPDATE_BLOG", payload: updatedBlogObj });
};

export const getAllIntrosRedux = () => async (dispatch) => {
  const introsArray = await getAllIntros();
  dispatch({ type: "GET_ALL_INTROS", payload: introsArray });
};

export const updateNoticeRedux = (noticeObj) => async (dispatch) => {
  const updatedNoticeObj = await updateNotice(noticeObj);
  dispatch({ type: "UPDATE_NOTICE", payload: updatedNoticeObj });
};

export const uploadImageRedux = (currentAdmin, file) => async (dispatch) => {
  const updatedAdminObj = await uploadImage(currentAdmin, file);
  dispatch({
    type: "UPDATE_ADMIN",
    payload: updatedAdminObj,
  });
};

export const updateAdminRedux = (updatedAdmin) => async (dispatch) => {
  console.log(updatedAdmin);
  const updatedUserObj = await updateAdmin(updatedAdmin);
  dispatch({
    type: "UPDATE_ADMIN",
    payload: updatedUserObj,
  });
};

export const makePaymentRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePayment(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS",
      payload: updatedOrdersArray,
    });
  };
export const makePaymentP2pRedux =
  (total, invoicesToPay, currentUser, admin, paymentMethod) =>
  async (dispatch) => {
    const updatedP2psArray = await makePaymentP2p(
      total,
      invoicesToPay,
      currentUser,
      admin,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_P2P",
      payload: updatedP2psArray,
    });
  };
export const makePaymentSourcingRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePaymentSourcing(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_SOURCINGS",
      payload: updatedOrdersArray,
    });
  };
export const makePaymentExpressRedux =
  (total, invoicesToPay, currentUser, admin, parcelsArray, paymentMethod) =>
  async (dispatch) => {
    const updatedOrdersArray = await makePaymentExpress(
      total,
      invoicesToPay,
      currentUser,
      admin,
      parcelsArray,
      paymentMethod
    );
    dispatch({
      type: "UPDATE_MULTIPLE_ORDERS_EXPRESS",
      payload: updatedOrdersArray,
    });
  };

// P2P CRUD
export const getAllP2PRedux = (status) => async (dispatch) => {
  const p2ps = await getAllp2p(status);
  dispatch({
    type: "GET_ALL_P2PS",
    payload: p2ps,
  });
};
export const getAllWarehoueProductsp2pRedux = (status) => async (dispatch) => {
  const p2ps = await getAllWarehouseProductsp2p(status);
  dispatch({
    type: "GET_ALL_P2PS",
    payload: p2ps,
  });
};
export const getAllDeliveryWarehoueProductsp2pRedux =
  (status) => async (dispatch) => {
    const p2ps = await getAllDeliveryWarehouseProductsp2p(status);
    dispatch({
      type: "GET_ALL_P2PS",
      payload: p2ps,
    });
  };

export const uploadP2PRedux = (p2pObj) => async (dispatch) => {
  const uploadedp2pObj = await uploadp2p(p2pObj);
  dispatch({
    type: "UPLOAD_P2P",
    payload: uploadedp2pObj,
  });
};

export const getAllP2pAgentRequestRedux = (status) => async (dispatch) => {
  const p2ps = await getAllP2pAgent(status);
  dispatch({
    type: "GET_ALL_P2P_AGENT_REQUEST",
    payload: p2ps,
  });
};
export const updateP2pAgentRequestRedux = (requestObj) => async (dispatch) => {
  const p2p = await updateP2pAgentRequest(requestObj);
  dispatch({
    type: "UPDATE_P2P_AGENT_REQUEST",
    payload: p2p,
  });
};
export const getAllP2pMemberRequestRedux = (status) => async (dispatch) => {
  const p2ps = await getAllP2pMember(status);
  dispatch({
    type: "GET_ALL_P2P_MEMBER_REQUEST",
    payload: p2ps,
  });
};
export const updateP2pMemberRequestRedux = (requestObj) => async (dispatch) => {
  const p2p = await updateP2pMemberRequest(requestObj);
  dispatch({
    type: "UPDATE_P2P_MEMBER_REQUEST",
    payload: p2p,
  });
};

export const updateP2PRedux = (p2pObj) => async (dispatch) => {
  const updatedp2pObj = await updatep2p(p2pObj);
  dispatch({
    type: "UPDATE_P2P",
    payload: updatedp2pObj,
  });
};
export const deleteP2PRedux = (p2pId) => async (dispatch) => {
  await deletep2p(p2pId);
  dispatch({
    type: "DELETE_P2P",
    payload: p2pId,
  });
};
